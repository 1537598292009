import React from 'react'
import ArrowUp from '../../assets/svg/arrowUp'
import ArrowDown from '../../assets/svg/arrowDown'
import { View, ProgressContainer, PartnerLogo, PartnerLogoContainer, Arrow, ArrowButton, SideContainer, Text } from './style'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

export const FooterMobile = ({ nextEnabled, visuals, progress, onSlideUp, onSlideDown }) => {
  const percentage = progress.completed / progress.total * 100
  return (
    <View>
      <PartnerLogoContainer>
        <PartnerLogo src={visuals.partnerLogoURL} />
      </PartnerLogoContainer>
      <ProgressContainer>
        <CircularProgressbarWithChildren
          value={percentage}
          styles={{
            root: {
              width: '15vw',
              maxHeight: '64px',
              margin: '0'
            },
            path: {
              stroke: visuals.primaryColor,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.5s ease 0s'
            },
            trail: {
              stroke: '#d7d7d7',
              strokeLinecap: 'round'
            },
            text: {
              fontWeight: 'bold',
              color: visuals.primaryColor,
              fontSize: '24px',
              maxHeight: '64px'
            }
          }}
        >
          <Text primaryColor={visuals.primaryColor}>
            {progress.completed}/{progress.total}
          </Text>
        </CircularProgressbarWithChildren>
      </ProgressContainer>
      <SideContainer>
        <ArrowButton style={{ marginRight: '5px' }} onClick={onSlideUp}>
          <Arrow visuals={visuals}>
            <ArrowUp />
          </Arrow>
        </ArrowButton>
        <ArrowButton disabled={!nextEnabled} onClick={onSlideDown}>
          <Arrow visuals={visuals}>
            <ArrowDown />
          </Arrow>
        </ArrowButton>
      </SideContainer>
    </View>
  )
}
