import React from 'react'
import { View, Row, Column, Label, RowButtons } from './style'
import { TextField } from '../text-field'
import { NextButton } from '../next-button'

export const DataConfirmation = props => {
  const { setNextEnabled, onAnswered, question, visuals, goToNextScreen, isMobile } = props

  const submit = ({ userAgree }) => {
    setNextEnabled(true)
    onAnswered(userAgree)
    goToNextScreen()
  }

  const { cpf, name, birthDate, address } = question.data

  return (
    <View>
      <Row>
        <Column>
          <Label>CPF</Label>
          <TextField visuals={visuals} disabled value={cpf || 'Dado incompleto'} />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Nome</Label>
          <TextField visuals={visuals} disabled value={name || 'Dado incompleto'} />
        </Column>
        <Column margin>
          <Label>Data de Nascimento</Label>
          <TextField visuals={visuals} disabled value={birthDate || 'Dado incompleto'} />
        </Column>
      </Row>
      <Row>
        <Column margin>
          <Label>Cep</Label>
          <TextField visuals={visuals} disabled value={(address && address.zipcode) || 'Dado incompleto'} />
        </Column>
        <Column margin>
          <Label>Rua</Label>
          <TextField visuals={visuals} disabled value={(address && address.street) || 'Dado incompleto'} />
        </Column>
        <Column margin isMobile={isMobile}>
          <Label>N°</Label>
          <TextField visuals={visuals} disabled value={(address && address.number) || 'Dado incompleto'} />
        </Column>
      </Row>
      <RowButtons>
        <NextButton title="Eu confirmo" enabled isMobile visuals={visuals} onClick={() => submit({ userAgree: true })} />
        <NextButton enabled title="Não confirmo" isMobile visuals={visuals} onClick={() => submit({ userAgree: false })} />
      </RowButtons>
    </View>
  )
}
