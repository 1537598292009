import React from 'react'
import {
  OptionsList,
  Option,
  OptionTitle,
  SpanBuild,
  OptionText,
  Row,
  Button,
  WrapperIcon,
  ContainerRecommended,
  CheckBox
} from './style'
import { usePlanSelect, useModal } from './hooks'
import { Check } from '../../assets/svg/check'
import { Star } from '../../assets/svg/star2'
import { DetailsPlan } from '../../assets/svg/details-plan'
import { currencyParser } from '../../utils'
import Modal from './modal'

const SelectPlanView = ({ question, onAnswered, setNextEnabled, visuals, isMobile }) => {
  const [isShowModal, setIsShowModal] = useModal(onAnswered, setNextEnabled)
  const [selectedOption, onSelectOption, plans, setPlan, plan] = usePlanSelect(
    question,
    onAnswered,
    setNextEnabled,
    setIsShowModal
  )
  return (
    <>
      {isShowModal && (
        <Modal
          id="mainModal"
          isMobile={isMobile}
          visuals={visuals}
          onDismiss={() => setIsShowModal(false)}
          onSelectOption={onSelectOption}
          plan={plan}
        />
      )}
      <OptionsList isMobile={isMobile}>
        {plans.map(item => (
          <Option
            onClick={() => {
              setPlan(item)
              setIsShowModal(true)
            }}
            isMobile={isMobile}
            key={item.id}
            visuals={visuals}
            isSelected={selectedOption !== undefined && item.id === selectedOption}
          >
            {item.isRecommendedPlan && (
              <ContainerRecommended isMobile={isMobile}>
                <Star />
                <p>Plano recomendado pra você</p>
              </ContainerRecommended>
            )}
            <OptionTitle isMobile={isMobile} visuals={visuals}>
              Receba <SpanBuild>{currencyParser(item.receiptValue * -1, 'R$')}</SpanBuild> por mês
            </OptionTitle>
            <Row>
              <CheckBox>
                <Check fill="#44F294" />
              </CheckBox>
              <OptionText isMobile={isMobile}>
                Receba durante <SpanBuild>{item.numberOfInstallmentsToReceive} meses</SpanBuild>
              </OptionText>
            </Row>
            <Row>
              <CheckBox>
                <Check fill="#44F294" />
              </CheckBox>
              <OptionText isMobile={isMobile}>
                Após os recebimentos, pague em <SpanBuild>{item.numberOfInstallmentsToPay} parcelas </SpanBuild>
              </OptionText>
            </Row>
            <Row>
              <CheckBox>
                <Check fill="#44F294" />
              </CheckBox>
              <OptionText isMobile={isMobile}>
                <SpanBuild>{item.monthlyInterest}</SpanBuild> de juros ao mês
              </OptionText>
            </Row>
            <Row>
              <CheckBox>
                <Check fill="#44F294" />
              </CheckBox>
              <OptionText isMobile={isMobile}>
                <SpanBuild>0%</SpanBuild> de taxa administrativa
              </OptionText>
            </Row>
            <Row end>
              <Button>
                ver detalhes
                <WrapperIcon>
                  <DetailsPlan fill="#ff531d" />
                </WrapperIcon>
              </Button>
            </Row>
          </Option>
        ))}
      </OptionsList>
    </>
  )
}
export default SelectPlanView
