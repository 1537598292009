import { useState } from 'react'
import { format, addMonths } from 'date-fns'

export const useOptionsPicker = (onAnswered, setNextEnabled, question) => {
  const course = JSON.parse(localStorage.getItem('course'))
  const options =
    course.value === '7'
      ? question.PartnerConditionsAnista.map(option => ({
          id: option.id,
          installmentsToApply: option.installmentsToApply,
          installmentValue: `R$ ${Number(option.VlrParcela).toFixed(2)}`,
          monthlyInterest: `${Number(option.monthlyInterest).toFixed(2)} %`
        }))
      : question.PartnerConditionsResidente.map(option => ({
          id: option.id,
          installmentsToApply: option.installmentsToApply,
          installmentValue: `R$ ${Number(option.VlrParcela).toFixed(2)}`,
          monthlyInterest: `${Number(option.monthlyInterest).toFixed(2)} %`
        }))
  const [selectedOption, setSelectedOption] = useState(options[0])
  const onSelectOption = option => {
    const lastPayment = addMonths(new Date(), option.installmentsToApply)
    setNextEnabled(true)
    setSelectedOption({
      ...option,
      lastPayment: format(lastPayment, 'MMM/YYYY')
    })
    onAnswered(option.id)
  }
  return [options, selectedOption, onSelectOption]
}
