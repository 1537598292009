import React from 'react'

import { Question } from '../../assets/svg/question'
import { Button, View } from './style'

export const HelpButton = ({ visuals, onClick }) => {
  return (
    <Button onClick={onClick}>
      <View visuals={visuals}>
        <Question fill={visuals.bgColor} />
      </View>
    </Button>
  )
}
