import React from 'react'
import { View } from './style'
import { TextField } from '../text-field'
import { ErrorMessage } from '../error-message'
import { useCpfValidator } from './hooks'

export const Cpf = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [showError, onDismiss, onValueChange, value] = useCpfValidator(onAnswered, setNextEnabled, question)
  return (
    <View>
      <TextField
        visuals={visuals}
        placeholder={'Responder aqui...'}
        onValueChange={onValueChange}
        mask="999.999.999 - 99"
        type="tel"
        value={value}
        icon={question.value}
        disabled={question.value}
      />
      {showError && <ErrorMessage errorMessage="CPF Inválido" onDismiss={onDismiss} />}
    </View>
  )
}
