import React from 'react'
import { HelmetWrapper } from '../../components/helmet'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { Loading } from '../../components/loading'
import { useScreenMeasure } from '../register/hooks'
import { Title, Text, Wrapper, BodyContainer } from './style'
import { useCreditDenied } from './hooks'
import { NextButton } from '../../components/next-button'

export const CreditDenied = props => {
  const [isMobile] = useScreenMeasure()
  const [isLoading, schoolSelect, visuals, slugPartner, screen, restartCreditRequest] = useCreditDenied(props)

  return (
    <ScreenImageBackground visuals={visuals} background={schoolSelect.background}>
      <HelmetWrapper partner={slugPartner} />
      {isLoading && <Loading visuals={visuals} />}
      <BodyContainer>
        <Wrapper>
          <Title isMobile={isMobile}>{screen && screen.title}</Title>

          <Text isMobile={isMobile}>{screen && screen.screenText}</Text>
          {screen &&
            screen.allowNewCreditRequest &&
            visuals && (
              <NextButton
                isMobile={isMobile}
                enabled={true}
                visuals={visuals}
                title="Fazer uma nova solicitão"
                onClick={() => restartCreditRequest()}
              />
            )}
        </Wrapper>
      </BodyContainer>
    </ScreenImageBackground>
  )
}
