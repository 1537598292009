import styled, { css } from 'styled-components'

export const View = styled.div`
  padding-top: 14px;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`

export const Label = styled.span`
  font-weight: bold;
  color: white;
`
export const RowButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-top: 20px;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      justify-content: flex-start;
      padding-bottom: 0px;
    }
  `};
`
