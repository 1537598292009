import React from 'react'

export const Dismiss = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ffffff"
    strokeWidth="1.5"
    strokeLinecap="square"
    strokeLinejoin="arcs"
  >
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
)
