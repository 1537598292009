import { useState, useEffect } from 'react'
import { showToast } from '../toast'
import { getGuarantors } from '../../services/api'

export const useGuarantorsList = () => {
  const [guarantor, setGuarantor] = useState()
  const [guarantors, setGuarantors] = useState([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getGuarantors()
        setGuarantors(data)
      } catch (e) {
        showToast(e.msg)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const selectGuarantor = item => setGuarantor(item)

  const copyMessage = () => showToast('Texto Copiado 😃')

  return [guarantor, guarantors, selectGuarantor, copyMessage, isShowModal, setIsShowModal, isLoading]
}
