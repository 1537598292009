import styled from 'styled-components'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'

export const CustemTab = styled(Tab)`
  width: 50%;
`

export const CustemTabs = styled(Tabs)`
  padding-bottom: 20px;
`

export const CustemPaper = styled(Paper)`
  width: 100%;
  box-shadow: none !important;
  background-color: transparent !important;
`
