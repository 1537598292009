import styled from 'styled-components'

export const TitleContainer = styled.div`
  display: flex;
`
export const Title = styled.p`
  font-weight: bold;
  font-size: ${({ isMobile }) => (isMobile ? '25px' : '35px')};
  line-height: ${({ isMobile }) => (isMobile ? '34px' : '48px')};
  margin-bottom: 14px;
  color: ${({ visuals }) => visuals.questionColor};
`

export const Container = styled.div`
  align-self: self-start;
  max-width: 670px;
  width: 100%;
`

export const Hint = styled.label`
  font-family: ${({ visuals }) => visuals.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
`
