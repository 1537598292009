import React from 'react'
import { Screen, BoundaryBox, Developer, Error } from './style'

import errorText from '../../assets/images/503.png'
import developer from '../../assets/images/developer.png'

import { useScreenMeasure } from '../../screens/register/hooks'

export const Background = props => {
  const [isMobile] = useScreenMeasure()
  return (
    <Screen isMobile={isMobile}>
      <BoundaryBox>
        <Error isMobile={isMobile} src={errorText} />
      </BoundaryBox>
      <BoundaryBox>
        <Developer isMobile={isMobile} src={developer} />
      </BoundaryBox>
    </Screen>
  )
}
