import styled from 'styled-components'

export const ContainerNoConnection = styled.div`
  width: ${({ isMobile }) => (isMobile ? '90%' : '420px')};
  position: relative;
  z-index: 100;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: none;
  margin: 0 auto;
  top: 20px;
  box-shadow: 25px 25px 23px -20px rgba(0, 0, 0, 0.75);
`

export const Text = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
`
