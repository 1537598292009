import React from 'react'
import { View } from './style'
import { TextField } from '../text-field'
import { ErrorMessage } from '../error-message'
import { usePhoneValidator } from './hooks'
// import { Checkbox } from '../checkbox'

export const Phone = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [showError, onDismiss, onValueChange, phone] = usePhoneValidator(onAnswered, setNextEnabled, question)

  return (
    <View>
      <TextField
        visuals={visuals}
        placeholder={'Responder aqui...'}
        onValueChange={onValueChange}
        maskChar={null}
        value={phone}
        mask="(99) 99999 - 9999"
        type="tel"
      />
      {showError && <ErrorMessage errorMessage="Telefone Inválido" onDismiss={onDismiss} />}
      {/* <Checkbox
        label="prefiro contato via whatsapp"
        visuals={visuals}
        onChange={onCheckChange}
        checked={allowWhatsAppContact}
      /> */}
    </View>
  )
}
