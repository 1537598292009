import { useState, useEffect } from 'react'

export const useIntendedWayToSpend = (onAnswered, setNextEnabled, question) => {
  const [value, setValue] = useState(question.value)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onAnswered && onAnswered(question.value)
    }
  }, [])

  const onValueChange = professionalSalaryWork => {
    setValue(professionalSalaryWork)
    onAnswered(professionalSalaryWork)

    if (!professionalSalaryWork || professionalSalaryWork.length < 10) {
      setNextEnabled(false)

      return
    }
    setNextEnabled(true)
  }

  return [onValueChange, value]
}
