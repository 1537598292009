import { useState, useEffect } from 'react'
import { sendConfirmationCode } from '../../services/api'
import { showToast } from '../toast'

const CODE_COUNT = 4

export const useCodeValidator = (onAnswered, question, setNextEnabled) => {
  const [code, setCode] = useState(new Array(CODE_COUNT).fill(undefined))

  useEffect(() => {
    sendCode()
  }, [])

  const sendCode = () => {
    sendConfirmationCode(question.value)
      .then(response => {
        showToast(response.msg)
      })
      .catch(err => showToast(err.msg))
  }

  const setCodeNumber = (codeNumber, position) => {
    if (position < code.length) {
      let newCode = code
      newCode[position] = codeNumber
      setCode(newCode)
    }
    const newCode = code.join('')
    if (newCode.length === 4) {
      onAnswered(newCode)
      setNextEnabled(true)
    } else {
      setNextEnabled(false)
    }
  }

  return [code, setCodeNumber, sendCode]
}

export const useInputFocus = () => {
  const inputRefs = []

  const addInput = ref => {
    inputRefs.push(ref)
  }

  const setNextFocus = (codeNumber, position) => {
    if (codeNumber !== '' && position < CODE_COUNT - 1) {
      inputRefs[position + 1].focus()
    }
  }

  return [addInput, setNextFocus]
}
