import React from 'react'
import offererLogo from '../../assets/images/provi_footer_high.png'
import line from '../../assets/images/line.png'
import {
  View,
  ProgressContainer,
  PartsContainer,
  OffererContainer,
  PartnerLogo,
  OffererLogo,
  Line,
  OfferedBy,
  StartSpace
} from '../footer/style'

export const IntroductionFooter = ({ visuals, progress }) => (
  <View>
    <StartSpace />
    <ProgressContainer />
    <PartsContainer>
      {visuals && <PartnerLogo visuals={visuals} src={visuals.partnerLogoURL} />}
      <Line src={line} />
      <OffererContainer>
        <OfferedBy>Viabilizado por</OfferedBy>
        <OffererLogo src={offererLogo} />
      </OffererContainer>
    </PartsContainer>
  </View>
)
