import React from 'react'
import { currencyParser } from '../../utils'
import { OptionsList, Option, OptionText, OptionSmallText } from './style'
import { useCurseSelect } from './hooks'

export const CourseSimulation = ({ question, onAnswered, setNextEnabled, visuals, isMobile }) => {
  const [selectedOption, onSelectOption, curses] = useCurseSelect(question, onAnswered, setNextEnabled)
  return (
    <OptionsList>
      {curses.map((option, index) => (
        <Option
          key={option.id}
          visuals={visuals}
          isSelected={selectedOption !== undefined && option.id === selectedOption.id}
          onClick={() => onSelectOption(option)}
          isMobile={isMobile}
        >
          <OptionText bold size="20px" visuals={visuals}>
            {option.installments}x
          </OptionText>
          <OptionText visuals={visuals}>{currencyParser(option.price, 'R$')}</OptionText>
          <OptionSmallText visuals={visuals}>
            {option.monthlyInterest ? `${String(option.monthlyInterest).replace(/\./g, ',')}% a.m.` : 'Sem juros'}
          </OptionSmallText>
        </Option>
      ))}
    </OptionsList>
  )
}
