import React from 'react'
import { View } from './style'
import { TextField } from '../text-field'
import { ErrorMessage } from '../error-message'
import { useCpfValidator } from './hooks'

export const BirthDate = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [showError, onDismiss, onValueChange, value] = useCpfValidator(onAnswered, setNextEnabled, question)
  return (
    <View>
      <TextField
        visuals={visuals}
        placeholder={'  /  /'}
        onValueChange={onValueChange}
        mask="99/99/9999"
        value={value}
        type="tel"
      />
      {showError && <ErrorMessage errorMessage="Data de Nascimento Inválida" onDismiss={onDismiss} />}
    </View>
  )
}
