import React from 'react'
import { OptionsList, Option, OptionText } from './style'
import { useCurseSelect } from './hooks'

const SanarGraduationsView = ({ question, onAnswered, setNextEnabled, visuals }) => {
  const [selectedOption, onSelectOption, cursesOptions] = useCurseSelect(onAnswered, setNextEnabled, question)

  return (
    <OptionsList>
      {cursesOptions &&
        cursesOptions.map(option => (
          <Option
            key={option.id}
            visuals={visuals}
            isSelected={selectedOption !== undefined && option.id === selectedOption.id}
            onClick={() => onSelectOption(option)}
          >
            <OptionText isSelected={selectedOption !== undefined && option.id === selectedOption.id} visuals={visuals}>
              {option.value}
            </OptionText>
          </Option>
        ))}
    </OptionsList>
  )
}
export default SanarGraduationsView
