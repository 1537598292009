import styled from 'styled-components'
import { Input } from '../../globalStyles'

export const InputWrapper = styled.div``
export const FormInputWrapper = styled.div`
  width: ${({ size }) => `${size}px`}
  margin-right: ${({ spacing }) => `${spacing}px`}
`
export const TextInput = styled(Input)`
  color: ${({ visuals }) => visuals.questionColor};
  ${({ disabled }) => disabled && 'opacity: 0.5'};
  font-size: 25px;
  line-height: 34px;
  width: 100%;
  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
  :-ms-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
`
export const TextImputMultiline = styled.textarea`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  color: ${({ visuals }) => visuals.questionColor};
  font-size: 25px;
  line-height: 34px;
  width: 100%;
  resize: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 0.2;
    color: ${({ visuals }) => visuals.questionColor};
  }
  :-ms-input-placeholder {
    opacity: 0.2;
    color: ${({ visuals }) => visuals.questionColor};
  }
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    border: 0;
    transition: ease-in-out 0.35s;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #000;
  }
`

export const FormInput = styled(TextInput)`
  font-size: 16px;
  width: 100%;
  border-color: ${({ visuals }) => visuals.questionColor};
  border-style: solid;
  border-bottom-width: 1px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
`
export const Subscript = styled.p`
  color: ${({ visuals }) => visuals.questionColor};
  font-size: 10px;
  line-height: 14px;
`
