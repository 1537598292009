import React from 'react'
import { Container, Wrapper } from './style'
import { useScreenMeasure } from '../../screens/register/hooks'
import { IntroductionFooter } from '../../components/introduction-footer'
import { FooterMobile } from '../../components/introduction-footer/mobile-footer'

export const ScreenImageBackground = props => {
  const [isMobile] = useScreenMeasure()

  return (
    <Container background={props.background} isMobile={isMobile} justifyContent={props.justifyContent}>
      <Wrapper>{props.children}</Wrapper>
      {isMobile ? <FooterMobile visuals={props.visuals} /> : <IntroductionFooter visuals={props.visuals} />}
    </Container>
  )
}
