import React from 'react'

export const Loading = ({ visuals }) => {
  const backgroundColor = visuals && visuals.questionColor
  return (
    <div id="loading-container">
      <div id="loading-wrapper">
        <div id="lds-ring">
          <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
          <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
          <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
          <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
        </div>
      </div>
    </div>
  )
}

export const LoadingText = ({ visuals }) => {
  const backgroundColor = visuals && visuals.questionColor
  return (
    <div id="lds-ring">
      <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
      <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
      <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
      <div style={{ borderColor: `${backgroundColor} transparent transparent transparent` }} />
    </div>
  )
}

export const LoadingLogin = ({ color }) => {
  return (
    <div id="loading-container">
      <div id="loading-wrapper">
        <div id="lds-ring">
          <div style={{ borderColor: `${color} transparent transparent transparent` }} />
          <div style={{ borderColor: `${color} transparent transparent transparent` }} />
          <div style={{ borderColor: `${color} transparent transparent transparent` }} />
          <div style={{ borderColor: `${color} transparent transparent transparent` }} />
        </div>
      </div>
    </div>
  )
}
