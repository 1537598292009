import React from 'react'
import { View } from './style'
import { TextField } from '../text-field'
import { ErrorMessage } from '../error-message'
import { useDateValidator } from './hooks'

export const DateGraduation = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [showError, onDismiss, onValueChange, value] = useDateValidator(onAnswered, setNextEnabled, question)
  return (
    <View>
      <TextField visuals={visuals} placeholder={'__/____'} onValueChange={onValueChange} mask="99/9999" value={value} />
      {showError && <ErrorMessage errorMessage="Data de graduação inválida" onDismiss={onDismiss} />}
    </View>
  )
}
