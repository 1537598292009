import { useState, useEffect } from 'react'
import moment from 'moment'

export const useCpfValidator = (onAnswered, setNextEnabled, question) => {
  const [showError, setShowError] = useState(false)
  const [value, setValue] = useState(question.value)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onAnswered && onAnswered(question.value)
      return
    }

    setNextEnabled(false)
  }, [])

  const onDismiss = () => {
    setShowError(false)
  }

  const isEmpty = dateStr => dateStr === '' || dateStr.includes('_')

  const isTooShort = dateStr => dateStr.split('/').length !== 3

  const isInvalidDate = dateStr => {
    const date = moment(dateStr, 'DD/MM/YYYY')
    const year = date.format('YYYY')

    const age = moment().diff(year, 'years')
    return !(age < 110)
  }

  const onValueChange = dateStr => {
    setValue(dateStr)
    onAnswered(dateStr)

    if (isEmpty(dateStr)) {
      setShowError(false)
      setNextEnabled(false)

      return
    }

    const isInvalid = isTooShort(dateStr) || isInvalidDate(dateStr)

    setShowError(isInvalid)
    setNextEnabled(!isInvalid)
  }

  return [showError, onDismiss, onValueChange, value]
}
