import styled, { css } from 'styled-components'

export const Title = styled.div`
  display: flex;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  margin: 10px 0;
  span {
    font-size: 1.2rem;
    padding: 10px;
    border-right: 2px solid #fff;
  }
  h1 {
    font-size: 1rem;
    padding-left: 10px;
  }
  svg {
    display: none;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      height: 100%;
      margin: 0;
      padding: 50px 10px 10px 10px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      color: #fff;
      span {
        font-size: 1.4rem;
        border-right: none;
      }
      h1 {
        font-size: 1.2rem;
      }
      svg {
        display: flex;
        width: 200px;
        height: 200px;
        fill: #fff;
      }
    }
  `};
`

export const Iframe = styled.iframe`
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? 'calc(100% - 64px);' : '100%')}
  border: none;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 80%;
      height: 100%;
    }
  `};
`

export const BodyContainer = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      display: flex;
      flex-direction: row;
      width: 100vw;
      height: calc(100vh - 93px);
    }
  `};
`

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const TitleLoading = styled.div`
  color: white;
  font-size: 23px;
  margin-bottom: 30px;
`

export const WrapperNotUrl = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 80%;
      max-width: 1200px;
    }
  `};
`

export const BodyContainerNotUrl = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `};
`

export const Text = styled.p`
  text-align: justify;
  margin: 1em auto;
  color: #fff;
  font-size: 1rem;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      font-size: 1.6rem;
      margin: 0.8rem 0;
    }
  `};
`

export const TitleNotUrl = styled.h1`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ isMobile }) => (isMobile ? '1.3em' : '2em')};
  color: #fff;
  height: auto;
`
