import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  bottom: 78px;
  left: 20px;
  opacity: 0.7;
  z-index: 2;
  :hover {
    opacity: 1;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      bottom: 100px;
      right: 20px;
      left: auto;
    }
  `};
`
