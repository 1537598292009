import styled, { css } from 'styled-components'

export const Button = styled.button`
  cursor: pointer;
  opacity: 1;
  color: black;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 35px;
  font: inherit;
  outline: inherit;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  margin: 20px 0;
  color: #fff;
`

export const Text = styled.p`
  text-align: left;
  margin: 0.8em auto;
  color: #fff;
  font-size: ${({ isMobile }) => (isMobile ? '1em' : '1.5em')};
`

export const Title = styled.h1`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ isMobile }) => (isMobile ? '1.25em' : '2em')};
  width: ${({ isMobile }) => (isMobile ? '88%' : '90%')};
  color: #fff;
`

export const BodyContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `};
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 80%;
      max-width: 1200px;
    }
  `};
`
