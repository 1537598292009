import React from 'react'
import { View } from './style'
import { Selector } from '../selector'
import { useCurrentDesiredIndustry } from './hooks'

export const CurrentOrDesiredIndustry = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, options, currentDesiredIndustry] = useCurrentDesiredIndustry(onAnswered, setNextEnabled, question)

  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={options}
        value={currentDesiredIndustry}
        onValueChange={onValueChange}
      />
    </View>
  )
}
