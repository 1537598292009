import React from 'react'
import { View, Column, DescriptionContainer, Description, ProgressText, WrapperCheck, NumberOfUploads } from './style'
import { useDropzone } from 'react-dropzone'
import { PendingUploadBox } from './pending'
import { ProcessingUploadBox } from './processing'
import { CompleteUploadBox } from './complete'
import { Selfie } from '../../assets/svg/selfie'
import { ProofAddress } from '../../assets/svg/proof_address'
import { SchoolRecords } from '../../assets/svg/school_records'
import { ProofEnrollment } from '../../assets/svg/proof_enrollment'
import { Document } from '../../assets/svg/document'
import { Check } from '../../assets/svg/check'
// TODO FIX MOBILE
// import { UploadBoxMobile } from '../upload-box-mobile'

const renderIcon = type => {
  switch (type) {
    case 'selfie':
      return <Selfie />
    case 'address':
      return <ProofAddress />
    case 'document':
      return <Document />
    case 'proof_enrollment':
      return <ProofEnrollment />
    case 'school_records':
      return <SchoolRecords />
    default:
      break
  }
}
const RenderIconProcessing = ({ onDrop, isMobile, text, type, progress, numberOfUploads }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <>
      <View {...getRootProps()} isMobile={isMobile} isDragActive={isDragActive}>
        <Column style={{ cursor: 'pointer' }}>
          <input {...getInputProps()} />
          {renderIcon(type)}
          {progress !== null && progress !== undefined && <ProgressText>{(progress * 100).toFixed(1)}%</ProgressText>}
          {!(progress !== null && progress !== undefined) && (
            <WrapperCheck>
              <Check size={35} fill="#50F036" />
            </WrapperCheck>
          )}
        </Column>
        <DescriptionContainer>
          <Description>{text}</Description>
        </DescriptionContainer>
      </View>
      <NumberOfUploads>
        {numberOfUploads > 0 && `Você enviou ${numberOfUploads} ${numberOfUploads > 1 ? 'arquivos' : 'arquivo'}`}
      </NumberOfUploads>
    </>
  )
}

export const UploadBox = ({ file, progress, status, isMobile, onDrop, text, type, question, numberOfUploads, visuals }) => {
  if (status === 'pending') {
    return (
      <PendingUploadBox
        type={type}
        text={text}
        onDrop={onDrop}
        isMobile={isMobile}
        question={question}
        numberOfUploads={numberOfUploads}
        visuals={visuals}
      />
    )
  }

  if (status === 'processing' && type === 'selfie') {
    return <ProcessingUploadBox type={type} text={text} file={file} progress={progress} />
  }

  if (status === 'processing') {
    return <RenderIconProcessing type={type} text={text} file={file} progress={progress} />
  }

  if (type === 'selfie') {
    return <CompleteUploadBox text={text} file={file} onDrop={onDrop} progress={progress} visuals={visuals} />
  }

  return <RenderIconProcessing type={type} text={text} onDrop={onDrop} file={file} numberOfUploads={numberOfUploads} />

  // TODO FIX MOBILE
  // if (isMobile) {
  //   return <UploadBoxMobile options={options} text={text} onDrop={onDrop} status={status} file={file} progress={progress} />
  // }
}
