import React from 'react'
import offererLogo from '../../assets/images/provi_footer_high.png'
import line from '../../assets/images/line.png'
import {
  View,
  ProgressContainer,
  PartsContainer,
  OffererContainer,
  ProgressBarContainer,
  ProgressBar,
  PartnerLogo,
  OffererLogo,
  Line,
  OfferedBy,
  ProgressText,
  StartSpace
} from './style'

export const Footer = ({ visuals, progress }) => (
  <View>
    <StartSpace />
    <ProgressContainer>
      <ProgressText>{`${progress.completed} de ${progress.total} respondidas`}</ProgressText>
      <ProgressBarContainer visuals={visuals}>
        <ProgressBar visuals={visuals} percentual={progress.completed / progress.total} />
      </ProgressBarContainer>
    </ProgressContainer>
    <PartsContainer>
      <PartnerLogo visuals={visuals} src={visuals.partnerLogoURL} />
      <Line src={line} />
      <OffererContainer>
        <OfferedBy>Viabilizado por</OfferedBy>
        <OffererLogo src={offererLogo} />
      </OffererContainer>
    </PartsContainer>
  </View>
)
