import React from 'react'
import { View, TextArea } from './style'
import { useWayToPayValidator } from './hooks'

export const WayToPay = ({ setNextEnabled, onAnswered, visuals }) => {
  const [onValueChange] = useWayToPayValidator(onAnswered, setNextEnabled)
  return (
    <View>
      <TextArea visuals={visuals} placeholder={'Responder aqui...'} onChange={({ target }) => onValueChange(target.value)} />
    </View>
  )
}
