import React from 'react'
import { TextField } from '../../text-field'
import { ErrorMessage } from '../../error-message'
import { Overlay, Container, Header, Body, Footer, CloseModal, GotItButton, Text, SubText } from './style'
import { Close } from '../../../assets/svg/close'
import { useCpfValidator } from './hooks'

export const ModalNewGuarantor = ({
  visuals,
  isMobile,
  guarantor,
  closeModal,
  setGuarantors,
  isNewGuarantor,
  setIsLoading,
  setNextEnabled
}) => {
  const [showError, onDismiss, onValueChange, value, saveGuarantor, cpfValid] = useCpfValidator(
    setGuarantors,
    guarantor,
    closeModal,
    isNewGuarantor,
    setIsLoading,
    setNextEnabled
  )

  return (
    <Overlay>
      <Container isMobile={isMobile}>
        <Header>
          <CloseModal onClick={closeModal}>
            <Close />
          </CloseModal>
          <Text>Digite abaixo aqui o CPF do novo avalista</Text>
        </Header>
        <Body>
          {isNewGuarantor && (
            <SubText>
              Lembre-se que você está substituindo o avalista #{(guarantor.guarantorName &&
                guarantor.guarantorName.replace(/ .*/, '')) ||
                `Avalista`}
            </SubText>
          )}

          <TextField
            visuals={{ questionColor: 'black' }}
            placeholder={'Inserir CPF: ___.___.___-__'}
            onValueChange={onValueChange}
            mask="999.999.999 - 99"
            value={value}
          />
          {showError && <ErrorMessage errorMessage="CPF Inválido" onDismiss={onDismiss} />}
        </Body>
        <Footer>
          <GotItButton disabled={!cpfValid} visuals={visuals} isMobile={isMobile} onClick={saveGuarantor}>
            Salvar
          </GotItButton>
        </Footer>
      </Container>
    </Overlay>
  )
}
