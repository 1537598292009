import styled, { css } from 'styled-components'

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Title = styled.p`
  font-size: ${({ isMobile }) => (isMobile ? '20px' : '25px')};
  line-height: ${({ isMobile }) => (isMobile ? '27px' : '34px')};
  margin-bottom: 14px;
  color: ${({ visuals }) => visuals.questionColor};
`
export const CodeView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: auto;
      justify-content: flex-start;
      align-items: flex-start;
    }
  `};
`
export const CodeNumber = styled.input.attrs(() => ({
  type: 'tel',
  size: '1',
  maxLength: '1'
}))`
  width: 60px;
  margin-right: 5px;
  outline: inherit;
  color: ${({ visuals }) => visuals.questionColor};
  background: none;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
  :-ms-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border-color: ${({ visuals }) => visuals.questionColor};
  border: 1px solid;
  border-radius: 8px;
`

export const SendCode = styled.div`
  height: 30px;
  display: flex;
  text-align: left;
  cursor: pointer;
  color: white;
  margin-top: 20px;
`
