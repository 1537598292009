import React from 'react'
import icon_whats from '../../assets/images/wa.png'

import { GetPartnerName } from './hooks'

import { Container } from './style'

export const Whatsapp = ({ match }) => {
  const { school } = match.params
  const [partnerName] = GetPartnerName({ school })

  return (
    <Container>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20com%20a%20${
          partnerName ? partnerName : school ? school.toUpperCase() : 'Página de Erro'
        }%3F`}
      >
        <img src={icon_whats} alt="btn" />
      </a>
    </Container>
  )
}
