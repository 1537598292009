import { useEffect } from 'react'

export const useLoginForm = props => {
  useEffect(() => {
    localStorage.removeItem('crid')
    const { values, validateForm } = props
    if (values.email !== '') {
      validateForm()
    }
  }, [props.tab])
}
