import { useState, useEffect } from 'react'
import { find } from 'lodash'
import { BANKS, MOST_USED_BANKS } from '../../constants'

const OPTIONS_ACCOUNT = [
  {
    id: 1,
    type: 'CONTA_CORRENTE',
    value: 'CONTA CORRENTE'
  },
  {
    id: 2,
    type: 'CONTA_POUPANCA',
    value: 'POUPANÇA'
  }
]

const orderBanks = banks => {
  const frequentlyUsed = banks.filter(item => MOST_USED_BANKS.includes(item.label))
  const allBanks = banks.filter(item => !frequentlyUsed.includes(item))

  return [...frequentlyUsed, ...allBanks]
}

const _BANKS = orderBanks(BANKS)

const validatorForm = (value, setNextEnabled, setAgencyError, setAccountError) => {
  let enabled = true

  if (!value.accountType) enabled = false
  if (!value.code) enabled = false
  if (!value.agency || value.agency.length > 4 || value.agency.length < 4) {
    setAgencyError('O campo Agência deve ter 4 digitos!')
    enabled = false
  } else {
    setAgencyError(null)
  }
  if (!value.account || value.account.length > 13 || !value.accountDigit) {
    if (value.account && value.account.length < 13) {
      setAccountError('O dígito da conta não pode estar vazio!')
    } else {
      setAccountError('O campo Conta deve ter no máximo 13 digitos!')
    }

    enabled = false
  } else {
    setAccountError(null)
  }

  if (!value.accountDigit) enabled = false

  if (value.agencyDigit && value.agencyDigit.length > 1) enabled = false

  setNextEnabled(enabled)
}

export const useBankPicker = (onAnswered, setNextEnabled, question) => {
  const initialStateBank = find(_BANKS, { code: question.value && question.value.code })
  const [selectedBank, setSelectedBank] = useState(initialStateBank)
  const initialStateAccount = find(OPTIONS_ACCOUNT, { type: question.value && question.value.accountType })
  const [selectedOption, setSelectedOption] = useState(initialStateAccount)
  const [value, setValue] = useState(question.value || {})
  const [agencyError, setAgencyError] = useState('')
  const [accountError, setAccountError] = useState('')

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onAnswered && onAnswered(question.value)
    }
  }, [])

  const onValueChangeSelect = bank => {
    setSelectedBank(bank)
    const obj = {
      ...value,
      code: bank.code
    }
    setValue({ ...obj })
    onAnswered({ ...obj })
    validatorForm(obj, setNextEnabled, setAgencyError, setAccountError)
  }

  const onFormChange = (formValue, identifier) => {
    const answer = value
    answer[identifier] = formValue
    setValue({ ...answer })
    onAnswered({ ...answer })
    validatorForm(answer, setNextEnabled, setAgencyError, setAccountError)
  }

  const onDismiss = () => {
    setAgencyError('')
    setAccountError('')
  }

  const onSelectOption = option => {
    const obj = {
      ...value,
      accountType: option.type
    }
    setSelectedOption(option)
    setValue({ ...obj })
    onAnswered({ ...obj })
    validatorForm(obj, setNextEnabled, setAgencyError, setAccountError)
  }

  return [
    onValueChangeSelect,
    _BANKS,
    OPTIONS_ACCOUNT,
    selectedBank,
    onFormChange,
    value,
    onDismiss,
    selectedOption,
    onSelectOption,
    agencyError,
    accountError
  ]
}
