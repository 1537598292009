import React from 'react'
import TextField from '@material-ui/core/TextField'
import { LoadingLogin } from '../../../components/loading'

import { WrapperForm, Button, LinkHelp, WrapperButton, TextError } from '../style'

const SignupComponent = props => {
  const { values, errors, handleChange, handleSubmit, isValid, isSubmitting, redirectTo, visuals } = props

  return (
    <WrapperForm onSubmit={handleSubmit}>
      {isSubmitting && <LoadingLogin color={visuals.loginButton} />}
      <TextField
        value={values.email ? values.email.trim() : null}
        label="Email"
        onChange={handleChange('email')}
        margin="normal"
      />
      {errors.email && <TextError>{errors.email}</TextError>}
      <TextField value={values.password} type="password" label="Senha" onChange={handleChange('password')} margin="normal" />
      {errors.password && <TextError>{errors.password}</TextError>}
      <TextField
        value={values.passwordConfirmation}
        type="password"
        label="Confirme sua senha"
        onChange={handleChange('passwordConfirmation')}
        margin="normal"
      />
      {errors.passwordConfirmation && <TextError>{errors.passwordConfirmation}</TextError>}
      <WrapperButton>
        <Button visuals={visuals} disabled={!isValid || isSubmitting} type={'submit'}>
          Criar conta
        </Button>
      </WrapperButton>
      <LinkHelp onClick={() => redirectTo('help')}>Precisa de ajuda ?</LinkHelp>
    </WrapperForm>
  )
}

export default SignupComponent
