import { useState, useEffect } from 'react'
import { getVisuals } from '../../utils'
import { getPartnerId, fetchByScreen, logout, restartCredit } from '../../services/api'
import bg_approved_impacta from '../../assets/images/bg-impacta.png'
import bg_approved_gama from '../../assets/images/bg-approved-gama.png'
import bg_approved_sanarup from '../../assets/images/bg-approved-sanarup.png'
import bg_digitalHouse from '../../assets/images/digitalhouse-bg.svg'

import creditRequestDone from '../../assets/images/credit-request-done.png'
import creditRequestIncomplete from '../../assets/images/credit-request-incomplete.png'
import creditRequestInAnalysis from '../../assets/images/credit-request-in-analysis.png'
import { STATUS } from '../../constants'

export const UserCreditList = props => {
  const [creditRequests, setCreditRequests] = useState(false)
  const [allowNewCR, setAllowNewCR] = useState(false)
  const [visuals, setVisuals] = useState(null)
  const [schoolSelect, setSchoolSelect] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [redirectURL, setRedirectURL] = useState()
  const [slugPartner, setSlugPartner] = useState()

  const sanar = {
    background: `url(${bg_approved_sanarup}) no-repeat center / cover`
  }

  const impacta = {
    background: `url(${bg_approved_impacta}) no-repeat center / cover`
  }

  const gama = {
    background: `url(${bg_approved_gama}) no-repeat center / cover`
  }

  const digitalhouse = {
    background: `url(${bg_digitalHouse}) no-repeat center / cover`
  }

  const sanarmed = {
    background: 'linear-gradient(90deg, #009971 0%, #045390 100%)'
  }

  useEffect(() => {
    localStorage.removeItem('crid')
    const slug = props.match.params.school
    async function fetchData() {
      try {
        const { id } = await getPartnerId(slug)
        const data = await fetchByScreen('ListCreditRequestScreen', id)

        const { activeCreditRequests, startNewCR, partnerRedirectUrl } = data.nextStep

        if (!activeCreditRequests) setCreditRequests(false)

        setRedirectURL(partnerRedirectUrl)
        setCreditRequests(activeCreditRequests)
        setAllowNewCR(startNewCR)
      } catch (error) {}
      setIsLoading(false)
    }
    fetchData()

    setSlugPartner(slug)
    switch (slug) {
      case 'sanar':
        setSchoolSelect(sanar)
        break
      case 'gama':
        setSchoolSelect(gama)
        break
      case 'impacta':
        setSchoolSelect(impacta)
        break
      case 'sanarmed':
        setSchoolSelect(sanarmed)
        break
      case 'digitalhouse':
        setSchoolSelect(digitalhouse)
        break
      default:
        break
    }

    setVisuals(getVisuals(slug))
  }, [])

  const killSession = async () => {
    localStorage.removeItem('crid')
    await logout()
    props.history.push(`/login/${slugPartner}`)
  }

  const handleStatus = state => {
    switch (state) {
      case STATUS.incomplete:
        return creditRequestIncomplete
      case STATUS.analysis:
        return creditRequestInAnalysis
      case STATUS.approved:
      case STATUS.signed:
      case STATUS.transferred:
      case STATUS.settled:
        return creditRequestDone
      default:
        return creditRequestIncomplete
    }
  }

  const initiateCreditRequest = async (crid, checkoutId) => {
    if (!crid) {
      try {
        await restartCredit(checkoutId)
        props.history.push(`/cadastro/${slugPartner}`)
        return
      } catch (error) {
        console.log(error)
      }
    }
    localStorage.setItem('crid', crid)
    props.history.push(`/cadastro/${slugPartner}`)
  }

  return [
    slugPartner,
    initiateCreditRequest,
    handleStatus,
    creditRequests,
    allowNewCR,
    redirectURL,
    visuals,
    isLoading,
    schoolSelect,
    killSession
  ]
}
