import { Element } from 'react-scroll'
import styled from 'styled-components'

export const ElementView = styled(Element)`
  width: 100%;
  height: 100vh;
  display: flex;
`
export const ContainerGuarantors = styled.div`
  display: flex;
  margin: 5px;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  flex-wrap: wrap;
`
