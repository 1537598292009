import styled, { css } from 'styled-components'

export const View = styled.div`
  padding-top: 14px;
  width: 406px;
  max-width: calc(100% - 16px);
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      max-width: 100%;
    }
  `};
`
