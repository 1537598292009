import styled from 'styled-components'
import { TextArea as BaseTextArea } from '../../globalStyles'

export const View = styled.div`
  padding-top: 14px;
`
export const TextArea = styled(BaseTextArea)`
  color: ${({ visuals }) => visuals.questionColor};
  font-size: 25px;
  line-height: 34px;
  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
  :-ms-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
`
