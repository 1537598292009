import React from 'react'

export const Check = ({ fill, size }) => (
  <svg style={{ width: size || 16, height: size || 16 }} viewBox="0 0 16 16">
    <g>
      <path
        d="M14.6533 6.70628C14.2964 6.70628 14.007 6.99566 14.007 7.3526V8.00357C14.0061 9.70163 13.3439 11.2977 12.1425 12.4977C10.9419 13.6968 9.34663 14.3571 7.64994 14.3571C7.64869 14.3571 7.64744 14.3571 7.64619 14.3571C4.14081 14.3551 1.29063 11.5017 1.29263 7.99625C1.2936 6.29822 1.95578 4.70216 3.15722 3.5021C4.35775 2.30297 5.9531 1.64272 7.64975 1.64272C7.651 1.64272 7.65225 1.64272 7.6535 1.64272C8.5495 1.64322 9.41881 1.82841 10.2373 2.19307C10.5634 2.33838 10.9453 2.19178 11.0907 1.86575C11.2359 1.53972 11.0894 1.15766 10.7633 1.01235C9.77847 0.573504 8.73244 0.350691 7.65422 0.350098C7.6526 0.350098 7.65135 0.350098 7.64972 0.350098C5.60819 0.350098 3.68825 1.14472 2.24369 2.5876C0.798001 4.0316 0.00118876 5.95219 1.26016e-06 7.99557C-0.00115499 10.0389 0.793408 11.9604 2.23747 13.4061C3.68153 14.8518 5.6021 15.6486 7.64544 15.6498C7.64703 15.6498 7.64831 15.6498 7.64994 15.6498C9.69147 15.6498 11.6114 14.8552 13.056 13.4123C14.5017 11.9682 15.2985 10.0476 15.2997 8.00394V7.35263C15.2997 6.99566 15.0103 6.70628 14.6533 6.70628Z"
        fill={fill}
      />
      <path
        d="M14.6533 6.70628C14.2964 6.70628 14.007 6.99566 14.007 7.3526V8.00357C14.0061 9.70163 13.3439 11.2977 12.1425 12.4977C10.9419 13.6968 9.34663 14.3571 7.64994 14.3571C7.64869 14.3571 7.64744 14.3571 7.64619 14.3571C4.14081 14.3551 1.29063 11.5017 1.29263 7.99625C1.2936 6.29822 1.95578 4.70216 3.15722 3.5021C4.35775 2.30297 5.9531 1.64272 7.64975 1.64272C7.651 1.64272 7.65225 1.64272 7.6535 1.64272C8.5495 1.64322 9.41881 1.82841 10.2373 2.19307C10.5634 2.33838 10.9453 2.19178 11.0907 1.86575C11.2359 1.53972 11.0894 1.15766 10.7633 1.01235C9.77847 0.573504 8.73244 0.350691 7.65422 0.350098C7.6526 0.350098 7.65135 0.350098 7.64972 0.350098C5.60819 0.350098 3.68825 1.14472 2.24369 2.5876C0.798001 4.0316 0.00118876 5.95219 1.26016e-06 7.99557C-0.00115499 10.0389 0.793408 11.9604 2.23747 13.4061C3.68153 14.8518 5.6021 15.6486 7.64544 15.6498C7.64703 15.6498 7.64831 15.6498 7.64994 15.6498C9.69147 15.6498 11.6114 14.8552 13.056 13.4123C14.5017 11.9682 15.2985 10.0476 15.2997 8.00394V7.35263C15.2997 6.99566 15.0103 6.70628 14.6533 6.70628Z"
        fill={fill}
      />
      <path
        d="M14.6533 6.70628C14.2964 6.70628 14.007 6.99566 14.007 7.3526V8.00357C14.0061 9.70163 13.3439 11.2977 12.1425 12.4977C10.9419 13.6968 9.34663 14.3571 7.64994 14.3571C7.64869 14.3571 7.64744 14.3571 7.64619 14.3571C4.14081 14.3551 1.29063 11.5017 1.29263 7.99625C1.2936 6.29822 1.95578 4.70216 3.15722 3.5021C4.35775 2.30297 5.9531 1.64272 7.64975 1.64272C7.651 1.64272 7.65225 1.64272 7.6535 1.64272C8.5495 1.64322 9.41881 1.82841 10.2373 2.19307C10.5634 2.33838 10.9453 2.19178 11.0907 1.86575C11.2359 1.53972 11.0894 1.15766 10.7633 1.01235C9.77847 0.573504 8.73244 0.350691 7.65422 0.350098C7.6526 0.350098 7.65135 0.350098 7.64972 0.350098C5.60819 0.350098 3.68825 1.14472 2.24369 2.5876C0.798001 4.0316 0.00118876 5.95219 1.26016e-06 7.99557C-0.00115499 10.0389 0.793408 11.9604 2.23747 13.4061C3.68153 14.8518 5.6021 15.6486 7.64544 15.6498C7.64703 15.6498 7.64831 15.6498 7.64994 15.6498C9.69147 15.6498 11.6114 14.8552 13.056 13.4123C14.5017 11.9682 15.2985 10.0476 15.2997 8.00394V7.35263C15.2997 6.99566 15.0103 6.70628 14.6533 6.70628Z"
        fill={fill}
      />
      <path
        d="M15.8106 1.24372C15.5581 0.99135 15.1489 0.99135 14.8965 1.24372L7.64968 8.49054L6.00565 6.84651C5.75327 6.5941 5.34402 6.5941 5.09165 6.84651C4.83924 7.09891 4.83924 7.50813 5.09165 7.76054L7.19271 9.8616C7.3189 9.98782 7.48434 10.0509 7.64974 10.0509C7.81515 10.0509 7.98055 9.98782 8.10677 9.8616L15.8106 2.15776C16.063 1.90535 16.063 1.49613 15.8106 1.24372Z"
        fill={fill}
      />
      <path
        d="M15.8106 1.24372C15.5581 0.99135 15.1489 0.99135 14.8965 1.24372L7.64968 8.49054L6.00565 6.84651C5.75327 6.5941 5.34402 6.5941 5.09165 6.84651C4.83924 7.09891 4.83924 7.50813 5.09165 7.76054L7.19271 9.8616C7.3189 9.98782 7.48434 10.0509 7.64974 10.0509C7.81515 10.0509 7.98055 9.98782 8.10677 9.8616L15.8106 2.15776C16.063 1.90535 16.063 1.49613 15.8106 1.24372Z"
        fill={fill}
      />
      <path
        d="M15.8106 1.24372C15.5581 0.99135 15.1489 0.99135 14.8965 1.24372L7.64968 8.49054L6.00565 6.84651C5.75327 6.5941 5.34402 6.5941 5.09165 6.84651C4.83924 7.09891 4.83924 7.50813 5.09165 7.76054L7.19271 9.8616C7.3189 9.98782 7.48434 10.0509 7.64974 10.0509C7.81515 10.0509 7.98055 9.98782 8.10677 9.8616L15.8106 2.15776C16.063 1.90535 16.063 1.49613 15.8106 1.24372Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
