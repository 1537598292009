import React from 'react'
import { Dismiss } from '../../assets/svg/dismiss'
import { View, Text, DismissButton } from './style'

/* eslint-disable no-empty-pattern */

export const ErrorMessage = ({ errorMessage, onDismiss }) => (
  <View>
    <Text>{errorMessage}</Text>
    <DismissButton onClick={onDismiss}>
      <Dismiss />
    </DismissButton>
  </View>
)
