import React from 'react'
import { TextConfirmation } from '../../../components/text-confirmation'
import { ListIncompleteGuarantors } from '../../../components/list-incomplete-guarantors'
import { HelpModal } from '../../../components/help-modal'
import { Question } from '../question'
import { ElementView, GoOutButton, ButtonTitle, HandleButtons } from './style'
import { CreditPreApproved } from '../../../components/credit-pre-approved'
import { DocumentsPreApproved } from '../../../components/documents-pre-approved'

export const Screen = props => {
  const screen = props.screen.nextStep
  const progress = props.screen.progress

  if (screen.uniqueName === 'CreditPreApprovedScreen') {
    return <CreditPreApproved {...props} />
  }

  if (screen.uniqueName === 'DocumentsPreApprovedScreen') {
    return <DocumentsPreApproved {...props} />
  }

  if (screen.uniqueName === 'WaitingContractScreen') {
    return (
      <ElementView name="WaitingContractScreen">
        <TextConfirmation
          key="WaitingContractScreen"
          bullets={screen.bullets}
          text={screen.text}
          textButton={screen.textButton}
          {...props}
        />
      </ElementView>
    )
  }

  if (screen.uniqueName === 'ConfirmationBlocoOne') {
    return (
      <ElementView name="ConfirmationBlocoOne">
        <TextConfirmation
          key="ConfirmationBlocoOne"
          bullets={screen.bullets}
          text={screen.text}
          textButton={screen.textButton}
          {...props}
        />
      </ElementView>
    )
  }

  if (screen.uniqueName === 'ConfirmationSendInfos') {
    return (
      <ElementView name="ConfirmationSendInfos">
        <TextConfirmation
          key="ConfirmationSendInfos"
          subText={screen.subText}
          text={screen.text}
          textButton={screen.textButton}
          {...props}
        />
      </ElementView>
    )
  }

  if (screen.uniqueName === 'ConfirmationBlocoTwo') {
    return (
      <ElementView name="ConfirmationBlocoTwo">
        <TextConfirmation
          key="ConfirmationBlocoTwo"
          bullets={screen.bullets}
          text={screen.text}
          textButton={screen.textButton}
          {...props}
        />
      </ElementView>
    )
  }

  if (screen.uniqueName === 'ReceivedRequest') {
    return (
      <ElementView name="ReceivedRequest">
        <TextConfirmation
          key="ReceivedRequest"
          subText={screen.subText}
          text={screen.text}
          textButton={screen.textButton}
          {...props}
        />
      </ElementView>
    )
  }

  if (screen.uniqueName === 'CreditAnalysisScreen') {
    const goToPage = url => (window.location.href = url)
    const slug = props.match.params.school

    return (
      <ElementView
        name="CreditAnalysisScreen"
        style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}
      >
        <TextConfirmation
          key="CreditAnalysisScreen"
          subText={screen.screenText}
          text={screen.title}
          textButton={screen.textButton}
          guarantors={screen}
          {...props}
        />
        <HandleButtons>
          {slug === 'impacta' && (
            <GoOutButton>
              <ButtonTitle onClick={() => props.history.push(`/creditos/${slug}`)}>
                Retornar para tela de solicitações
              </ButtonTitle>
            </GoOutButton>
          )}
          <GoOutButton>
            <ButtonTitle onClick={() => goToPage(screen.redirectURL)}>Ir para o site</ButtonTitle>
          </GoOutButton>
        </HandleButtons>
      </ElementView>
    )
  }

  if (screen.uniqueName === 'ListIncompleteGuarantorsScreen') {
    return <ListIncompleteGuarantors screen={screen} {...props} />
  }

  if (screen) {
    return (
      <ElementView name={screen.uniqueName}>
        {props.showHelpModal && (
          <HelpModal
            screen={props.screen.nextStep}
            visuals={props.visuals}
            isMobile={props.isMobile}
            onDismiss={() => props.setShowHelpModal(false)}
          />
        )}
        <Question key={screen.uniqueName} progress={progress} question={screen} {...props} />
      </ElementView>
    )
  }
}
