import styled from 'styled-components'

export const View = styled.div`
  padding-top: 14px;
`
export const FieldsContainer = styled.div`
  max-width: 510px;
  min-width: ${({ isMobile }) => (isMobile ? '280px' : '418px')};
  display: flex;
  flex-wrap: wrap;
`
