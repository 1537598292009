import React from 'react'
import { TextConfirmation } from '../text-confirmation'
import { ElementView, ContainerGuarantors } from './style'
import { ModalGuarantor } from '../modal-guarantor'
import { CapsuleGuarantor } from '../capsule-guarantor'
import { Loading } from '../loading'

import { useGuarantorsList } from './hooks'

export const ListIncompleteGuarantors = props => {
  const { screen, visuals, isMobile } = props
  const { nextStep } = screen
  const [guarantor, guarantors, selectGuarantor, copyMessage, isShowModal, setIsShowModal, isLoading] = useGuarantorsList()

  return (
    <ElementView
      name="CreditAnalysisScreen"
      style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}
    >
      {isLoading && <Loading visuals={visuals} />}
      <TextConfirmation
        key="CreditAnalysisScreen"
        subText={nextStep.screenText}
        text={nextStep.title}
        textButton={nextStep.textButton}
        guarantors={nextStep}
        {...props}
      />

      <ContainerGuarantors isMobile={isMobile}>
        {guarantors &&
          guarantors.length > 0 &&
          guarantors.map((item, index) => (
            <CapsuleGuarantor
              key={index}
              margin={index !== 0}
              index={index}
              item={item}
              isEditable={false}
              openModal={() => {
                selectGuarantor(item)
                setIsShowModal(true)
              }}
              isMobile={isMobile}
              visuals={visuals}
            />
          ))}
      </ContainerGuarantors>
      {isShowModal && (
        <ModalGuarantor
          visuals={visuals}
          guarantor={guarantor}
          isMobile={isMobile}
          copyMessage={copyMessage}
          onDismiss={() => setIsShowModal(false)}
        />
      )}
    </ElementView>
  )
}
