import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  padding-top: 14px;
`

export const FieldsContainer = styled.div`
  margin-top: ${({ isMobile }) => (isMobile ? '20px' : '30px')};
  max-width: ${({ isMobile }) => (isMobile ? '295px' : '510px')};
  min-width: ${({ isMobile }) => (isMobile ? '295px' : '418px')};
  display: flex;
  flex-wrap: wrap;
  align-content: ${({ isMobile }) => (isMobile ? 'space-between' : '')};
  height: ${({ isMobile }) => (isMobile ? '41%' : '')};
`

export const OptionsList = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
`

export const Option = styled(Button)`
  border: ${({ visuals, isSelected }) => (isSelected ? '2.5px solid white' : `1px solid ${visuals.questionColor}`)};
  background: ${({ visuals, isSelected }) => (isSelected ? 'rgba(250,250,250,0.3)' : 'transparent')};
  margin: 2px;
  border-radius: 8px;
`

export const OptionText = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 22px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'none')};
  color: ${({ visuals }) => visuals.questionColor};
`
export const StyledLabel = styled.label`
  font-size: 12px;
  color: #fff;
  margin: 0;
  padding: 0;
  text-align: left;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px 10px;
`
export const Column = styled.div`
  display: flex
  flex-direction: column;
  justify-content: space-around;
  
  margin-left: ${({ isMobile }) => isMobile && '10px'};
`
export const Space = styled.div`
  ${({ isMobile }) => (isMobile ? 'margin:0, padding:0' : 'margin: 0 15px;')};
`
