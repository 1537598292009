import React from 'react'
import { HelmetWrapper } from '../../components/helmet'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { TimerDocSign } from '../../components/timer-docsign'
import { LoadingText } from '../../components/loading'
import { useScreenMeasure } from '../register/hooks'
import { Title, Iframe, BodyContainer, ContainerLoading } from './style'
import { useContract } from './hooks'
import ArrowDocusign from '../../assets/svg/arrowDocusign'

export const Contract = props => {
  const [isMobile] = useScreenMeasure()
  const [schoolSelect, visuals, slugPartner, screen, counter, redirectUrl] = useContract(props)

  return (
    <ScreenImageBackground visuals={visuals} background={schoolSelect.background} justifyContent="flex-start">
      <HelmetWrapper partner={slugPartner} />
      {!redirectUrl && (
        <ContainerLoading>
          <TimerDocSign counter={counter} />
        </ContainerLoading>
      )}

      {redirectUrl && (
        <BodyContainer>
          <Title isMobile={isMobile}>
            <h1>{screen && screen.title}</h1>
            {!isMobile && <ArrowDocusign />}
          </Title>

          <Iframe id="docusignFrame" isMobile={isMobile} name="docusignFrame" src={redirectUrl} />
        </BodyContainer>
      )}
    </ScreenImageBackground>
  )
}
