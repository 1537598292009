import { useState, useEffect } from 'react'

export const useDaySelect = (onAnswered, setNextEnabled, question) => {
  const dayOptions = question.options.map(item => {
    return {
      label: item.value,
      value: item.value,
      id: item.id
    }
  })

  const initialState = dayOptions.filter(item => question.value === item.id)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onSelectOption(initialState[0])
      onAnswered && onAnswered(initialState[0].id)
    }
  }, [])

  const [selectedOption, setSelectedCurse] = useState(question.value || {})

  const onSelectOption = curse => {
    setNextEnabled(true)
    setSelectedCurse(curse)
    onAnswered(curse.value)
  }

  return [selectedOption, onSelectOption, dayOptions]
}
