import { useState } from 'react'

export const useProfessionSalaryWorkValidator = (onAnswered, setNextEnabled, question) => {
  const [value, setValue] = useState(question.value)

  const onValueChange = professionalSalaryWork => {
    setValue(professionalSalaryWork)
    onAnswered(professionalSalaryWork)

    if (!professionalSalaryWork || professionalSalaryWork === '') {
      setNextEnabled(false)

      return
    }
    setNextEnabled(true)
  }

  return [onValueChange, value]
}
