import React from 'react'
import { View } from './style'
import { TextArea } from '../text-field'
import { useIntendedWayToSpend } from './hooks'

export const IntendedWayToExpend = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, value] = useIntendedWayToSpend(onAnswered, setNextEnabled, question)
  return (
    <View>
      <TextArea visuals={visuals} placeholder={'Responder aqui...'} onValueChange={onValueChange} value={value} />
    </View>
  )
}
