import styled, { css } from 'styled-components'
import { Button } from '../../globalStyles'

export const OptionsList = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      justify-content: center;
      flex-direction: column;
    }
  `};
`
export const Option = styled(Button)`
  width: 100%;
  max-width: 300px;
  border: ${({ visuals, isSelected }) => (isSelected ? '2.5px solid white' : `1px solid ${visuals.questionColor}`)};
  background: ${({ visuals, isSelected }) => (isSelected ? 'rgba(250,250,250,0.3)' : 'transparent')};
  margin: 4px 8px;
  padding: 25px 12px;
  border-radius: 5px;
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.6')};
  position: relative;
`
export const OptionText = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 22px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'none')};
  color: ${({ visuals }) => visuals.questionColor};
`
export const OptionMonthlyInterest = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 10px;
  line-height: 16px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'none')};
  color: ${({ visuals }) => visuals.questionColor};
`

export const ContainerRecommended = styled.div`
  text-align: center;
  padding: 5px 10px;
  font-size: 0.8rem;
  width: 80%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  background: #ffbb00;
  font-weight: bold;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    margin-left: 5px;
  }
`
