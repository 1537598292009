import styled from 'styled-components'

export const View = styled.div`
  display: flex;
  min-width: 100px;
  max-width: 700px;
`
export const TipIcon = styled.img`
  margin-top: 6px;
  margin-right: 10px;
  align-self: baseline;
`
export const TipText = styled.p`
  margin-right: 10px;
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  color: ${({ visuals }) => visuals.questionColor};
`
export const TipTitle = styled.strong``
