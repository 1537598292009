import React from 'react'
import { TextField } from '../text-field'
import { OptionsList, Option, OptionText, Row, Label, Column } from './style'
import { useOptionsFunding } from './hooks'

const styleLabel = {
  fontSize: '1.2rem',
  fontWeight: 'bold'
}

const HasFundingView = ({ question, onAnswered, setNextEnabled, visuals }) => {
  const [selectedOption, onSelectOption, onValueChange, value, options] = useOptionsFunding(
    question,
    onAnswered,
    setNextEnabled
  )
  return (
    <>
      <Row>
        <OptionsList>
          {options.map(option => (
            <Option
              key={option.id}
              visuals={visuals}
              isSelected={selectedOption !== undefined && option.id === selectedOption.id}
              onClick={() => onSelectOption(option)}
            >
              <OptionText visuals={visuals}>{option.value}</OptionText>
            </Option>
          ))}
        </OptionsList>
      </Row>
      {selectedOption &&
        selectedOption.item && (
          <Column>
            <Label style={styleLabel}>Qual tipo de financiamento ?</Label>
            <TextField visuals={visuals} value={value} placeholder={'FIES, com a faculdade...'} onValueChange={onValueChange} />
          </Column>
        )}
    </>
  )
}
export default HasFundingView
