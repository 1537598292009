import React, { useState } from 'react'
import { currencyParser } from '../../utils'
import { NextButton } from '../../components/next-button'
import { OptionsList, Option, OptionText, Row, RowRight, Column, Link, CourseName, View, OptionsAndDiscountBox } from './style'
import { Modal } from './modal'
import moment from 'moment'

export const ConfirmCourseSimulation = ({ question, onAnswered, setNextEnabled, visuals, goToNextScreen }) => {
  const [isShowModal, setIsShowModal] = useState(false)
  setNextEnabled(true)
  const redirectTo = param => {
    onAnswered(param)
    goToNextScreen()
  }

  return (
    <View>
      <OptionsAndDiscountBox>
        <OptionsList>
          {question.courses.map(item => (
            <Option key={item.name} visuals={visuals}>
              <Row>
                <OptionText visuals={visuals}>
                  Curso:<CourseName> {item.name}</CourseName>
                </OptionText>
              </Row>
              <Row>
                <Column>
                  {item.path ? (
                    <>
                      <OptionText visuals={visuals}>Trilha: </OptionText>
                      <OptionText height="0px" bold size="14px" visualsm={visuals}>
                        {item.path}
                      </OptionText>
                    </>
                  ) : (
                    <>
                      <OptionText visuals={visuals}>Período: </OptionText>
                      <OptionText height="0px" bold size="14px" visualsm={visuals}>
                        {item.period ? item.period : 'A definir'}
                      </OptionText>
                    </>
                  )}
                </Column>
                <Column>
                  <OptionText visuals={visuals}>Local: </OptionText>
                  <OptionText height="0px" bold size="14px" visuals={visuals}>
                    {item.course_place}
                  </OptionText>
                </Column>
              </Row>
              <Row>
                <Column>
                  <OptionText visuals={visuals}>Início: </OptionText>
                  <OptionText height="0px" bold size="14px" visuals={visuals}>
                    {item.start_date ? moment(item.start_date).format('DD/MM/YYYY') : 'A definir'}
                  </OptionText>
                </Column>

                <Column>
                  <OptionText visuals={visuals}>Término: </OptionText>
                  <OptionText height="0px" bold size="14px" visuals={visuals}>
                    {item.end_date ? moment(item.end_date).format('DD/MM/YYYY') : 'A definir'}
                  </OptionText>
                </Column>
              </Row>
              <Row>
                <Column>
                  <OptionText visuals={visuals}>Valor: </OptionText>
                  <OptionText style={{ marginBottom: '18px' }} bold height="0px" size="18px" visuals={visuals}>
                    {currencyParser(item.price_in_centes || item.price_in_cents / 100, 'R$')}
                  </OptionText>
                </Column>
              </Row>
            </Option>
          ))}
        </OptionsList>
        {question.hasDiscount && (
          <RowRight>
            <OptionText decoration bold color="red">
              Desconto: {currencyParser(question.discountData.discountValue / 100, 'R$')}
            </OptionText>
            <OptionText bold size="18px" height="0">
              Valor Final: {currencyParser(question.discountData.checkoutPrice / 100, 'R$')}
            </OptionText>
          </RowRight>
        )}
      </OptionsAndDiscountBox>
      <RowRight>
        <NextButton title="Eu confirmo" enabled isMobile visuals={visuals} onClick={() => redirectTo(true)} />
        <Link onClick={() => setIsShowModal(true)}>Não confirmo</Link>
      </RowRight>
      {isShowModal && (
        <Modal
          visuals={visuals}
          setNextEnabled={setNextEnabled}
          redirectUrl={question.redirectURL}
          onDismiss={() => setIsShowModal(false)}
        />
      )}
    </View>
  )
}
