import React from 'react'
import { Overlay, Container, Header, Body, Footer, Title, CloseModal, Text, LinkText } from './style'
import { Close } from '../../../assets/svg/close'

export const Modal = ({ isMobile, onDismiss, setNextEnabled, redirectUrl, visuals }) => {
  setNextEnabled(false)

  return (
    <Overlay>
      <Container isMobile={isMobile}>
        <Header>
          <CloseModal onClick={onDismiss}>
            <Close />
          </CloseModal>
          <Title isMobile={isMobile}>Algum problema com seus dados?</Title>
        </Header>
        <Body>
          <Text>
            Caso alguma informação referente ao seu curso esteja incorreta, clique no link para retornar ao site da{' '}
            {visuals.partnerName}, e selecionar novamente os seus cursos.
          </Text>
        </Body>
        <Footer>
          <LinkText underline href={redirectUrl} target="_blank">
            Voltar para a área de seleção de cursos
          </LinkText>
        </Footer>
      </Container>
    </Overlay>
  )
}
