export const FETCH_SCREEN_START = 'FETCH_SCREEN_START'
export const FETCH_SCREEN_ERROR = 'FETCH_SCREEN_ERROR'
export const FETCH_MODAL_ERROR = 'FETCH_MODAL_ERROR'
export const FETCH_SCREEN_END = 'FETCH_SCREEN_END'

export const ANSWER_QUESTION_START = 'ANSWER_QUESTION_START'
export const ANSWER_QUESTION_ERROR = 'ANSWER_QUESTION_ERROR'
export const ANSWER_QUESTION_END = 'ANSWER_QUESTION_END'

export const LOADING_START = 'LOADING_START'
export const LOADING_END = 'LOADING_END'
