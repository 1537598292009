import React from 'react'
import styled from 'styled-components'
import { HelmetWrapper } from '../../components/helmet'
import ImageNotFound from '../../assets/images/404.png'
import { Whatsapp } from '../../components/whatsapp'

export const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background-image: url(${({ background }) => background});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: ${({ isMobile }) => (isMobile ? '65%' : 'center')};
`

export const NotFound = props => (
  <>
    <HelmetWrapper />
    <Background background={ImageNotFound} />
    <Whatsapp match={props.match} />
  </>
)
