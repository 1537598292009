import React from 'react'
import { View, CurrencyInput } from './style'
import { useMonthlyEarnsValidator } from './hooks'

export const MonthlyEarns = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [earns, onValueChange] = useMonthlyEarnsValidator(onAnswered, setNextEnabled, question)
  return (
    <View>
      <CurrencyInput
        visuals={visuals}
        value={earns}
        prefix="R$ "
        decimalSeparator=","
        thousandSeparator="."
        allowEmpty={true}
        placeholder="Responde aqui..."
        onChangeEvent={({ target }) => onValueChange(target.value)}
      />
    </View>
  )
}
