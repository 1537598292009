import { useState, useEffect } from 'react'
import { getVisuals } from '../../utils'
import { restartCredit, getStatus } from '../../services/api'
import bg_approved_impacta from '../../assets/images/bg-approved-impacta.png'
import bg_approved_gama from '../../assets/images/bg-approved-gama.png'
import bg_approved_sanarup from '../../assets/images/bg-approved-sanarup.png'
import bg_digitalHouse from '../../assets/images/digitalhouse-bg.svg'
import { STATUS } from '../../constants'

export const UserCreditRequest = props => {
  const [schoolSelect, setSchoolSelect] = useState({})
  const [visuals, setVisuals] = useState(null)
  const [slugPartner, setSlugPartner] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const sanar = {
    background: `url(${bg_approved_sanarup}) no-repeat center / cover`
  }

  const impacta = {
    background: `url(${bg_approved_impacta}) no-repeat center / cover`
  }

  const gama = {
    background: `url(${bg_approved_gama}) no-repeat center / cover`
  }

  const digitalhouse = {
    background: `url(${bg_digitalHouse}) no-repeat center / cover`
  }

  const sanarmed = {
    background: 'linear-gradient(90deg, #009971 0%, #045390 100%)'
  }

  useEffect(() => {
    const slug = props.match.params.school
    setIsLoading(true)
    async function checkUser() {
      try {
        const { status } = await getStatus()
        if (status !== STATUS.canceled) {
          props.history.push(`/cadastro/${slug}`)
        }
        setIsLoading(false)
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false)
    }

    checkUser()

    setSlugPartner(slug)
    const DENIED = 'CreditDenied'
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(
      {
        event: 'Pageview',
        pagePath: `${window.location.pathname}/${DENIED}`,
        pageTitle: `${DENIED} - ${slug}`
      },
      {
        event: 'GAEvent',
        eventCategory: 'Screen View',
        eventAction: DENIED,
        eventLabel: `${slug}`
      }
    )

    switch (slug) {
      case 'sanar':
        setSchoolSelect(sanar)
        break
      case 'gama':
        setSchoolSelect(gama)
        break
      case 'impacta':
        setSchoolSelect(impacta)
        break
      case 'sanarmed':
        setSchoolSelect(sanarmed)
        break
      case 'digitalhouse':
        setSchoolSelect(digitalhouse)
        break
      default:
        break
    }

    setVisuals(getVisuals(slug))
  }, [])

  const restartCreditRequest = async () => {
    try {
      await restartCredit()
      props.history.push(`/cadastro/${slugPartner}`)
    } catch (error) {
      console.log(error)
    }
  }

  return [schoolSelect, visuals, slugPartner, restartCreditRequest, isLoading]
}
