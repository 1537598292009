import React from 'react'
import tip from '../../assets/images/tip.png'
import { View, TipIcon, TipText, TipTitle } from './style'

export const Tip = ({ visuals, text }) => (
  <View>
    <TipIcon src={tip} />
    <TipText visuals={visuals}>
      <TipTitle>Dica Provi: </TipTitle>
      {text}
    </TipText>
  </View>
)
