import { useState, useEffect } from 'react'
import firebase from '../../services/firebase'
import { showToast } from '../toast'
import { slackHook } from '../../services/slack'

export const useFileUpload = (onAnswered, setNextEnabled, question) => {
  const [status, setStatus] = useState('pending')
  const [progress, setProgress] = useState(0)
  const [numberOfUploads, setNumberOfUploads] = useState(0)
  const [urlsState, setUrlsState] = useState([])

  useEffect(() => {
    if (question.value.length > 0) {
      setNextEnabled(true)
      setNumberOfUploads(question.value.length)
      onAnswered && onAnswered(question.value)
      return
    }
  }, [])

  const uploadFile = uploadTask => {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        snapshot => {
          setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        error => {
          slackHook(error, 'proof receipt')
          showToast('Deu erro, tente novamente por favor!')
          setStatus('pending')
          reject(error)
        },
        async complete => resolve(complete)
      )
    })
  }

  const onDrop = async files => {
    setNextEnabled(false)
    const reader = new FileReader()

    reader.onerror = error => {
      showToast('Deu erro, tente novamente por favor!')
    }

    files.map(file => (file.uploadedToFirebase = false))
    let urls = []

    for (const file of files) {
      setStatus('processing')

      const now = new Date().getTime()
      const storagePath = `/${firebase.auth().currentUser.uid}/${now}`

      let uploadRef = firebase.storage().ref(storagePath)
      let uploadTask = uploadRef.put(file)

      await uploadFile(uploadTask)

      const url = await uploadRef.getDownloadURL()
      file['url'] = url
      urls.push({ downloadURL: file.url })

      const newUrls = urls.concat(urlsState)
      setUrlsState(newUrls)
      setNumberOfUploads(urls.length + numberOfUploads)
      if (files.length === urls.length) {
        setNextEnabled(true)
        onAnswered(newUrls)
        setStatus('complete')
      }
    }
  }

  return [onDrop, status, progress, numberOfUploads]
}
