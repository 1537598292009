import { useState, useEffect } from 'react'

export const useLastCompany = (onAnswered, setNextEnabled, question) => {
  const [value, setValue] = useState(question.value === 'Nunca trabalhei' ? '' : question.value)
  const [enabledButton, setEnabledButton] = useState(false)

  useEffect(() => {
    setNextEnabled(true)
    if (question.value === 'Nunca trabalhei') {
      setEnabledButton(true)
      onAnswered && onAnswered('')
    } else if (!question.value) {
      setEnabledButton(false)
      onAnswered && onAnswered('')
    } else {
      setEnabledButton(true)
      onAnswered && onAnswered(question.value)
    }
  }, [])

  const onValueChange = lastCompany => {
    setValue(lastCompany)
    onAnswered(lastCompany)

    if (!lastCompany || lastCompany.length < 3) {
      setEnabledButton(false)

      return
    }
    setEnabledButton(true)
  }

  return [onValueChange, value, enabledButton]
}
