import { useState, useEffect } from 'react'
import { showToast } from '../toast'
import { getGuarantors, copiedGuarantors } from '../../services/api'

export const useGuarantorsList = (question, onAnswered, setNextEnabled) => {
  const [guarantor, setGuarantor] = useState()
  const [guarantors, setGuarantors] = useState([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowmodalNewGuarantor, setIsShowmodalNewGuarantor] = useState(false)

  const [canAddOtherGuarantor, setCanAddOtherGuarantor] = useState(true)
  const [isNewGuarantor, setIsNewGuarantor] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const isCanAddOtherGuarantor = guarantors.reduce((prev, next) => prev && next.canAddOtherGuarantor, true)
    setCanAddOtherGuarantor(isCanAddOtherGuarantor)
  }, [guarantors])

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getGuarantors()

        const filterWasClicked = data.filter(item => item.wasClicked)
        setNextEnabled(data.length === filterWasClicked.length)

        setGuarantors(data)
      } catch (e) {
        showToast(e.msg)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const selectGuarantor = item => {
    setGuarantor(item)
  }

  const copyMessage = async (guarantorId, wasClicked) => {
    if (wasClicked) {
      setIsShowModal(false)
      showToast('Texto Copiado 😃')
      return
    }

    setIsLoading(true)

    try {
      await copiedGuarantors(guarantorId)
      const { data } = await getGuarantors()

      const filterWasClicked = data.filter(item => item.wasClicked === true)
      setNextEnabled(data.length === filterWasClicked.length)

      setGuarantors(data)
      setIsShowModal(false)
      showToast('Texto Copiado 😃')
    } catch (e) {
      showToast(e.msg)
    }
    setIsLoading(false)
  }

  return [
    guarantor,
    guarantors,
    selectGuarantor,
    copyMessage,
    isShowModal,
    setIsShowModal,
    isShowmodalNewGuarantor,
    setIsShowmodalNewGuarantor,
    setGuarantors,
    setIsNewGuarantor,
    isNewGuarantor,
    canAddOtherGuarantor,
    isLoading,
    setIsLoading
  ]
}
