import { showToast } from '../toast'
import { removeGuarantors, getGuarantors } from '../../services/api'

export const useCapsuleGuarantor = (setGuarantors, setIsLoading) => {
  const deleteCpf = async id => {
    setIsLoading(true)
    try {
      await removeGuarantors(id)
      const { data } = await getGuarantors()
      setGuarantors(data)
      showToast('Avalista Deletado!')
    } catch (e) {
      showToast(e.msg)
    }
    setIsLoading(false)
  }

  return [deleteCpf]
}
