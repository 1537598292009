import React from 'react'
import { Slider } from '../../components/slider'
import { Footer } from '../../components/footer'
import { Loading } from '../../components/loading'
import { HelmetWrapper } from '../../components/helmet'
import { NoConnection } from '../../components/no-connection'

import { ModalQuestionAlert } from '../../components/modals'
import { Screen } from './screen'
import { useSliderControl, useHelpModal, useScreenMeasure } from './hooks'
import { Background, List } from './style'
import { FooterMobile } from '../../components/footer-mobile'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { Whatsapp } from '../../components/whatsapp'

const RegisterView = props => {
  const [showHelpModal, setShowHelpModal] = useHelpModal()
  const [isMobile] = useScreenMeasure()
  const [
    currentScreen,
    goToPreviousScreen,
    goToNextScreen,
    goToScreen,
    onAnswered,
    onSendClick,
    onNextClick,
    onKeyDown,
    nextEnabled,
    setNextEnabled,
    isCreditAnalysisScreen,
    isTermsScreen,
    isListIncompleteGuarantorsScreen,
    isShowModalAlert,
    setIsShowModalAlert,
    quitApplication,
    visuals,
    IsCreditApprovedScreen,
    netInfo,
    schoolSelected
  ] = useSliderControl(props, setShowHelpModal)

  const screenName = currentScreen && currentScreen.nextStep && currentScreen.nextStep.uniqueName

  return (
    <>
      {!netInfo && <NoConnection isMobile={isMobile} />}
      <HelmetWrapper partner={props.match.params.school} />
      <Background isMobile={isMobile} visuals={visuals} isCreditAnalysisScreen={isCreditAnalysisScreen} />
      {(props.isLoading || !schoolSelected) && <Loading visuals={visuals} />}
      {props.loadingAnswer && <Loading visuals={visuals} />}
      {props.isLoadingSendingData && <Loading visuals={visuals} />}
      <KeyboardEventHandler handleKeys={['enter']} handleFocusableElements={true} onKeyEvent={(key, e) => onKeyDown(key)} />
      {props.errorMessage && (
        <ModalQuestionAlert
          title="Curso não encontrado 😔"
          linkTitle="Acesse o site do parceiro aqui."
          link={props.errorMessage}
          visuals={visuals}
          isMobile={isMobile}
          setModalState={props.fetchModalError}
        />
      )}
      {isShowModalAlert && (
        <ModalQuestionAlert
          title="Você deseja fazer logout?"
          visuals={visuals}
          isMobile={isMobile}
          deleteFunc={quitApplication}
          setModalState={setIsShowModalAlert}
          questionButtons
        />
      )}
      {!isTermsScreen &&
        !IsCreditApprovedScreen &&
        !isMobile &&
        !isCreditAnalysisScreen &&
        !isListIncompleteGuarantorsScreen && (
          <Slider nextEnabled={nextEnabled} onSlideUp={goToPreviousScreen} onSlideDown={goToNextScreen} />
        )}
      <List isMobile={isMobile}>
        {!props.isLoading && currentScreen && (
          <Screen
            setShowHelpModal={setShowHelpModal}
            showHelpModal={showHelpModal}
            onAnswered={onAnswered}
            screen={currentScreen}
            visuals={visuals}
            onNextClick={() => onNextClick()}
            onHelpClick={() => setShowHelpModal(true)}
            onSendClick={onSendClick}
            setNextEnabled={setNextEnabled}
            nextEnabled={nextEnabled}
            isMobile={isMobile}
            goToPreviousScreen={goToPreviousScreen}
            goToNextScreen={goToNextScreen}
            goToScreen={screen => goToScreen(screen)}
            {...props}
          />
        )}
      </List>
      {visuals &&
        currentScreen &&
        !isCreditAnalysisScreen &&
        !isListIncompleteGuarantorsScreen &&
        !IsCreditApprovedScreen &&
        currentScreen.nextStep.screen !== 'TermsAndConditionsScreen' &&
        (isMobile ? (
          <FooterMobile
            progress={currentScreen.progress}
            visuals={visuals}
            onSlideUp={goToPreviousScreen}
            onSlideDown={goToNextScreen}
            nextEnabled={nextEnabled}
          />
        ) : (
          <Footer progress={currentScreen.progress} visuals={visuals} />
        ))}
      {screenName !== 'CreditAnalysisScreen' && <Whatsapp match={props.match} />}
    </>
  )
}

export default RegisterView
