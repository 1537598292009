import { useState } from 'react'

export const useMonthlyEarnsValidator = (onAnswered, setNextEnabled, question) => {
  const [earns, setEarns] = useState(question.value || null)

  const onValueChange = monthlyEarns => {
    onAnswered(monthlyEarns)
    setNextEnabled(true)

    if (monthlyEarns === 'R$ 0,00' && earns !== null) {
      setEarns(null)
      setNextEnabled(false)

      return
    }
    setEarns(monthlyEarns)
  }

  return [earns, onValueChange]
}
