import React from 'react'
import { View } from './style'
import { Selector } from '../selector'
import { useCivilStatePicker } from './hooks'

export const CivilState = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, civilStates, selectedCivilState] = useCivilStatePicker(onAnswered, setNextEnabled, question)
  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={civilStates}
        value={selectedCivilState}
        onValueChange={onValueChange}
      />
    </View>
  )
}
