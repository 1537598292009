import styled, { keyframes } from 'styled-components'
import { Button } from '../../globalStyles'

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Overlay = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  display: flex;
  -webkit-animation-name: ${opacity}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${opacity};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

const scale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

export const Container = styled.div`
  position: relative;
  max-width: 800px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background: white;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  padding: ${({ isMobile }) => (isMobile ? '25px' : '30px')};
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '80px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '250px')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-around' : 'space-between')};
  -webkit-animation-name: ${scale}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${scale};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

export const Header = styled.div`
  position: relative;
`
export const Body = styled.div`
  position: relative;
  width: ${({ isMobile }) => (isMobile ? '100%' : '80%')};
`
export const Footer = styled.div`
  position: relative;
`

export const Title = styled.p`
  color: ${({ visuals }) => visuals.bgColor};
  font-size: 2rem;
  line-height: 48px;
  font-weight: 600;
`
export const Description = styled.p`
  font-size: 1rem;
  line-height: 25px;
  text-align: justify;
`
export const MoreHelpText = styled.p`
  margin-top: 15px;
  opacity: 0.6;
`
export const MoreHelpLink = styled.a`
  color: ${({ visuals }) => visuals.bgColor};
`
export const GotItButton = styled(Button)`
  width: 220px;
  height: 40px;
  color: ${({ visuals }) => visuals.bgColor};
  background: transparent;
  border: 1px solid ${({ visuals }) => visuals.bgColor};
  border-radius: 3px;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  :hover {
    background: ${({ visuals }) => visuals.bgColor};
    color: #fff;
  }
`

export const CloseModal = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
  cursor: pointer;
  justify-content: flex-end;
`
