import React from 'react'
import styled from 'styled-components'

import ImgWhats from '../../../assets/images/whatsapp-logo.png'

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`

export const Text = styled.p`
  margin: 20px 0;
`

export const EmailTo = styled.a``

export const WrapperHelp = styled.div`
  margin-top: 20px;
  text-align: left;
`

export const ContainerWhatsApp = styled.div`
  position: absolute;
  bottom: 25px;
  right: 25px;
`

export const Link = styled.a``

export const Img = styled.img``

export default () => {
  return (
    <WrapperHelp>
      <Text>Tá com alguma dúvida?</Text>
      <Text>Manda um email pra gente no:</Text>
      <EmailTo href="mailto:papo@provi.com.br">papo@provi.com.br</EmailTo>
      <Text>
        ou clica no botão abaixo pra falar pelo chat{' '}
        <span role="img" aria-labelledby="Emoji happy">
          😉
        </span>{' '}
      </Text>

      <ContainerWhatsApp>
        <Link href="https://api.whatsapp.com/send?phone=551130427055&text=Oi%20Provi%21%20Pode%20me%20ajudar%3F">
          <Img src={ImgWhats} alt="Provi WhatsApp | clique para mandar uma mensagem" />
        </Link>
      </ContainerWhatsApp>
    </WrapperHelp>
  )
}
