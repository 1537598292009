import React from 'react'
import './style.css'

export const TimerDocSign = ({ counter = 3 }) => {
  return (
    <div class="container-timer">
      <p class="text-container-timer">Estamos gerando seu contrato</p>
      <div class="timer">
        <span>{counter}</span>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
