import { useState, useEffect } from 'react'
import { validateFullName } from '../../utils'

export const useFullNameValidator = (onAnswered, setNextEnabled, question) => {
  const [showError, setShowError] = useState(false)
  const [value, setValue] = useState(question.value)

  useEffect(() => {
    if (question.value) {
      onAnswered && onAnswered(question.value)
      setNextEnabled(true)
      return
    }

    setNextEnabled(false)
  }, [])

  const onDismiss = () => {
    setShowError(false)
  }

  const onValueChange = fullName => {
    setValue(fullName)
    onAnswered(fullName)

    setShowError(!validateFullName(fullName))
    setNextEnabled(validateFullName(fullName))
  }

  return [showError, onDismiss, onValueChange, value]
}
