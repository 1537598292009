import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  display: flex;
  margin-top: 17px;
  padding-left: 8px;
  background: #ff0e0e;
  box-sizing: border-box;
  border-radius: 3px;
  justify-content: space-between;
  align-items: stretch;
  max-width: 300px;
  -webkit-box-shadow: 5px 5px 5px -1px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 5px 5px 5px -1px rgba(0, 0, 0, 0.41);
  box-shadow: 5px 5px 5px -1px rgba(0, 0, 0, 0.41);
`
export const Text = styled.p`
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  font-weight: bold;
`
export const DismissButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 9px;
  padding-bottom: 2px;
`
export const DismissImage = styled.img``
