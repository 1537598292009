import React from 'react'
import { View } from './style'
import { Selector } from '../selector'
import { useCoursePicker } from './hooks'

export const Course = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, courses, selectedCourse] = useCoursePicker(onAnswered, setNextEnabled, question)
  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={courses}
        value={selectedCourse}
        onValueChange={onValueChange}
      />
    </View>
  )
}
