import { sendForm, goToNext } from './api'
import logger from '../utils/logger'
import firebase from '../services/firebase'
import { showToast } from '../components/toast'

export const sendQuestionAnswer = async question => {
  const {
    answer,
    nextStep: { endpoint, uniqueName }
  } = question
  const data = { data: answer }
  data.PartnerId = question.nextStep.PartnerId

  const CreditRequestId = localStorage.getItem('crid')

  if (CreditRequestId) data.CreditRequestId = CreditRequestId

  if (question.nextStep.goToNextScreen) {
    return { data: question.nextStep.goToNextScreen }
  }

  if (endpoint) {
    try {
      await sendForm({ endpoint, data })
      const { nextScreen } = await goToNext(uniqueName)
      if (nextScreen === 'DataConfirmationScreen' || nextScreen === 'FullNameScreen') {
        localStorage.removeItem('cpf')
      }
      return { data: nextScreen }
    } catch (error) {
      let info = {}
      firebase.auth().onAuthStateChanged(user => {
        console.log('user', user)
        info.firebaseId = user.uid
        logger({ error, info })
      })
      showToast(error.msg)
      return {
        err: error.msg
      }
    }
  }
}
