const { REACT_APP_FRONT_END_TOKEN_END, REACT_APP_API_ENV } = process.env

const URLS = {
  production: 'https://app.provi.com.br',
  remote_development: 'https://dev.provi.com.br',
  staging: 'https://staging.provi.com.br',
  local_development: 'http://localhost:5000'
}

let _ROOT_URL

switch (REACT_APP_API_ENV) {
  case 'production':
    console.log('[production] -> server')
    _ROOT_URL = URLS.production
    break
  case 'staging':
    console.log('[staging] -> server')
    _ROOT_URL = URLS.staging
    break
  case 'development':
    console.log('[development] -> server')
    _ROOT_URL = URLS.remote_development
    break
  case 'local':
    console.log('[development] -> server')
    _ROOT_URL = URLS.local_development
    break
  default:
    console.log('[localhost] -> server')
    _ROOT_URL = URLS.local_development
    break
}

console.log('[ROOT_URL]: ', _ROOT_URL)

export const CEP_URL = 'https://api.postmon.com.br/v1/cep'
export const ROOT_URL = _ROOT_URL
export const FRONT_END_TOKEN = REACT_APP_FRONT_END_TOKEN_END
export const termsAndConditionsLink = 'https://provi.com.br/terms-and-conditions'
export const privacyPolicyLink = 'https://provi.com.br/privacy-policy'
export const scrText = 'A consulta e compartilhamento de informações do SCR do banco central.'

const getEmbeddedURL = {
  development: 'https://ms-docusign-dev.provi.com.br',
  staging: 'https://ms-docusign-staging.provi.com.br',
  production: 'https://ms-docusign.provi.com.br',
  prod: 'https://ms-docusign.provi.com.br'
}

export const EMBEDDED_BASE_URL = getEmbeddedURL[REACT_APP_API_ENV]

export const BASIC_INFO_STATUS = {
  incomplete: 'incomplete',
  analysis: 'analysis',
  approved: 'approved',
  review: 'review',
  denied: 'denied'
}
export const DOCUMENT_STATUS = {
  incomplete: 'incomplete',
  review: 'review',
  analysis: 'analysis',
  approved: 'approved',
  denied: 'denied'
}
export const GUARANTOR_STATUS = {
  unneeded: 'unneeded',
  needed: 'needed',
  incomplete: 'incomplete',
  analysis: 'analysis',
  review: 'review',
  approved: 'approved',
  denied: 'denied'
}
export const STATUS = {
  incomplete: 'incomplete',
  analysis: 'analysis',
  waiting_contract: 'waiting_contract',
  approved: 'approved',
  waiting_signature: 'waiting_signature',
  signed: 'signed',
  transferred: 'transferred',
  settled: 'settled',
  denied: 'denied',
  canceled: 'canceled'
}

export const SCREENS = {
  CPFScreen: 'CPFScreen',
  AddressScreen: 'AddressScreen',
  FullNameScreen: 'FullNameScreen',
  BirthDateScreen: 'BirthDateScreen',
  PhoneScreen: 'PhoneScreen',
  WaitingPhoneConfirmationScreen: 'WaitingPhoneConfirmationScreen',
  SanarGraduationsScreen: 'SanarGraduationsScreen',
  GraduationDateScreen: 'GraduationDateScreen',
  ChoosePlanScreen: 'ChoosePlanScreen',
  IntendedWayToPayScreen: 'IntendedWayToPayScreen',
  IntendedWayToExpendScreen: 'IntendedWayToExpendScreen',
  NumberOfDependentsScreen: 'NumberOfDependentsScreen',
  ExistingExternalLoanScreen: 'ExistingExternalLoanScreen',
  RequestGuarantorScreen: 'RequestGuarantorScreen',
  ListGuarantorsScreen: 'ListGuarantorsScreen',
  NegativeFeedbackGuarantorScreen: 'NegativeFeedbackGuarantorScreen',
  SelfieUploadScreen: 'SelfieUploadScreen',
  ProofOfAddressUploadScreen: 'ProofOfAddressUploadScreen',
  PersonalDocumentUploadScreen: 'PersonalDocumentUploadScreen',
  SchoolRecordsScreen: 'SchoolRecordsScreen',
  ProofOfEnrollmentScreen: 'ProofOfEnrollmentScreen',
  BankAccountScreen: 'BankAccountScreen',
  CreditAnalysisScreen: 'CreditAnalysisScreen',
  TermsAndConditions: 'TermsAndConditions',
  ListIncompleteGuarantorsScreen: 'ListIncompleteGuarantorsScreen',
  CreditPreApprovedScreen: 'CreditPreApprovedScreen',
  CreditApprovedScreen: 'CreditApprovedScreen',
  DocumentsPreApprovedScreen: 'DocumentsPreApprovedScreen',
  ConfirmPaymentFlowScreen: 'ConfirmPaymentFlowScreen',
  ListCreditRequestScreen: 'ListCreditRequestScreen'
}

export const KEYS = {
  smartlook: 'ccaaede6ad778caf1a4f51b7ee642b392632fb3c'
}

export const BANKS = [
  { id: 141, code: '117', label: 'Advanced Corretora de Câmbio Ltda. - 117' },
  { id: 166, code: '172', label: 'Albatross Corretora de Câmbio e Valores S.A - 172' },
  { id: 172, code: '188', label: 'Ativa Investimentos S.A. Corret. de Tít. Câmbio e Valores - 188' },
  { id: 189, code: '654', label: 'Banco A. J. Renner S.A. - 654' },
  { id: 9, code: '246', label: 'Banco ABC Brasil S.A. - 246' },
  { id: 10, code: '075', label: 'Banco ABN AMRO S.A. - 075' },
  { id: 115, code: '121', label: 'Banco Agiplan S.A - 121' },
  { id: 11, code: '025', label: 'Banco Alfa S.A. - 025' },
  { id: 12, code: '641', label: 'Banco Alvorada S.A. - 641' },
  { id: 13, code: '065', label: 'Banco Andbank (Brasil) S.A. - 065' },
  { id: 176, code: '213', label: 'Banco Arbi S.A. - 213' },
  { id: 14, code: '024', label: 'Banco BANDEPE S.A. - 024' },
  { id: 15, code: '740', label: 'Banco Barclays S.A. - 740' },
  { id: 16, code: '107', label: 'Banco BBM S.A. - 107' },
  { id: 17, code: '096', label: 'Banco BM&FBOVESPA S.A - 096' },
  { id: 18, code: '318', label: 'Banco BMG S.A. - 318' },
  { id: 19, code: '752', label: 'Banco BNP Paribas Brasil S.A. - 752' },
  { id: 20, code: '248', label: 'Banco Boavista Interatlântico S.A. - 248' },
  { id: 21, code: '169', label: 'Banco Bonsucesso Consignado S.A. - 169' },
  { id: 22, code: '218', label: 'Banco Bonsucesso S.A. - 218' },
  { id: 23, code: '069', label: 'Banco BPN Brasil S.A. - 069' },
  { id: 24, code: '063', label: 'Banco Bradescard S.A. - 063' },
  { id: 25, code: '036', label: 'Banco Bradesco BBI S.A. - 036' },
  { id: 142, code: '122', label: 'Banco Bradesco BERJ S.A. - 122' },
  { id: 26, code: '204', label: 'Banco Bradesco Cartões S.A. - 204' },
  { id: 27, code: '394', label: 'Banco Bradesco Financiamentos S.A. - 394' },
  { id: 4, code: '237', label: 'Banco Bradesco S.A. - 237' },
  { id: 28, code: '208', label: 'Banco BTG Pactual S.A. - 208' },
  { id: 29, code: '263', label: 'Banco Cacique S.A. - 263' },
  { id: 30, code: '473', label: 'Banco Caixa Geral - Brasil S.A. - 473' },
  { id: 182, code: '412', label: 'Banco Capital S. A. - 412' },
  { id: 31, code: '040', label: 'Banco Cargill S.A. - 040' },
  { id: 180, code: '266', label: 'Banco Cédula S.A. - 266' },
  { id: 32, code: '739', label: 'Banco Cetelem S.A. - 739' },
  { id: 33, code: '233', label: 'Banco Cifra S.A. - 233' },
  { id: 34, code: '745', label: 'Banco Citibank S.A. - 745' },
  { id: 177, code: '241', label: 'Banco Clássico S.A. - 241' },
  { id: 35, code: '000', label: 'Banco CNH Industrial Capital S.A. - 000' },
  { id: 36, code: '095', label: 'Banco Confidence de Câmbio S.A. - 095' },
  { id: 37, code: '756', label: 'Banco Cooperativo do Brasil S.A. - BANCOOB - 756' },
  { id: 38, code: '748', label: 'Banco Cooperativo Sicredi S.A. - 748' },
  { id: 39, code: '222', label: 'Banco Credit Agricole Brasil S.A. - 222' },
  { id: 40, code: '505', label: 'Banco Credit Suisse (Brasil) S.A. - 505' },
  { id: 41, code: '003', label: 'Banco da Amazônia S.A. - 003' },
  { id: 42, code: '083', label: 'Banco da China Brasil S.A. - 083' },
  { id: 43, code: '707', label: 'Banco Daycoval S.A. - 707' },
  { id: 181, code: '300', label: 'Banco de la Nacion Argentina - 300' },
  { id: 184, code: '495', label: 'Banco de La Provincia de Buenos Aires - 495' },
  { id: 183, code: '494', label: 'Banco de La Republica Oriental del Uruguay - 494' },
  { id: 44, code: '000', label: 'Banco de Lage Landen Brasil S.A. - 000' },
  { id: 45, code: '456', label: 'Banco de Tokyo-Mitsubishi UFJ Brasil S.A. - 456' },
  { id: 1, code: '001', label: 'Banco do Brasil S.A. - 001' },
  { id: 46, code: '047', label: 'Banco do Estado de Sergipe S.A. - 047' },
  { id: 47, code: '037', label: 'Banco do Estado do Pará S.A. - 037' },
  { id: 48, code: '041', label: 'Banco do Estado do Rio Grande do Sul S.A. - 041' },
  { id: 49, code: '004', label: 'Banco do Nordeste do Brasil S.A. - 004' },
  { id: 50, code: '265', label: 'Banco Fator S.A. - 265' },
  { id: 51, code: '224', label: 'Banco Fibra S.A. - 224' },
  { id: 52, code: '626', label: 'Banco Ficsa S.A. - 626' },
  { id: 53, code: '000', label: 'Banco Ford S.A. - 000' },
  { id: 54, code: '000', label: 'Banco GMAC S.A. - 000' },
  { id: 55, code: '612', label: 'Banco Guanabara S.A. - 612' },
  { id: 56, code: '000', label: 'Banco Honda S.A. - 000' },
  { id: 57, code: '000', label: 'Banco IBM S.A. - 000' },
  { id: 58, code: '012', label: 'Banco INBURSA de Investimentos S.A. - 012' },
  { id: 59, code: '604', label: 'Banco Industrial do Brasil S.A. - 604' },
  { id: 60, code: '653', label: 'Banco Indusval S.A. - 653' },
  { id: 187, code: '630', label: 'Banco Intercap S.A. - 630' },
  { id: 61, code: '077', label: 'Banco Intermedium S.A. - 077' },
  { id: 62, code: '249', label: 'Banco Investcred Unibanco S.A. - 249' },
  { id: 63, code: '184', label: 'Banco Itaú BBA S.A. - 184' },
  { id: 64, code: '029', label: 'Banco Itaú BMG Consignado S.A. - 029' },
  { id: 65, code: '000', label: 'Banco Itaú Veículos S.A. - 000' },
  { id: 66, code: '479', label: 'Banco ItauBank S.A - 479' },
  { id: 67, code: '376', label: 'Banco J. P. Morgan S.A. - 376' },
  { id: 68, code: '074', label: 'Banco J. Safra S.A. - 074' },
  { id: 69, code: '217', label: 'Banco John Deere S.A. - 217' },
  { id: 195, code: '757', label: 'Banco Keb Hana do Brasil S. A. - 757' },
  { id: 70, code: '600', label: 'Banco Luso Brasileiro S.A. - 600' },
  { id: 178, code: '243', label: 'Banco Máxima S.A. - 243' },
  { id: 71, code: '389', label: 'Banco Mercantil do Brasil S.A. - 389' },
  { id: 72, code: '370', label: 'Banco Mizuho do Brasil S.A. - 370' },
  { id: 73, code: '746', label: 'Banco Modal S.A. - 746' },
  { id: 125, code: '274', label: 'Banco Money Plus - 274' },
  { id: 122, code: '735', label: 'Banco Neon S.A. - 735' },
  { id: 74, code: '212', label: 'Banco Original S.A. - 212' },
  { id: 190, code: '712', label: 'Banco Ourinvest S.A. - 712' },
  { id: 75, code: '623', label: 'Banco PAN S.A. - 623' },
  { id: 76, code: '611', label: 'Banco Paulista S.A. - 611' },
  { id: 186, code: '613', label: 'Banco Pecúnia S. A. - 613' },
  { id: 77, code: '094', label: 'Banco Petra S.A. - 094' },
  { id: 78, code: '643', label: 'Banco Pine S.A. - 643' },
  { id: 79, code: '000', label: 'Banco PSA Finance Brasil S.A. - 000' },
  { id: 80, code: '747', label: 'Banco Rabobank International Brasil S.A. - 747' },
  { id: 81, code: '633', label: 'Banco Rendimento S.A. - 633' },
  { id: 191, code: '741', label: 'Banco Ribeirão Preto S.A. - 741' },
  { id: 82, code: '120', label: 'Banco Rodobens S.A. - 120' },
  { id: 5, code: '422', label: 'Banco Safra S.A. - 422' },
  { id: 6, code: '033', label: 'Banco Santander (Brasil) S.A. - 033' },
  { id: 192, code: '743', label: 'Banco Semear S.A. - 743' },
  { id: 194, code: '754', label: 'Banco Sistema S.A. - 754' },
  { id: 83, code: '366', label: 'Banco Société Générale Brasil S.A. - 366' },
  { id: 188, code: '637', label: 'Banco Sofisa S. A. - 637' },
  { id: 84, code: '464', label: 'Banco Sumitomo Mitsui Brasileiro S.A. - 464' },
  { id: 85, code: '082', label: 'Banco Topázio S.A. - 082' },
  { id: 86, code: '000', label: 'Banco Toyota do Brasil S.A. - 000' },
  { id: 87, code: '634', label: 'Banco Triângulo S.A. - 634' },
  { id: 88, code: '000', label: 'Banco Volkswagen S.A. - 000' },
  { id: 89, code: '000', label: 'Banco Volvo Brasil S.A. - 000' },
  { id: 90, code: '655', label: 'Banco Votorantim S.A. - 655' },
  { id: 91, code: '610', label: 'Banco VR S.A. - 610' },
  { id: 92, code: '119', label: 'Banco Western Union do Brasil S.A. - 119' },
  { id: 143, code: '124', label: 'Banco Woori Bank do Brasil S.A. - 124' },
  { id: 93, code: '021', label: 'BANESTES S.A. Banco do Estado do Espírito Santo - 021' },
  { id: 94, code: '719', label: 'Banif-Banco Internacional do Funchal (Brasil)S.A. - 719' },
  { id: 95, code: '755', label: 'Bank of America Merrill Lynch Banco Múltiplo S.A. - 755' },
  { id: 96, code: '081', label: 'BBN Banco Brasileiro de Negócios S.A. - 081' },
  { id: 97, code: '250', label: 'BCV - Banco de Crédito e Varejo S.A. - 250' },
  { id: 157, code: '144', label: 'Bexs Banco de Câmbio S.A. - 144' },
  { id: 179, code: '253', label: 'Bexs Corretora de Câmbio S/A - 253' },
  { id: 149, code: '134', label: 'BGC Liquidez Distribuidora de Títulos e Valores Mobil. Ltda. - 134' },
  { id: 98, code: '017', label: 'BNY Mellon Banco S.A. - 017' },
  { id: 144, code: '126', label: 'BR Partners Banco de Investimento S.A. - 126' },
  { id: 99, code: '125', label: 'Brasil Plural S.A. - Banco Múltiplo - 125' },
  { id: 100, code: '070', label: 'BRB - Banco de Brasília S.A. - 070' },
  { id: 167, code: '173', label: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A. - 173' },
  { id: 155, code: '142', label: 'Broker Brasil Corretora de Câmbio Ltda. - 142' },
  { id: 128, code: '080', label: 'BT Corretora de Câmbio Ltda. - 080' },
  { id: 2, code: '104', label: 'Caixa Econômica Federal - 104' },
  { id: 146, code: '130', label: 'Caruana S.A. Sociedade de Crédito - 130' },
  { id: 163, code: '159', label: 'Casa do Crédito S.A. Soc. de Créd. ao Microempreendedor - 159' },
  { id: 140, code: '114', label: 'Central das Coop. Econ. e Créd. Mútuo do Est do Esp. Santo - 114' },
  { id: 130, code: '091', label: 'Central de Coop. de Econ. e Créd. Mútuo do Est RS - Unicred - 091' },
  { id: 101, code: '320', label: 'China Construction Bank (Brasil) Banco Múltiplo S.A. - 320' },
  { id: 8, code: '477', label: 'Citibank N.A. - 477' },
  { id: 169, code: '180', label: 'CM Capital Markets Corretora de Câmbio - 180' },
  { id: 145, code: '127', label: 'Codepe Corretora de Valores e Câmbio S.A. - 127' },
  { id: 164, code: '163', label: 'Commerzbank Brasil S.A. - Banco Múltiplo - 163' },
  { id: 129, code: '090', label: 'Coop. Central de Econ. e Créd. Mútuo - Sicoob Unimais - 090' },
  { id: 116, code: '085', label: 'Cooperativa Central de Crédito Urbano-CECRED - 085' },
  { id: 121, code: '099', label: 'Cooperativa Central de Economia e Credito Mutuo das Unicreds - 099' },
  { id: 124, code: '089', label: 'Cooperativa de Crédito Rural da Região da Mogiana - 089' },
  { id: 132, code: '098', label: 'Credialiança Cooperativa de Crédito Rural - 098' },
  { id: 120, code: '133', label: 'Cresol Confederação - 133' },
  { id: 170, code: '182', label: 'Dacasa Financeira S/A - Sociedade de Crédito - 182' },
  { id: 102, code: '487', label: 'Deutsche Bank S.A. - Banco Alemão - 487' },
  { id: 154, code: '140', label: 'Easynvest - Título Corretora de Valores SA - 140' },
  { id: 161, code: '149', label: 'Facta Financeira S.A. - Crédito Financiamento e Invest - 149' },
  { id: 175, code: '196', label: 'Fair Corretora de Câmbio S.A. - 196' },
  { id: 152, code: '138', label: 'Get Money Corretora de Câmbio S.A. - 138' },
  { id: 103, code: '064', label: 'Goldman Sachs do Brasil Banco Múltiplo S.A. - 064' },
  { id: 150, code: '135', label: 'Gradual Corretora de Câmbio - 135' },
  { id: 168, code: '177', label: 'Guide Investimentos S.A. Corretora de Valores - 177' },
  { id: 159, code: '146', label: 'Guitta Corretora de Câmbio Ltda. - 146' },
  { id: 104, code: '078', label: 'Haitong Banco de Investimento do Brasil S.A. - 078' },
  { id: 105, code: '062', label: 'Hipercard Banco Múltiplo S.A. - 062' },
  { id: 173, code: '189', label: 'HS Financeira S/A Crédito - 189' },
  { id: 7, code: '399', label: 'HSBC Bank Brasil S.A. - Banco Múltiplo - 399' },
  { id: 162, code: '157', label: 'ICAP do Brasil Corretora de Títulos e Valores Mobil. Ltda. - 157' },
  { id: 148, code: '132', label: 'ICBC do Brasil Banco Múltiplo S.A. - 132' },
  { id: 106, code: '492', label: 'ING Bank N.V. - 492' },
  { id: 153, code: '139', label: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo - 139' },
  { id: 107, code: '652', label: 'Itaú Unibanco Holding S.A. - 652' },
  { id: 3, code: '341', label: 'Itaú Unibanco S.A. - 341' },
  { id: 108, code: '488', label: 'JPMorgan Chase Bank, National Association - 488' },
  { id: 136, code: '105', label: 'Lecca Crédito - 105' },
  { id: 158, code: '145', label: 'Levycam - Corretora de Câmbio e Valores Ltda. - 145' },
  { id: 139, code: '113', label: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios - 113' },
  { id: 109, code: '128', label: 'MS Bank S.A. Banco de Câmbio - 128' },
  { id: 151, code: '137', label: 'Multimoney Corretora de Câmbio Ltda - 137' },
  { id: 126, code: '014', label: 'Natixis Brasil S.A. Banco Múltiplo - 014' },
  { id: 174, code: '191', label: 'Nova Futura Corretora de Títulos e Valores Mobil. Ltda. - 191' },
  { id: 193, code: '753', label: 'Novo Banco Continental S.A. - Banco Múltiplo - 753' },
  { id: 119, code: '260', label: 'Nu Pagamentos S.A (Nubank) - 260' },
  { id: 138, code: '111', label: 'Oliveira Trust Distr. de Títulos e Valores Mobil. S.A. - 111' },
  { id: 0, code: '000', label: 'Outros - 000' },
  { id: 110, code: '254', label: 'Paraná Banco S.A. - 254' },
  { id: 133, code: '100', label: 'Planner Corretora de Valores S.A. - 100' },
  { id: 131, code: '093', label: 'Pólocred Soc. Créd. Microempreendedor e à Emp. de Peq Porte - 093' },
  { id: 137, code: '108', label: 'PortoCred S.A. Crédito - 108' },
  { id: 134, code: '101', label: 'Renascença Distr. de Títulos e Valores Mobiliários Ltda. - 101' },
  { id: 160, code: '147', label: 'Rico Corretora de Títulos e Valores Mobiliários S.A. - 147' },
  { id: 165, code: '167', label: 'S. Hayata Corretora de Câmbio S.A. - 167' },
  { id: 111, code: '751', label: 'Scotiabank Brasil S.A. Banco Múltiplo - 751' },
  { id: 185, code: '545', label: 'Senso Corretora de Câmbio e Valores Mobiliários S.A. - 545' },
  { id: 114, code: '756', label: 'SICOOB - 756' },
  { id: 171, code: '183', label: 'Socred S.A. - Sociedade de Crédito ao Microempreendedor - 183' },
  { id: 112, code: '118', label: 'Standard Chartered Bank (Brasil) S/A–Bco Invest. - 118' },
  { id: 117, code: '197', label: 'Stone Pagamentos S.A - 197' },
  { id: 156, code: '143', label: 'Treviso Corretora de Câmbio S.A. - 143' },
  { id: 147, code: '131', label: 'Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda. - 131' },
  { id: 113, code: '129', label: 'UBS Brasil Banco de Investimento S.A. - 129' },
  { id: 127, code: '015', label: 'UBS Brasil Corretora de Câmbio - 015' },
  { id: 118, code: '136', label: 'Unicred do Brasil - 136' },
  { id: 123, code: '084', label: 'Unicred Norte do Paraná - 084' },
  { id: 135, code: '102', label: 'XP Invest. Corret. de Câmbio Títulos e Valores Mobil. S.A - 102.' }
]

export const MOST_USED_BANKS = [
  'Banco Bradesco S.A. - 237',
  'Banco do Brasil S.A. - 001',
  'Caixa Econômica Federal - 104',
  'Itaú Unibanco S.A. - 341',
  'Nu Pagamentos S.A (Nubank) - 260',
  'Banco Santander (Brasil) S.A. - 033',
  'Banco Intermedium S.A. - 077',
  'Banco Neon S.A. - 735'
]

export const ERRORS = {
  default: 'Ops, ocorreu algum erro 😔'
}
