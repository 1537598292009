import styled from 'styled-components'

export const Container = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 100vw;
  height: 100vh;
  background: ${({ visuals }) => visuals.background};
`

export const Text = styled.p`
  margin: 0.8em 0;
  color: #fff;
  font-size: ${({ isMobile }) => (isMobile ? '1em' : '1.5em')};
`

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: ${({ isMobile }) => (isMobile ? '1.25em' : '2em')};
  color: #fff;
`

export const Link = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff;
    text-decoration: none;
  }
`
