import React from 'react'
import { NextButton } from '../../../components/next-button'
import { HelpButton } from '../../../components/help-button'
import { ModalAreYouSure } from '../../../components/modal-are-you-sure'
import { View, TitleContainer, Title, Hint, BodyContainer, ButtonContainer } from './style'
import { useProvider } from './hooks'

export const Question = props => {
  const {
    isMobile,
    onNextClick,
    onHelpClick,
    onAnswered,
    question,
    visuals,
    hidden,
    setNextEnabled,
    nextEnabled,
    goToPreviousScreen,
    goToScreen,
    progress,
    goToNextScreen
  } = props
  const [screens, setIsShowModal, isShowModal, isMinorOfAge] = useProvider({
    setNextEnabled,
    onAnswered,
    question,
    visuals,
    isMobile,
    nextEnabled,
    goToPreviousScreen,
    goToScreen,
    goToNextScreen
  })

  const nextStep = () => {
    const { uniqueName } = props.screen.nextStep

    // if (uniqueName === 'BankAccountScreen') {
    //   return setIsShowModal(true)
    // }
    if (uniqueName === 'BirthDateScreen' && isMinorOfAge(props.screen.answer)) {
      return props.setShowHelpModal(true)
    }
    return onNextClick()
  }

  return (
    <View style={{ opacity: hidden ? '0.2' : '1.0' }}>
      <TitleContainer>
        <Title isMobile={isMobile} visuals={visuals}>
          <div>
            <span>{progress.completed}</span>
            {!isMobile && ' -'} {question.title}
          </div>
        </Title>

        {question.help && <HelpButton visuals={visuals} onClick={onHelpClick} />}
      </TitleContainer>
      {question.hint && <Hint visuals={visuals}>{question.hint}</Hint>}
      <BodyContainer>{screens}</BodyContainer>
      <ButtonContainer>
        {question.buttonText && (
          <NextButton
            isMobile={isMobile}
            enabled={nextEnabled}
            visuals={visuals}
            title={question.buttonText}
            onClick={nextStep}
          />
        )}
      </ButtonContainer>
      {isShowModal && (
        <ModalAreYouSure nextStep={onNextClick} visuals={visuals} isMobile={isMobile} onDismiss={() => setIsShowModal(false)} />
      )}
    </View>
  )
}
