import React from 'react'
import { View, CodeView, CodeNumber, SendCode } from './style'
import { useCodeValidator, useInputFocus } from './hooks'

const ConfirmationCodeView = ({ question, isMobile, onAnswered, visuals, hidden, setNextEnabled }) => {
  const [code, setCodeNumber, sendCode] = useCodeValidator(onAnswered, question, setNextEnabled)
  const [addInput, setNextFocus] = useInputFocus()

  return (
    <View style={{ opacity: hidden ? '0.2' : '1.0' }}>
      <CodeView>
        {code.map((_, position) => (
          <CodeNumber
            autoFocus={position === 0}
            ref={addInput}
            key={position}
            visuals={visuals}
            onChange={({ target }) => {
              setCodeNumber(target.value, position)
              setNextFocus(target.value, position)
            }}
          />
        ))}
      </CodeView>
      <CodeView>
        <SendCode onClick={sendCode}>Reenviar código</SendCode>
      </CodeView>
    </View>
  )
}
export default ConfirmationCodeView
