import { useState, useEffect } from 'react'
import { submitGuarantor } from '../../services/api'
import { isValidCpf } from '../../utils'

export const useGuarantorValidate = (onAnswered, question, setNextEnabled) => {
  const [isShowModalNegative, setIsShowModalNegative] = useState(false)
  const [showError, setShowError] = useState(false)
  const [value, setValue] = useState()

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      return
    }

    setNextEnabled(false)
  }, [])

  const onSubmit = async () => {
    try {
      await submitGuarantor(value)
    } catch (_) {
      setIsShowModalNegative(true)
    }
  }

  const onDismiss = () => {
    setShowError(false)
  }

  const onValueChange = cpf => {
    setValue(cpf)
    onAnswered(cpf || question.value)
    if (cpf.includes('_')) {
      setNextEnabled(false)
      return
    }
    if (!isValidCpf(cpf.replace(/[^\d]+/g, ''))) {
      setShowError(true)
      setNextEnabled(false)
      return
    }

    setNextEnabled(true)
    setShowError(false)
  }

  return [showError, onDismiss, onValueChange, value, isShowModalNegative, setIsShowModalNegative, onSubmit]
}
