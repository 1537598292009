import React from 'react'
import { UploadBox } from '../upload-box'
import { useFileUpload } from './hooks'
import { View } from './style'

export const UploadDocuments = ({ visuals, isMobile, setNextEnabled, onAnswered, question }) => {
  const [onDrop, status, progress, numberOfUploads] = useFileUpload(onAnswered, setNextEnabled, question)

  return (
    <View>
      <UploadBox
        question={question}
        type="document"
        text="Documento aberto"
        progress={progress}
        status={status}
        isMobile={isMobile}
        onDrop={onDrop}
        numberOfUploads={numberOfUploads}
        visuals={visuals}
      />
    </View>
  )
}
