import { useState, useEffect } from 'react'

export const useCourseIntendedIndustry = (onAnswered, setNextEnabled, question) => {
  const options = question.options.map(item => ({
    label: item.name,
    id: item.id
  }))

  const initialState = options && options.filter(item => question.value === item.id)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onValueChange(initialState)
      onAnswered && onAnswered(initialState[0].id)
    }
  }, [])

  const [currentCourseIntendedIndustry, setCourseIntendedIndustry] = useState(initialState)

  const onValueChange = number => {
    setNextEnabled(true)
    setCourseIntendedIndustry(number)
    onAnswered(number.id)
  }

  return [onValueChange, options, currentCourseIntendedIndustry]
}
