import React from 'react'
const iconStyle = {
  position: 'relative',
  top: '-25px',
  left: '231px'
}
export const LockIcon = () => (
  <svg
    style={iconStyle}
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#999999"
    strokeWidth="1.5"
    strokeLinecap="square"
    strokeLinejoin="arcs"
  >
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
    <path d="M7 11V7a5 5 0 0 1 10 0v4" />
  </svg>
)
