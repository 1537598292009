import React from 'react'
import {
  Overlay,
  Container,
  Header,
  Body,
  Footer,
  Title,
  Description,
  GotItButton,
  SpanBold,
  Text,
  CloseModal,
  ContainerPortion,
  Number,
  TextNumberPortions,
  ContainerButton,
  LinkRegulation
} from './style'
import { currencyParser } from '../../../utils'
import { Close } from '../../../assets/svg/close'

export default ({ screen, visuals, isMobile, onDismiss, onSelectOption, plan }) => {
  return (
    <Overlay>
      <Container isMobile={isMobile}>
        <Header isMobile={isMobile}>
          <CloseModal onClick={onDismiss}>
            <Close />
          </CloseModal>
          <Title visuals={visuals} isMobile={isMobile}>
            Fluxo Mesada UP de <SpanBold>{currencyParser(plan.receiptValue * -1, 'R$')}</SpanBold>/mês:
          </Title>
        </Header>
        <Body isMobile={isMobile} visuals={visuals}>
          <Description isMobile={isMobile}>
            <Text color="#FF4A2D" size="19px">
              Fluxo de <SpanBold>recebimento</SpanBold>:
            </Text>
            <br />

            <Text>
              Primeiro recebimento em <SpanBold>{plan.firstReceiptDate}</SpanBold>
            </Text>
            <Text>
              Último recebimento em <SpanBold>{plan.lastReceiptDate}</SpanBold>
            </Text>

            <br />
            <Text color="#FF4A2D" size="19px">
              Fluxo de <SpanBold>pagamento:</SpanBold>
            </Text>
            <br />

            {Object.keys(plan.flowToPay).map((installments, index) => {
              return (
                <>
                  <ContainerPortion key={index}>
                    <Number>{index + 1}</Number>
                    <TextNumberPortions>
                      {plan.flowToPay[installments][0].date} a{' '}
                      {plan.flowToPay[installments][plan.flowToPay[installments].length - 1].date}
                      <br /> <SpanBold>{currencyParser(installments, 'R$')}/mês</SpanBold>
                    </TextNumberPortions>
                  </ContainerPortion>
                </>
              )
            })}

            <br />
            <Text>
              Número de parcelas a receber: <SpanBold>{plan.numberOfInstallmentsToReceive}x</SpanBold>
            </Text>
            <Text>
              Número de parcelas a pagar: <SpanBold>{plan.numberOfInstallmentsToPay}x</SpanBold>
            </Text>
            <Text>
              Taxa de juros mensal: <SpanBold>{plan.monthlyInterest}</SpanBold>
            </Text>
            <Text>
              Taxa de administração: <SpanBold>0%</SpanBold>
            </Text>
          </Description>
        </Body>
        <Footer isMobile={isMobile}>
          <ContainerButton>
            <GotItButton visuals={visuals} isMobile={isMobile} onClick={() => onSelectOption(plan.id)}>
              Selecionar plano
            </GotItButton>
            {/* <LinkRegulation>Clique para baixar o regulamento</LinkRegulation> */}
          </ContainerButton>
        </Footer>
      </Container>
    </Overlay>
  )
}
