import styled from 'styled-components'

export const View = styled.div`
  padding-top: 14px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Column = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`

export const Label = styled.p`
  margin-top: 10px;
  font-size: 15px;
  color: white;
`
