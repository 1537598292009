export const useWayToPayValidator = (onAnswered, setNextEnabled) => {
  const onValueChange = wayToPay => {
    onAnswered(wayToPay)

    if (!wayToPay || wayToPay === '') {
      setNextEnabled(false)

      return
    }
    if (wayToPay.length < 5 || wayToPay.length > 3000) {
      setNextEnabled(false)

      return
    }
    setNextEnabled(true)
  }

  return [onValueChange]
}
