import styled from 'styled-components'

export const Screen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')}
  background: #fffff;
`
export const BoundaryBox = styled.div`
  margin: auto;
  padding: 0 20px;
`
export const Error = styled.img`
  max-width: ${({ isMobile }) => (isMobile ? '95%' : '80%')};
`
export const Developer = styled.img`
  max-width: ${({ isMobile }) => (isMobile ? '82%' : '70%')};
`
