import { useState, useEffect } from 'react'
import moment from 'moment'

const formatGraduationDate = date => {
  let formatedDate = date
    .split('/')
    .reverse()
    .map((item, index) => (index === 1 ? parseInt(parseInt(item) - 1) : parseInt(item)))

  formatedDate = moment(formatedDate)
  return formatedDate.format('MM/YYYY')
}

export const useDateValidator = (onAnswered, setNextEnabled, question) => {
  const [showError, setShowError] = useState(false)
  const [value, setValue] = useState(question.value ? formatGraduationDate(question.value) : null)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onAnswered && onAnswered(question.value)
    }
  }, [])

  const onDismiss = () => {
    setShowError(false)
  }

  const isEmpty = dateStr => dateStr === ''

  const onValueChange = dateStr => {
    setValue(dateStr)
    onAnswered(`01/${dateStr}`)

    if (isEmpty(dateStr)) {
      setShowError(false)
      setNextEnabled(false)

      return
    }
    setShowError(false)
    setNextEnabled(true)
  }

  return [showError, onDismiss, onValueChange, value]
}
