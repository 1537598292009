import React from 'react'
import { Overlay, Container, Header, Body, Footer, Title, SubTitle, NoButton, YesButton, CloseModal, OkButton } from './style'
import { Close } from '../../assets/svg/close'

export const ModalQuestionAlert = ({
  visuals,
  isMobile,
  onDismiss,
  nextStep,
  title,
  subTitle,
  text,
  deleteFunc,
  item,
  setModalState,
  questionButtons,
  link,
  linkTitle
}) => {
  return (
    <Overlay>
      <Container isMobile={isMobile}>
        {onDismiss && (
          <CloseModal onClick={onDismiss}>
            <Close />
          </CloseModal>
        )}
        <Header>
          <Title visuals={visuals} isMobile={isMobile}>
            {title}
          </Title>
          <SubTitle visuals={visuals} isMobile={isMobile}>
            {subTitle}
          </SubTitle>
          <SubTitle visuals={visuals} isMobile={isMobile}>
            <a target="_blank" href={link} rel="noopener noreferrer">
              {linkTitle}
            </a>
          </SubTitle>
        </Header>
        <Body>{text}</Body>
        <Footer isMobile={isMobile}>
          {questionButtons ? (
            <>
              <NoButton visuals={visuals} isMobile={isMobile} onClick={() => setModalState(false)}>
                não
              </NoButton>
              <YesButton
                visuals={visuals}
                isMobile={isMobile}
                onClick={() => {
                  deleteFunc && deleteFunc(item)
                  setModalState(false)
                }}
              >
                sim
              </YesButton>
            </>
          ) : (
            <OkButton isMobile={isMobile} onClick={() => setModalState(false)}>
              Ok
            </OkButton>
          )}
        </Footer>
      </Container>
    </Overlay>
  )
}
