import { useState, useEffect } from 'react'
import axios from 'axios'
import { CEP_URL } from '../../constants'

export const useCepValidator = (onAnswered, setNextEnabled, question) => {
  const [value, setValue] = useState({
    zipcode: '',
    state: '',
    city: '',
    district: '',
    street: '',
    number: ''
  })
  const [isShowAddress, setIsShowAddress] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      setValue(question.value)
      setIsShowAddress(true)
      onAnswered && onAnswered(question.value)
      return
    }

    setNextEnabled(false)
  }, [])

  const getCep = async zipcode => {
    setIsloading(true)
    try {
      let { data } = await axios.get(`${CEP_URL}/${zipcode}`)
      let address = {
        zipcode: zipcode,
        state: data.estado,
        city: data.cidade,
        district: data.bairro,
        street: data.logradouro
      }
      setValue(address)
      onAnswered(address)
      setIsShowAddress(true)
      setNextEnabled(true)
      setShowError(false)
    } catch (e) {
      setIsShowAddress(true)
      console.error(e)
    }
    setIsloading(false)
  }

  const onDismiss = () => {
    setShowError(false)
  }

  const onValueChange = zipcode => {
    const _zipcode = zipcode.replace(/[^\d]+/g, '')

    setValue({ ...value, zipcode: _zipcode })
    onAnswered({ ...value, zipcode: _zipcode })

    if (_zipcode.length === 8) getCep(_zipcode)
    if (_zipcode.length < 8) setNextEnabled(false)
    else setNextEnabled(true)
  }

  const onFormChange = (formValue, identifier) => {
    const newValues = { ...value, [identifier]: formValue }
    setValue(newValues)
    onAnswered(newValues)
    if (newValues.zipcode && newValues.state && newValues.city && newValues.district && newValues.street && newValues.number) {
      setShowError(false)
      setNextEnabled(true)
    } else {
      setShowError(true)
      setNextEnabled(false)
    }
  }

  return [showError, onDismiss, onValueChange, onFormChange, value, isLoading, isShowAddress]
}
