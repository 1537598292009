import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  padding-top: 14px;
`
export const CourseValue = styled.p`
  font-size: 30px;
  line-height: 41px;
  color: ${({ visuals }) => visuals.questionColor};
`
export const Simulate = styled.p`
  padding-top: 20px;
  font-size: 15px;
  line-height: 20px;
  color: ${({ visuals }) => visuals.questionColor};
`
export const OptionsList = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: row;
`
export const Option = styled(Button)`
  border: 1px solid ${({ visuals, isSelected }) => (isSelected ? 'transparent' : visuals.questionColor)};
  background: ${({ visuals, isSelected }) => (isSelected ? visuals.buttonBgColor : 'transparent')};
  margin: 2px;
  border-radius: 8px;
`
export const OptionText = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ visuals }) => visuals.questionColor};
`
export const OptionDetails = styled.div`
  padding-top: 11px;
  max-width: 250px;
`
export const Detail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const DetailLabel = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: ${({ visuals }) => visuals.questionColor};
`
export const DetailValue = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: ${({ visuals }) => visuals.questionColor};
`
