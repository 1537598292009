import styled from 'styled-components'
import { Button as DefaultButton } from '../../globalStyles'

export const View = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 5px;
`
export const Button = styled(DefaultButton)`
  opacity: ${({ enabled }) => (enabled ? '1' : '0.5')};
  color: white;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ visuals }) => visuals.buttonBgColor};
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
`
export const ButtonTitle = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-right: 16px;
`
export const Hightlight = styled.span`
  font-weight: bold;
`
export const TextContainer = styled.div`
  margin-left: 17px;
  display: flex;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
`
