import { useState, useEffect } from 'react'

export const usePhoneValidator = (onAnswered, setNextEnabled, question) => {
  const [phone, setPhone] = useState(question.value)
  const [showError, setShowError] = useState(false)
  // const [allowWhatsAppContact, setAllowWhatsAppContact] = useState(false)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onAnswered && onAnswered(question.value)
      return
    }

    setNextEnabled(false)
  }, [])

  const onDismiss = () => {
    setShowError(false)
  }

  const onValueChange = value => {
    setPhone(value)
    onAnswered(value)

    if (!value) return setNextEnabled(false)

    const _value = Number(value.replace(/[^\d]+/g, ''))

    if (typeof _value !== 'number' || (String(_value).length !== 11 && String(_value).length !== 10)) {
      setNextEnabled(false)
      return
    }

    setNextEnabled(true)
  }

  return [showError, onDismiss, onValueChange, phone]
}
