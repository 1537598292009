import { useState, useEffect } from 'react'

export const usePaymentSelect = (onAnswered, setNextEnabled, question) => {
  const paymentOptions = question.options.map(({ text, id, isRecommendedPlan, _monthlyInterest, value }) => {
    return { text, id, isRecommendedPlan, _monthlyInterest, value }
  })

  let initialState = []
  initialState = paymentOptions.filter(item => question.value === item.value)

  if (initialState.length === 0) {
    initialState = paymentOptions.filter(item => item.isRecommendedPlan)
  }

  useEffect(() => {
    if (question.value || initialState.length) {
      setNextEnabled(true)
      onSelectOption(initialState[0])
      onAnswered && onAnswered(initialState[0].value)
    }
  }, [])

  const [selectedOption, setSelectedOption] = useState(question.value || {})

  const onSelectOption = option => {
    setNextEnabled(true)
    setSelectedOption(option)
    onAnswered(option.value)
  }

  return [selectedOption, onSelectOption, paymentOptions]
}
