import React from 'react'
import { View, FieldsContainer, OptionsList, Option, OptionText, StyledLabel, Column, Row, Space } from './style'
import { Selector } from '../selector'
import { ErrorMessage } from '../error-message'
import { FormField } from '../text-field'
import { useBankPicker } from './hooks'
import { useScreenMeasure } from '../../screens/register/hooks'

export const BankData = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [
    onValueChangeSelect,
    _BANKS,
    OPTIONS_ACCOUNT,
    selectedBank,
    onFormChange,
    value,
    onDismiss,
    selectedOption,
    onSelectOption,
    agencyError,
    accountError
  ] = useBankPicker(onAnswered, setNextEnabled, question)
  const [isMobile] = useScreenMeasure()
  return (
    <View>
      <Selector
        getOptionValue={option => option.code}
        placeholder="Selecione o seu banco"
        visuals={visuals}
        options={_BANKS}
        value={selectedBank}
        onValueChange={onValueChangeSelect}
      />
      <FieldsContainer isMobile={isMobile}>
        <Column isMobile={isMobile}>
          <Row>
            <Column>
              <StyledLabel>Agência*</StyledLabel>
              <FormField
                value={value['agency']}
                visuals={visuals}
                spacing={10}
                maxlength={4}
                size={80}
                type="number"
                placeholder="Ex. 0000"
                onValueChange={v => onFormChange(v, 'agency')}
              />
            </Column>
            <Column>
              <StyledLabel>Dígito</StyledLabel>
              <FormField
                value={value['agencyDigit']}
                visuals={visuals}
                maxlength={1}
                spacing={10}
                size={20}
                type="number"
                placeholder="0"
                onValueChange={v => onFormChange(v, 'agencyDigit')}
              />
            </Column>
          </Row>
        </Column>
        <Space isMobile={isMobile} />
        <Column isMobile={isMobile}>
          <Row>
            <Column>
              <StyledLabel>Conta*</StyledLabel>
              <FormField
                value={value['account']}
                visuals={visuals}
                spacing={10}
                size={120}
                type="number"
                placeholder="Ex. 0000000"
                onValueChange={v => onFormChange(v, 'account')}
              />
            </Column>

            <Column>
              <StyledLabel>Dígito*</StyledLabel>
              <FormField
                value={value['accountDigit']}
                visuals={visuals}
                spacing={40}
                size={20}
                maxlength={1}
                type="number"
                placeholder="0"
                onValueChange={v => onFormChange(v, 'accountDigit')}
              />
            </Column>
          </Row>
        </Column>
      </FieldsContainer>
      {agencyError && <ErrorMessage errorMessage={agencyError} onDismiss={onDismiss} />}
      {accountError && <ErrorMessage errorMessage={accountError} onDismiss={onDismiss} />}

      <OptionsList>
        {OPTIONS_ACCOUNT.map(option => (
          <Option
            key={option.id}
            visuals={visuals}
            isSelected={selectedOption !== undefined && option.id === selectedOption.id}
            onClick={() => onSelectOption(option)}
          >
            <OptionText visuals={visuals}>{option.value}</OptionText>
          </Option>
        ))}
      </OptionsList>
    </View>
  )
}
