import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'

import TextField from '@material-ui/core/TextField'

import firebase from '../../../services/firebase'

import { showToast } from '../../../components/toast'
import { LoadingLogin } from '../../../components/loading'
import { WrapperForm, Button, TextError, FormField } from '../style'

const RecoverPassword = props => {
  const { values, errors, handleChange, handleSubmit, isValid, isSubmitting, visuals } = props

  return (
    <WrapperForm onSubmit={handleSubmit}>
      {isSubmitting && <LoadingLogin color={visuals.loginButton} />}
      <p>Insira o seu endereço de email para recuperar a sua senha.</p>
      <FormField>
        <TextField value={values.email} label="Email" onChange={handleChange('email')} margin="normal" />
        {errors.email && <TextError>{errors.email}</TextError>}
      </FormField>
      <Button visuals={visuals} disabled={!isValid || isSubmitting} type={'submit'}>
        Redefinir senha
      </Button>
    </WrapperForm>
  )
}

export default withFormik({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Preencha o campo Email')
  }),
  handleSubmit: async ({ email }, { setSubmitting }) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        showToast('Email enviado 😉')
        setSubmitting(false)
      })
      .catch(({ code }) => {
        if (code === 'auth/user-not-found') {
          setSubmitting(false)
          showToast('Usuário não encontrado')
        }
      })
  }
})(RecoverPassword)
