import styled, { css } from 'styled-components'

export const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background: ${({ visuals, isCreditAnalysisScreen }) =>
    visuals && (isCreditAnalysisScreen ? visuals.creditApprovedBgImg : visuals.background)};
  background-size: cover;
  background-position: ${({ isMobile }) => (isMobile ? 'top left' : 'center')};
`
export const List = styled.div`
  width: calc(100% - 280px);
  height: 100vh;
  left: 215px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 100vw;
      padding: 20px;
      height: calc(100% - 64px);
      left: 0;
      overflow-x: auto;
    }
  `};
`
