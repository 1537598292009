import styled, { css } from 'styled-components'

export const Text = styled.p`
  text-align: left;
  margin: 0.8em auto;
  color: #fff;
  font-size: ${({ isMobile }) => (isMobile ? '1em' : '1.5em')};
`
export const Title = styled.h1`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ isMobile }) => (isMobile ? '1.3em' : '2em')};
  color: #fff;s
`

export const Image = styled.img`
  width: 40px;
`

export const BodyContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `};
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: 80%;
      max-width: 1200px;
    }
  `};
`

export const LinkRedirect = styled.a`
  text-decoration: none;
`
