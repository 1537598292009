import styled, { keyframes } from 'styled-components'
import { Button } from '../../../globalStyles'

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  -webkit-animation-name: ${opacity}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${opacity};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

const scale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

export const Container = styled.div`
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  height: ${({ isMobile }) => (isMobile ? '100%' : '90%')};
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? '5px' : '20px')};
  justify-content: center;
  -webkit-animation-name: ${scale}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${scale};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

export const Header = styled.div`
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Body = styled.div`
  height: 70%;
  overflow-y: auto;
  border: 2px solid #ff531b;
  border-radius: 10px;
  display: flex;
  justify-content: left;
  padding: 20px;
`
export const Footer = styled.div`
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.p`
  color: black;
  font-size: ${({ isMobile }) => (isMobile ? '1.6rem' : '2rem')};
  text-align: center;
`

export const Description = styled.div`
  text-align: justify;
`

export const Text = styled.p`
  color: ${({ color }) => (color ? color : 'color: black;')};
  font-size: ${({ size }) => (size ? size : '16px')};
`

export const SpanBold = styled.span`
  font-weight: 600;
`

export const GotItButton = styled(Button)`
  width: 200px;
  height: 58px;
  color: ${({ visuals }) => visuals.questionColor};
  background: #ff531b;
  border-radius: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 15px;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  margin: 10px 0 0 0;
  cursor: pointer;
`

export const CloseModal = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
`

export const ContainerPortion = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`

export const Number = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid #ff492f;
  color: #ff492f;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextNumberPortions = styled.p`
  margin-left: 15px;
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const LinkRegulation = styled.div`
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
`
