import React from 'react'
const styleIcon = {
  width: '118px',
  height: '125px',
  fill: '#ffffff'
}
export const ProofAddress = () => (
  <svg style={styleIcon}>
    <path
      className="st0"
      d="M13.8,15.3h11.6c0.8,0,1.5-0.6,1.5-1.5c0-0.8-0.6-1.5-1.5-1.5H13.8c-0.8,0-1.5,0.6-1.5,1.5
	C12.4,14.6,13,15.3,13.8,15.3z"
    />
    <path
      className="st0"
      d="M12,82.9c0,0.8,0.6,1.5,1.5,1.5h50.3c0.8,0,1.5-0.6,1.5-1.5s-0.6-1.5-1.5-1.5H13.5C12.7,81.4,12,82.1,12,82.9z"
    />
    <path
      className="st0"
      d="M73.7,28.8V8.3c0-2.9-2.4-5.3-5.3-5.3H9C6,3,3.6,5.4,3.6,8.3v82.4C3.6,93.6,6,96,9,96h59.4
	c2.9,0,5.3-2.4,5.3-5.3V80.2C87.2,79.5,98,68.2,98,54.5S87.2,29.5,73.7,28.8z M70.8,90.7c0,1.3-1.1,2.4-2.4,2.4H9
	c-1.3,0-2.4-1.1-2.4-2.4V8.3C6.5,7,7.6,5.9,9,5.9h59.4c1.3,0,2.4,1.1,2.4,2.4v20.5C66.1,29,61.7,30.6,58,33H13.5
	c-0.8,0-1.5,0.6-1.5,1.5c0,0.8,0.6,1.5,1.5,1.5h40.9c-1.1,1.1-2.2,2.3-3.1,3.5H13.5c-0.8,0-1.5,0.6-1.5,1.5s0.6,1.5,1.5,1.5h36
	c-1.9,3.6-3,7.7-3,12.1c0,0.7,0,1.4,0.1,2.1h-33c-0.8,0-1.5,0.6-1.5,1.5c0,0.8,0.6,1.5,1.5,1.5h33.4c0.2,1.2,0.6,2.4,1,3.5H13.5
	c-0.8,0-1.5,0.6-1.5,1.5c0,0.8,0.6,1.5,1.5,1.5h35.7c4,8.1,12.1,13.7,21.6,14.3V90.7z M73.7,77.6c-0.5,0-1,0.1-1.5,0.1s-1,0-1.5-0.1
	c-8-0.5-14.9-5.1-18.7-11.7C51.5,65,51,64,50.7,63c-0.5-1.1-0.8-2.3-1.1-3.5c-0.2-0.9-0.4-1.9-0.5-2.9C49,55.9,49,55.2,49,54.5
	c0-4.4,1.3-8.6,3.4-12.1c0.6-1,1.3-2,2.1-2.9c1.1-1.3,2.4-2.5,3.8-3.5c1.5-1.2,3.3-2.2,5.1-2.9c2.3-0.9,4.7-1.5,7.3-1.7
	c0.5,0,1-0.1,1.5-0.1s1,0,1.5,0.1c12.1,0.7,21.7,10.8,21.7,23.1S85.8,76.9,73.7,77.6z"
    />
    <path
      className="st0"
      d="M87.9,53.2H73.7h-0.2V38.8c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v14.4h-0.2H56.6
	c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h14.2h0.2v14.4c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V55.8h0.2h14.2
	c0.7,0,1.3-0.6,1.3-1.3C89.2,53.8,88.6,53.2,87.9,53.2z"
    />
  </svg>
)
