import React from 'react'
import { View } from './style'
import { TextField } from '../text-field'
import { ErrorMessage } from '../error-message'
import { useFullNameValidator } from './hooks'

export const FullName = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [showError, onDismiss, onValueChange, value] = useFullNameValidator(onAnswered, setNextEnabled, question)
  return (
    <View>
      <TextField visuals={visuals} placeholder={'Responder aqui...'} onValueChange={onValueChange} value={value} />
      {showError && <ErrorMessage errorMessage="Nome Inválido" onDismiss={onDismiss} />}
    </View>
  )
}
