import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const OptionsList = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const Option = styled(Button)`
  border: ${({ visuals }) => `1px solid ${visuals.questionColor}`};
  background: ${({ isSelected }) => (isSelected ? 'rgba(204, 204, 204, 0.8)' : 'transparent')};
  width: ${({ isMobile }) => (isMobile ? '100px' : '130px')};
  margin: ${({ isMobile }) => (isMobile ? '2px' : '10px')};
`
export const OptionText = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  ${({ bold }) => bold && 'font-weight: bold'};
  font-size: ${({ size }) => (size ? size : '16px')};
  line-height: 22px;
  color: ${({ visuals }) => visuals.questionColor};
`

export const OptionSmallText = styled.p`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 3.5px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${({ visuals }) => visuals.questionColor};
`
