import React from 'react'

export const Question = ({ fill }) => (
  <svg width="10" height="16" viewBox="0 0 10 16">
    <path
      d="M0.408421 3.45083C1.49398 1.84266 2.87668 0.940918 5.01144 0.940918C7.2816 0.940918 9.70476 2.76783 9.70473 5.17621C9.70473 7.16516 8.40884 7.93617 7.42897 8.51915C6.83331 8.87355 6.35442 9.15846 6.35442 9.60527V9.76445C6.35442 10.1299 6.06705 10.4262 5.71254 10.4262H3.7743C3.41979 10.4262 3.13241 10.1299 3.13241 9.76445V9.49456C3.13241 7.83051 4.32683 7.14141 5.26499 6.60015C5.28799 6.58688 5.31084 6.5737 5.33351 6.5606C6.14592 6.09102 6.64383 5.77166 6.64383 5.14982C6.64383 4.32725 5.62613 3.78132 4.80334 3.78132C3.75688 3.78132 3.25993 4.27952 2.58999 5.14481C2.3728 5.42534 1.97807 5.47605 1.70064 5.25918L0.546908 4.35728C0.272208 4.14254 0.211497 3.74255 0.408421 3.45083ZM2.88984 13.1486C2.88984 12.0954 3.72095 11.2386 4.7425 11.2386C5.76405 11.2386 6.59515 12.0954 6.59515 13.1486C6.59515 14.2018 5.76405 15.0586 4.7425 15.0586C3.72095 15.0586 2.88984 14.2018 2.88984 13.1486Z"
      fill={fill}
    />
  </svg>
)
