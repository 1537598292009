import styled, { css } from 'styled-components'

export const OptionsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const Option = styled.div`
  margin: 5px;
  border: ${({ visuals }) => `1px solid ${visuals.questionColor}`};
  background: ${({ isSelected }) => (isSelected ? 'rgba(204, 204, 204, 0.8)' : 'transparent')};
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 320px;
  ${({ marginLeft, isMobile }) => (isMobile ? 'margin-top: 10px; margin-left: 0' : marginLeft && 'margin-left: 15px')};
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `};
`
export const OptionText = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  ${({ bold }) => bold && 'font-weight: bold'};
  font-size: ${({ size }) => (size ? size : '16px')};
  line-height: ${({ height }) => (height ? height : '22px')};
  color: ${({ color }) => (color ? color : 'white')};
`

export const CourseName = styled.span`
  font-weight: bold;
  color: white;
  font-size: 16px;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

export const Column = styled.div`
  display: flex;
  min-width: 30%;
  flex-direction: column;
`

export const Link = styled.div`
  margin-left: 5px;
  margin-top: 10px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`

export const View = styled.div`
  padding: 12px;
`

export const OptionsAndDiscountBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const RowRight = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
    }
  `};
`
