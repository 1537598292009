import React from 'react'
import { useScreenMeasure } from '../../screens/register/hooks'
import { NextButton } from '../../components/next-button'

import { Title, TitleContainer, Hint, Container } from './style'

export const CreditPreApproved = props => {
  const [isMobile] = useScreenMeasure()
  const { title, screenText, goToNextScreen } = props.screen.nextStep

  return (
    <Container>
      <TitleContainer>
        <Title isMobile={isMobile} visuals={props.visuals}>
          {title}
        </Title>
      </TitleContainer>
      <Hint visuals={props.visuals}>{screenText}</Hint>

      <NextButton
        isMobile={isMobile}
        enabled={true}
        visuals={props.visuals}
        title="OK"
        onClick={() => props.goToScreen(goToNextScreen)}
      />
    </Container>
  )
}
