import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { HelmetWrapper } from '../../components/helmet'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { Loading } from '../../components/loading'
import { useScreenMeasure } from '../register/hooks'
import { Title, Text, Button, BodyContainer, Wrapper } from './style'
import { useIntroduction, getRespectiveRedirectURL, getPartnerRedirectURL } from './hooks'

export const IntroductionScreen = props => {
  const [isLoading, partner, visuals, slugPartner, isSanarMed, sanarmedText] = useIntroduction(props)
  const [infoToken, setInfoToken] = useState()

  const [isMobile] = useScreenMeasure()

  useEffect(() => {
    const partnerSlug = props.match.params.school
    const urlSearchParams = new URLSearchParams(props.location.search)
    setInfoToken(urlSearchParams.get('info'))

    if (partnerSlug === 'sanarmed') {
      window.location.href = getRespectiveRedirectURL(urlSearchParams.get('info'))
    }
    if (partnerSlug === 'impacta') {
      window.location.href = getPartnerRedirectURL('impacta')
    }
    if (partnerSlug === 'gama') {
      window.location.href = getPartnerRedirectURL('gama')
    }
  }, [])

  return (
    <>
      <HelmetWrapper partner={slugPartner} />
      {isLoading ? (
        <Loading visuals={visuals} />
      ) : isSanarMed ? (
        <ScreenImageBackground visuals={visuals} background={sanarmedText && sanarmedText.background}>
          <BodyContainer>
            <Wrapper>
              <Title isMobile={isMobile}>{sanarmedText && sanarmedText.title1}</Title>
              <Title isMobile={isMobile}>{sanarmedText && sanarmedText.title}</Title>
              {_.isArray(sanarmedText && sanarmedText.text) && sanarmedText && sanarmedText.text.length > 0 ? (
                sanarmedText &&
                sanarmedText.text.map((item, index) => (
                  <Text key={index} isMobile={isMobile}>
                    {item}
                  </Text>
                ))
              ) : (
                <Text isMobile={isMobile}>{sanarmedText && sanarmedText.text}</Text>
              )}

              <Button onClick={() => (window.location.href = getRespectiveRedirectURL(infoToken))}>INICIAR</Button>
            </Wrapper>
          </BodyContainer>
        </ScreenImageBackground>
      ) : (
        <ScreenImageBackground visuals={visuals} background={partner && partner.background}>
          <BodyContainer>
            <Wrapper>
              <Title isMobile={isMobile}>{partner && partner.title}</Title>
              {_.isArray(partner && partner.text) && partner && partner.text.length > 0 ? (
                partner &&
                partner.text.map((item, index) => (
                  <Text key={index} isMobile={isMobile}>
                    {item}
                  </Text>
                ))
              ) : (
                <Text isMobile={isMobile}>{partner && partner.text}</Text>
              )}

              <Button onClick={() => props.history.push(`/login/${props.match.params.school}`)}>INICIAR</Button>
            </Wrapper>
          </BodyContainer>
        </ScreenImageBackground>
      )}
    </>
  )
}
