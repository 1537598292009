import React from 'react'
import { HelmetWrapper } from '../../components/helmet'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { useScreenMeasure } from '../register/hooks'
import { Title, Text, Wrapper, BodyContainer } from './style'
import { useSigned } from './hooks'

export const BankSlipPaid = props => {
  const [isMobile] = useScreenMeasure()
  const [schoolSelect, visuals, slugPartner] = useSigned(props)

  return (
    <ScreenImageBackground visuals={visuals} background={schoolSelect.background}>
      <HelmetWrapper partner={slugPartner} />
      <BodyContainer>
        <Wrapper>
          <Title isMobile={isMobile}>
            Parabéns!{' '}
            <span role="img" aria-labelledby="Raising Hands">
              🙌
            </span>
          </Title>
          {schoolSelect &&
            schoolSelect.textArr &&
            schoolSelect.textArr.map((text, idx) => (
              <Text isMobile={isMobile} key={idx}>
                {text}
              </Text>
            ))}
        </Wrapper>
      </BodyContainer>
    </ScreenImageBackground>
  )
}
