import { useState, useEffect } from 'react'
import { getVisuals, setLocalstorage } from '../../utils'
import { checkDecryptInfo } from '../../services/api'
import bg_approved_sanarup from '../../assets/images/bg-approved-sanarup.png'
import bg_approved_sanarmed from '../../assets/images/bg-approved-sanarmed.png'
import bg_intro_gama from '../../assets/images/bg-intro-gama.png'
import bg_impacta from '../../assets/images/bg-impacta.png'
import bg_digitalHouse from '../../assets/images/digitalhouse-bg.svg'

export const useIntroduction = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [visuals, setVisuals] = useState(null)
  const [slugPartner, setSlugPartner] = useState()
  const [partner, setPartner] = useState(null)
  const [isSanarMed, setIsSanarMed] = useState(false)
  const [sanarmedText, setSanarMedText] = useState(false)

  const sanarmed = [
    {
      title: `Você será um médico brilhante! E nós vamos te ajudar!`,
      text: [
        'Faça sua inscrição com a Provi e impulsione os seus estudos sem desequilibrar sua vida financeira.',
        'A Provi é a fintech que construiu junto com a gente o melhor produto para seu sucesso na área médica!'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo R1 2020',
      title1: 'Extensivo R1 2020',
      title: 'Deseja se preparar de forma completa para qualquer prova do país em 12 meses? Matricule-se no Extensivo R1 2020.',
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 12x sem juros ou pague 49 reais nos primeiros 12 meses. Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Avançado R1 2020',
      title1: 'Avançado R1 2020',
      title:
        'Deseja se preparar para qualquer prova de residência médica do país? Se prepare através do curso mais completo e barato do Brasil!',
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 12x sem juros ou pague 49 reais nos primeiros 12 meses. Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo Programado',
      title1: 'Extensivo R1 Programado',
      title: `Deseja se preparar para qualquer prova de residência médica do país? Se prepare através do curso mais completo e barato do Brasil!`,
      text: [
        'Faça agora o seu cadastro com a Provi, fintech parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie os dois anos do curso por boleto parcelado em até 24x. Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo Programado R1',
      title1: 'Extensivo R1 Programado',
      title: `É aluno meio-anista e pretende se preparar de forma completa para as provas R1 de 2021? Conheça o curso que vai te deixar pronto para ser aprovado!`,
      text: [
        'Faça agora o seu cadastro com a Provi, fintech parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie os dois anos do curso por boleto parcelado em até 24x. Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo Revalida 2020',
      title1: 'Extensivo Revalida 2020',
      title: `Quer revalidar seu diploma e precisa de uma preparação completa para as provas do Revalida? Garanta vaga no Extensivo Revalida 2020`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Intensivo Revalida 2020',
      title1: 'Intensivo Revalida 2020',
      title: `Deseja revalidar seu diploma de médico no Brasil? Prepare-se com um curso focado nos principais temas para garantir sua revalidação!`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Intensivo R1 2020',
      title1: 'Intensivo R1 2020',
      title: `Prepare-se para as provas de R1 em menos de 6 meses com uma metodologia objetiva e completa.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'R3 Clínica Médica Intensivo',
      title1: 'R3 Clínica Médica Intensivo',
      title: `Prepare-se para o R3 de Clínica Médica e aproveite para consolidar conhecimentos da sua residência.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'R3 Cirurgia Intensivo',
      title1: 'R3 Cirurgia Intensivo',
      title: `Prepare-se para o R3 de Cirurgia e aproveite para consolidar conhecimentos da sua Residência.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Intensivo Revalida Digital',
      title1: 'Intensivo Revalida Digital',
      title: `Precisa se preparar para a prova do Revalida em um curto período de tempo e apenas com materiais digitais? Matricule-se no Intensivo Revalida Digital e garanta todo o suporte para a sua aprovação.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },

    // New courses
    {
      name: 'Intensivo R1 2020 + 3 Yellowbooks (Promocao)',
      title1: 'Intensivo R1 2020 + 3 Yellowbooks (Promocao)',
      title: `Prepare-se para as provas de R1 em menos de 6 meses com uma metodologia objetiva e completa. Edição especial com 3 Yellowbooks bônus e 6 meses de acesso premium ao aplicativo do Yellowbook.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Intensivo Revalida 2020 + 3 Yellowbooks (Promocao)',
      title1: 'Intensivo Revalida 2020 + 3 Yellowbooks (Promocao)',
      title: `Precisa se preparar para a prova do Revalida em um curto período de tempo? Matricule-se no Intensivo Revalida e garanta todo o suporte para a sua aprovação. Edição Especial com 3 Yellowbooks bônus e 6 meses de acesso premium ao aplicativo Yellowbook e acesso ao curso até as provas do INEP.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Reta Final - Intensivo R1 2020',
      title1: 'Reta Final - Intensivo R1 2020',
      title: `Prepare-se para as provas de R1 em menos de 6 meses com uma metodologia objetiva e completa.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo Programado R1 - Do internato à residência (3 anos)',
      title1: 'Extensivo Programado R1 - Do internato à residência (3 anos)',
      title: `Está no 4o ano e sente que precisa de um curso que te prepare para os desafios do internato, e ainda garanta a sua preparação para as provas de residência? Matricule-se no Programado: Do internato à residência!`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo Programado R1 2021 (2 anos)',
      title1: 'Extensivo Programado R1 2021 (2 anos)',
      title: `Está no 5o ano e precisa se preparar com calma para as provas de residência de 2022? Matricule-se no Programado R1 2021 de 2 anos e tenha uma preparação completa!`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo R1 2021',
      title1: 'Extensivo R1 2021',
      title: `Deseja se preparar de forma completa para qualquer prova do país em 12 meses? Matricule-se no Extensivo R1 2021.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'R+ Cirurgia 2021',
      title1: 'R+ Cirurgia 2021',
      title: `O R+ Cirurgia 2021 foi desenhado do zero para garantir a atualização do especialista e preparar para a prova de R+.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'R+ Pediatria 2021',
      title1: 'R+ Pediatria 2021',
      title: `O R+ Pediatria 2021 foi desenhado do zero para garantir a atualização do especialista e preparar para a prova de R+.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'R+ Ginecologia e Obstetrícia 2021',
      title1: 'R+ Ginecologia e Obstetrícia 2021',
      title: `O R+ Ginecologia e Obstetrícia 2021 foi desenhado do zero para garantir a atualização do especialista e preparar para a prova de R+.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'R+ Clínica Médica 2021',
      title1: 'R+ Clínica Médica 2021',
      title: `O R+ Clínica Médica 2021 foi desenhado do zero para garantir a atualização do especialista e preparar para a prova de R+.`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Programado R1 2021 (2 anos)',
      title1: 'Programado R1 2021 (2 anos)',
      title: `Está no 5o ano e precisa se preparar com calma para as provas de residência de 2022? Matricule-se no Programado R1 2021 de 2 anos e tenha uma preparação completa!`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'R1 Prime - 2021 (3 anos)',
      title1: 'R1 Prime - 2021 (3 anos)',
      title: `Está no 4o ano e sente que precisa de um curso que te prepare para os desafios do internato, e ainda garanta a sua preparação para as provas de residência? Matricule-se no Programado: Do internato à residência!`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Extensivo Revalida 2021',
      title1: 'Extensivo Revalida 2021',
      title: `Quer revalidar seu diploma e precisa de uma preparação completa para as provas do Revalida? Garanta vaga no Extensivo Revalida 2021`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    },
    {
      name: 'Intensivo Revalida 2021.1',
      title1: 'Intensivo Revalida 2021.1',
      title: `Precisa se preparar para a prova do Revalida em um curto período de tempo? Matricule-se no Intensivo Revalida 2021.1 e garanta todo o suporte para a sua aprovação`,
      text: [
        'Faça agora o seu cadastro com a Provi, parceira da Sanar, e impulsione seus estudos sem desequilibrar sua vida financeira!',
        'A Sanar Residência Médica permite que você financie o curso por boleto parcelado em até 24x! Verifique condições.'
      ],
      background: `url(${bg_approved_sanarmed}) no-repeat center / cover`
    }
  ]

  const partners = {
    sanar: {
      title: 'Está pronto para dar um UP na sua carreira?',
      text: [
        `A Sanar, em parceria com a Fintech Provi, construiu a mesada UP especialmente para você que se formará em até 18 meses ou que está na residência médica!`,
        `Fique ligado! Você vai precisar de alguns documentos para finalizar o seu cadastro: comprovante de matrícula, histórico da faculdade ou residência, documento de identificação com foto e comprovante endereço!`,
        `Agora sim você está preparado para começar o cadastro UP!`,
        `Vamos lá?`
      ],
      background: `url(${bg_approved_sanarup}) no-repeat center / cover`
    },
    sanarmed: sanarmed,
    gama: {
      title: 'Para financiar seu curso com a Provi você deve responder algumas perguntas para validarmos os seus dados.',
      text: 'Prometo que é rápido!',
      background: `url(${bg_intro_gama}) no-repeat center / cover`
    },
    impacta: {
      title: 'Estamos aqui para te ajudar a acelerar sua carreira!',
      text: [
        'Faça o financiamento do seu curso da Impacta com a Provi e impulsione sua vida profissional sem desequilibrar sua vida financeira.',
        'A Provi é a fintech parceira da Impacta, que vai te ajudar a desenvolver sua carreira sem pesar no seu bolso.'
      ],
      background: `url(${bg_impacta}) no-repeat center / cover`
    },
    digitalhouse: {
      title: 'SEU FUTURO É DIGITAL!',
      text: [
        'Venha estudar no mais novo hub de educação e tecnologia de SP.',
        'A Provi é a fintech parceira da Digital House, que vai te ajudar\na desenvolver sua carreira sem pesar no seu bolso.'
      ],
      background: `url(${bg_digitalHouse}) no-repeat center / cover`
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const cpf = urlParams.get('cpf')
    const info = urlParams.get('info')

    const slug = props.match.params.school

    if (slug === 'gama') {
      window.location.href = getPartnerRedirectURL('gama')
      return
    }
    if (slug === 'impacta') {
      window.location.href = getPartnerRedirectURL('impacta')
      return
    }
    if (slug === 'sanarmed') {
      window.location.href = getPartnerRedirectURL('sanarmed')
      return
    }

    localStorage.removeItem('cpf')
    setLocalstorage(cpf, 'cpf')

    async function fetchData() {
      setIsLoading(true)
      try {
        const data = await checkDecryptInfo(info)
        setSanarMedText(partners.sanarmed.filter(item => item.name === data.course.name)[0])
      } catch (error) {
        setSanarMedText(partners[slug][0])
      }
      setIsLoading(false)
    }

    if (slug === 'sanarmed') {
      setIsSanarMed(true)
      fetchData()
    }

    setSlugPartner(slug)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: window.location.pathname,
      pageTitle: `Intro - ${slug}`
    })
    setPartner(partners[slug])

    setVisuals(getVisuals(slug))
  }, [partners, props.match.params.school])

  return [isLoading, partner, visuals, slugPartner, isSanarMed, sanarmedText]
}

export const getRespectiveRedirectURL = info => {
  const options = {
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiSW50ZW5zaXZvIFJldmFsaWRhIDIwMjBcIixcImNvdXJzZV9wbGFjZVwiOlwiT25saW5lXCIsXCJwcmljZV9pbl9jZW50c1wiOjE2NDkwMCxcInN0YXJ0X2RhdGVcIjpcIjIwMjAtMDUtMTFUMDI6MDA6MDAuMDAwWlwiLFwiZW5kX2RhdGVcIjpcIjIwMjAtMTEtMTFUMDI6MDA6MDAuMDAwWlwiLFwicGVyaW9kXCI6XCI2IG1lc2VzXCJ9XSxcImNoZWNrb3V0X3ByaWNlX2luX2NlbnRzXCI6MTY0OTAwLFwibm90aWZpY2F0aW9uX3VybFwiOlwiaHR0cDovL2UtaGFuZGxlci5lZGl0b3Jhc2FuYXIuY29tLmJyL2V2ZW50cy9yZXNtZWQvUFJPVklcIixcIm9ubHlDb25zdGFudEluc3RhbGxtZW50c1wiOnRydWV9Ig.KccEp4_yzd2pzvBMvO0nFURt7GZhK8z2h2fJNNnOfdQ':
      'https://login.provi.com.br/sanarmed/campanha/intensivo-revalida-2020',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUjMgQ2zDrW5pY2EgTcOpZGljYSBJbnRlbnNpdm9cIixcImNvdXJzZV9wbGFjZVwiOlwiT25saW5lXCIsXCJwcmljZV9pbl9jZW50c1wiOjE2OTkwMCxcInN0YXJ0X2RhdGVcIjpcIjIwMjAtMDctMTVUMDY6MDA6MDAuMDAwWlwiLFwiZW5kX2RhdGVcIjpcIjIwMjEtMDQtMzBUMDY6MDA6MDAuMDAwWlwiLFwicGVyaW9kXCI6XCI2IG1lc2VzXCJ9XSxcImNoZWNrb3V0X3ByaWNlX2luX2NlbnRzXCI6MTY5OTAwLFwibm90aWZpY2F0aW9uX3VybFwiOlwiaHR0cDovL2UtaGFuZGxlci5lZGl0b3Jhc2FuYXIuY29tLmJyL2V2ZW50cy9yZXNtZWQvUFJPVklcIixcIm9ubHlDb25zdGFudEluc3RhbGxtZW50c1wiOnRydWV9Ig.KsEqCb29JGBAaahoUQuHaGZKKBeTAN_XdnWEhwX5ioA':
      'https://login.provi.com.br/sanarmed/campanha/r3-clinica-medica-intensivo',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUjMgQ2lydXJnaWEgSW50ZW5zaXZvXCIsXCJjb3Vyc2VfcGxhY2VcIjpcIk9ubGluZVwiLFwicHJpY2VfaW5fY2VudHNcIjoxNjk5MDAsXCJzdGFydF9kYXRlXCI6XCIyMDIwLTA3LTE1VDA2OjAwOjAwLjAwMFpcIixcImVuZF9kYXRlXCI6XCIyMDIxLTA0LTMwVDA2OjAwOjAwLjAwMFpcIixcInBlcmlvZFwiOlwiNiBtZXNlc1wifV0sXCJjaGVja291dF9wcmljZV9pbl9jZW50c1wiOjE2OTkwMCxcIm5vdGlmaWNhdGlvbl91cmxcIjpcImh0dHA6Ly9lLWhhbmRsZXIuZWRpdG9yYXNhbmFyLmNvbS5ici9ldmVudHMvcmVzbWVkL1BST1ZJXCIsXCJvbmx5Q29uc3RhbnRJbnN0YWxsbWVudHNcIjp0cnVlfSI.3EpyDd6WRBZ0kdj1cpWdhN1ump1aAaUxebK_-Vgpmew':
      'https://login.provi.com.br/sanarmed/campanha/r3-cirurgia-intensivo',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiRXh0ZW5zaXZvIFIxIDIwMjFcIixcImNvdXJzZV9wbGFjZVwiOlwiT25saW5lXCIsXCJwcmljZV9pbl9jZW50c1wiOjE5OTkwMCxcInN0YXJ0X2RhdGVcIjpcIjIwMjEtMDEtMjdUMDY6MDA6MDAuMDAwWlwiLFwiZW5kX2RhdGVcIjpcIjIwMjItMDEtMzFUMDY6MDA6MDAuMDAwWlwiLFwicGVyaW9kXCI6XCIxMiBtZXNlc1wifV0sXCJjaGVja291dF9wcmljZV9pbl9jZW50c1wiOjE5OTkwMCxcIm5vdGlmaWNhdGlvbl91cmxcIjpcImh0dHA6Ly9lLWhhbmRsZXIuZWRpdG9yYXNhbmFyLmNvbS5ici9ldmVudHMvcmVzbWVkL1BST1ZJXCIsXCJvbmx5Q29uc3RhbnRJbnN0YWxsbWVudHNcIjp0cnVlfSI.vJ4aPAaZTeTZQjiGB-2-Lteo3r9hMNAIaT1cVX8_e9c':
      'https://login.provi.com.br/sanarmed/campanha/extensivo-r1-2021',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUHJvZ3JhbWFkbyBSMSAyMDIxICgyIGFub3MpXCIsXCJjb3Vyc2VfcGxhY2VcIjpcIlJlbW90b1wiLFwicHJpY2VfaW5fY2VudHNcIjoyOTk5MDAsXCJzdGFydF9kYXRlXCI6XCIyMDIxLTAxLTI3VDA2OjAwOjAwLjAwMFpcIixcImVuZF9kYXRlXCI6XCIyMDIzLTAxLTMxVDA2OjAwOjAwLjAwMFpcIixcInBlcmlvZFwiOlwiMjQgbWVzZXNcIn1dLFwiY2hlY2tvdXRfcHJpY2VfaW5fY2VudHNcIjoyOTk5MDAsXCJub3RpZmljYXRpb25fdXJsXCI6XCJodHRwOi8vZS1oYW5kbGVyLmVkaXRvcmFzYW5hci5jb20uYnIvZXZlbnRzL3Jlc21lZC9QUk9WSVwiLFwib25seUNvbnN0YW50SW5zdGFsbG1lbnRzXCI6dHJ1ZX0i.EProzAXz4Duhrt1hlxP1rjL7YCq4yk6l7Ra4uVW-iC4':
      'https://login.provi.com.br/sanarmed/campanha/extensivo-programado-r1-2021-2-anos',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUjEgUHJpbWUgLSAyMDIxICgzIGFub3MpXCIsXCJjb3Vyc2VfcGxhY2VcIjpcIlJlbW90b1wiLFwicHJpY2VfaW5fY2VudHNcIjozNjk5MDAsXCJzdGFydF9kYXRlXCI6XCIyMDIxLTAzLTAzVDA2OjAwOjAwLjAwMFpcIixcImVuZF9kYXRlXCI6XCIyMDI0LTAxLTMxVDA2OjAwOjAwLjAwMFpcIixcInBlcmlvZFwiOlwiMzQgbWVzZXNcIn1dLFwiY2hlY2tvdXRfcHJpY2VfaW5fY2VudHNcIjozNjk5MDAsXCJub3RpZmljYXRpb25fdXJsXCI6XCJodHRwOi8vZS1oYW5kbGVyLmVkaXRvcmFzYW5hci5jb20uYnIvZXZlbnRzL3Jlc21lZC9QUk9WSVwiLFwib25seUNvbnN0YW50SW5zdGFsbG1lbnRzXCI6dHJ1ZX0i.0xJUMdslObuUBmULa9kn775canVObqBtj3XQVGVJJjE':
      'https://login.provi.com.br/sanarmed/campanha/r1-prime-2021-3-anos',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUisgQ2zDrW5pY2EgTcOpZGljYSAyMDIxXCIsXCJjb3Vyc2VfcGxhY2VcIjpcIlJlbW90b1wiLFwicHJpY2VfaW5fY2VudHNcIjo0Njk5MDAsXCJzdGFydF9kYXRlXCI6XCIyMDIxLTAxLTIxVDA2OjAwOjAwLjAwMFpcIixcImVuZF9kYXRlXCI6XCIyMDIyLTAyLTI4VDA2OjAwOjAwLjAwMFpcIixcInBlcmlvZFwiOlwiMTIgbWVzZXNcIn1dLFwiY2hlY2tvdXRfcHJpY2VfaW5fY2VudHNcIjo0Njk5MDAsXCJub3RpZmljYXRpb25fdXJsXCI6XCJodHRwOi8vZS1oYW5kbGVyLmVkaXRvcmFzYW5hci5jb20uYnIvZXZlbnRzL3Jlc21lZC9QUk9WSVwiLFwib25seUNvbnN0YW50SW5zdGFsbG1lbnRzXCI6dHJ1ZX0i.Cjm5uWwDHo2Rak8sR0UDSEUmEcQhKupkpfzzEfeI3wo':
      'https://login.provi.com.br/sanarmed/campanha/r-clinica-medica-2021',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUisgQ2lydXJnaWEgMjAyMVwiLFwiY291cnNlX3BsYWNlXCI6XCJSZW1vdG9cIixcInByaWNlX2luX2NlbnRzXCI6NDY5OTAwLFwic3RhcnRfZGF0ZVwiOlwiMjAyMS0wMS0yMVQwNjowMDowMC4wMDBaXCIsXCJlbmRfZGF0ZVwiOlwiMjAyMi0wMi0yOFQwNjowMDowMC4wMDBaXCIsXCJwZXJpb2RcIjpcIjEyIG1lc2VzXCJ9XSxcImNoZWNrb3V0X3ByaWNlX2luX2NlbnRzXCI6NDY5OTAwLFwibm90aWZpY2F0aW9uX3VybFwiOlwiaHR0cDovL2UtaGFuZGxlci5lZGl0b3Jhc2FuYXIuY29tLmJyL2V2ZW50cy9yZXNtZWQvUFJPVklcIixcIm9ubHlDb25zdGFudEluc3RhbGxtZW50c1wiOnRydWV9Ig.Ks9DsaSJzlHe8-20z3xvyvA7GVpSM6dAw1n7jKcBM2w':
      'https://login.provi.com.br/sanarmed/campanha/r-cirurgia-2021',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUisgUGVkaWF0cmlhIDIwMjFcIixcImNvdXJzZV9wbGFjZVwiOlwiUmVtb3RvXCIsXCJwcmljZV9pbl9jZW50c1wiOjQ2OTkwMCxcInN0YXJ0X2RhdGVcIjpcIjIwMjEtMDEtMjFUMDY6MDA6MDAuMDAwWlwiLFwiZW5kX2RhdGVcIjpcIjIwMjItMDItMjhUMDY6MDA6MDAuMDAwWlwiLFwicGVyaW9kXCI6XCIxMiBtZXNlc1wifV0sXCJjaGVja291dF9wcmljZV9pbl9jZW50c1wiOjQ2OTkwMCxcIm5vdGlmaWNhdGlvbl91cmxcIjpcImh0dHA6Ly9lLWhhbmRsZXIuZWRpdG9yYXNhbmFyLmNvbS5ici9ldmVudHMvcmVzbWVkL1BST1ZJXCIsXCJvbmx5Q29uc3RhbnRJbnN0YWxsbWVudHNcIjp0cnVlfSI.vNYUgBoOkxKq_MVPGieDyi0141qAAlCwNG3YplIa2kA':
      'https://login.provi.com.br/sanarmed/campanha/r-pediatria-2021',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiUisgR2luZWNvbG9naWEgZSBPYnN0ZXRyw61jaWEgMjAyMVwiLFwiY291cnNlX3BsYWNlXCI6XCJSZW1vdG9cIixcInByaWNlX2luX2NlbnRzXCI6NDY5OTAwLFwic3RhcnRfZGF0ZVwiOlwiMjAyMS0wMS0yMVQwNjowMDowMC4wMDBaXCIsXCJlbmRfZGF0ZVwiOlwiMjAyMi0wMi0yOFQwNjowMDowMC4wMDBaXCIsXCJwZXJpb2RcIjpcIjEyIG1lc2VzXCJ9XSxcImNoZWNrb3V0X3ByaWNlX2luX2NlbnRzXCI6NDY5OTAwLFwibm90aWZpY2F0aW9uX3VybFwiOlwiaHR0cDovL2UtaGFuZGxlci5lZGl0b3Jhc2FuYXIuY29tLmJyL2V2ZW50cy9yZXNtZWQvUFJPVklcIixcIm9ubHlDb25zdGFudEluc3RhbGxtZW50c1wiOnRydWV9Ig.NQe-I5n5gPmjAoBs-5CsY_9mTNE8wcSWQrhnlFUka3w':
      'https://login.provi.com.br/sanarmed/campanha/r-ginecologia-e-obstetricia-2021',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiRXh0ZW5zaXZvIFJldmFsaWRhIDIwMjFcIixcImNvdXJzZV9wbGFjZVwiOlwiUmVtb3RvXCIsXCJwcmljZV9pbl9jZW50c1wiOjE5OTkwMCxcInN0YXJ0X2RhdGVcIjpcIjIwMjEtMDEtMjdUMDY6MDA6MDAuMDAwWlwiLFwiZW5kX2RhdGVcIjpcIjIwMjItMDEtMzFUMDY6MDA6MDAuMDAwWlwiLFwicGVyaW9kXCI6XCIxMiBtZXNlc1wifV0sXCJjaGVja291dF9wcmljZV9pbl9jZW50c1wiOjE5OTkwMCxcIm5vdGlmaWNhdGlvbl91cmxcIjpcImh0dHA6Ly9lLWhhbmRsZXIuZWRpdG9yYXNhbmFyLmNvbS5ici9ldmVudHMvcmVzbWVkL1BST1ZJXCIsXCJvbmx5Q29uc3RhbnRJbnN0YWxsbWVudHNcIjp0cnVlfSI.tc0pL2dedkgwtBq53gzC_dEqa9mWGruA_o-9QZg_UwQ':
      'https://login.provi.com.br/sanarmed/campanha/extensivo-revalida-2021',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IntcImNvdXJzZXNcIjpbe1wibmFtZVwiOlwiSW50ZW5zaXZvIFJldmFsaWRhIDIwMjEuMVwiLFwiY291cnNlX3BsYWNlXCI6XCJSZW1vdG9cIixcInByaWNlX2luX2NlbnRzXCI6MTQ5OTAwLFwic3RhcnRfZGF0ZVwiOlwiMjAyMS0wMS0yN1QwNjowMDowMC4wMDBaXCIsXCJlbmRfZGF0ZVwiOlwiMjAyMS0wNy0zMVQwNjowMDowMC4wMDBaXCIsXCJwZXJpb2RcIjpcIjYgbWVzZXNcIn1dLFwiY2hlY2tvdXRfcHJpY2VfaW5fY2VudHNcIjoxNDk5MDAsXCJub3RpZmljYXRpb25fdXJsXCI6XCJodHRwOi8vZS1oYW5kbGVyLmVkaXRvcmFzYW5hci5jb20uYnIvZXZlbnRzL3Jlc21lZC9QUk9WSVwiLFwib25seUNvbnN0YW50SW5zdGFsbG1lbnRzXCI6dHJ1ZX0i.sl7_1nEhoIid4AS4jXD5HWy0RjL0RyeSZiwpNf4wFCc':
      'https://login.provi.com.br/sanarmed/campanha/intensivo-revalida-2021-1'
  }

  return options[info] || 'https://web.provi.com.br/404'
}

export const getPartnerRedirectURL = partnerSlug => {
  return `https://login.provi.com.br/${partnerSlug}`
}
