import React from 'react'
import { UploadBox } from '../upload-box'
import { useFileUpload } from './hooks'
import { View } from './style'

const OPTIONS_DROPZONE = {
  accept: 'image/jpeg, image/png, application/pdf'
}

export const UploadReceipt = ({ visuals, isMobile, setNextEnabled, onAnswered, question }) => {
  const [onDrop, status, progress, numberOfUploads] = useFileUpload(onAnswered, setNextEnabled, question)
  return (
    <View>
      <UploadBox
        question={question}
        type="proof_enrollment"
        text="Comprovante de residência"
        options={OPTIONS_DROPZONE}
        numberOfUploads={numberOfUploads}
        progress={progress}
        status={status}
        isMobile={isMobile}
        onDrop={onDrop}
        visuals={visuals}
      />
    </View>
  )
}
