import React from 'react'
import { View } from './style'
import { Selector } from '../selector'
import { useProfessionalSituationPicker } from './hooks'

export const ProfessionalSituation = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, profissionalSituations, selectedProfessionalSituation] = useProfessionalSituationPicker(
    onAnswered,
    setNextEnabled,
    question
  )
  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={profissionalSituations}
        value={selectedProfessionalSituation}
        onValueChange={onValueChange}
      />
    </View>
  )
}
