import React from 'react'

export const Loading = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="rotate(0 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.9768009768009769s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(40 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.8547008547008548s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(80 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.7326007326007327s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.6105006105006106s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(160 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.48840048840048844s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(200 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.36630036630036633s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.24420024420024422s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(280 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="-0.12210012210012211s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(320 50 50)">
      <rect x="48.5" y="27.5" rx="1.5" ry="6.9" width="3" height="15" fill="#000000">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.0989010989010988s"
          begin="0s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
  </svg>
)
