import { useState, useEffect } from 'react'
import { getVisuals } from '../../utils'
import bg_approved_impacta from '../../assets/images/bg-approved-impacta.png'
import bg_approved_gama from '../../assets/images/bg-approved-gama.png'
import bg_approved_sanarup from '../../assets/images/bg-approved-sanarup.png'
import bg_digitalHouse from '../../assets/images/digitalhouse-bg.svg'

export const useSigned = props => {
  const [schoolSelect, setSchoolSelect] = useState({})
  const [visuals, setVisuals] = useState(null)
  const [slugPartner, setSlugPartner] = useState()

  const defaultTextStrucutre = [
    'Está tudo certo com seu contrato e o seu pagamento já foi confirmado.',
    'A partir de agora você pode baixar o app da Provi e acompanhar todas as suas faturas dentro da nossa plataforma feita para os alunos.',
    'Caso tenha algum problema com seu contrato ou seu primeiro boleto, entre em contato com a gente via email papo@provi.com.br, ou via whatsapp +55 (11) 95771-2414.'
  ]

  const sanar = {
    textArr: [
      'Está tudo certo com a sua Mesada UP!',
      'A partir de agora você pode baixar o app da Provi e acompanhar todas as datas de recebimento da sua mesada na nossa plataforma',
      'Caso tenha algum problema com sua Mesada UP entre em contato com a gente via email papo@provi.com.br, ou via whatsapp +55 (11) 95771-2414.'
    ],
    background: `url(${bg_approved_sanarup}) no-repeat center / cover`
  }

  const impacta = {
    textArr: defaultTextStrucutre,
    background: `url(${bg_approved_impacta}) no-repeat center / cover`
  }

  const gama = {
    textArr: defaultTextStrucutre,
    background: `url(${bg_approved_gama}) no-repeat center / cover`
  }

  const digitalhouse = {
    textArr: defaultTextStrucutre,
    background: `url(${bg_digitalHouse}) no-repeat center / cover`
  }

  const sanarmed = {
    textArr: defaultTextStrucutre,
    background: 'linear-gradient(90deg, #009971 0%, #045390 100%)'
  }

  useEffect(() => {
    const slug = props.match.params.school

    setSlugPartner(slug)
    const DENIED = 'CreditDenied'
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(
      {
        event: 'Pageview',
        pagePath: `${window.location.pathname}/${DENIED}`,
        pageTitle: `${DENIED} - ${slug}`
      },
      {
        event: 'GAEvent',
        eventCategory: 'Screen View',
        eventAction: DENIED,
        eventLabel: `${slug}`
      }
    )

    switch (slug) {
      case 'sanar':
        setSchoolSelect(sanar)
        break
      case 'gama':
        setSchoolSelect(gama)
        break
      case 'impacta':
        setSchoolSelect(impacta)
        break
      case 'sanarmed':
        setSchoolSelect(sanarmed)
        break
      case 'digitalhouse':
        setSchoolSelect(digitalhouse)
        break
      default:
        break
    }

    setVisuals(getVisuals(slug))
  }, [])

  return [schoolSelect, visuals, slugPartner]
}
