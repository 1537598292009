import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  padding-top: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

export const GotItButton = styled(Button)`
  padding: 15px 30px;
  width: auto !important;
  height: auto !important;
  color: ${({ visuals }) => visuals.questionColor};
  background: tranparent;
  border: 1px solid white;
  border-radius: 0;
  font-weight: 600;
  font-size: 15px;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  margin-top: 40px;
  cursor: pointer;
`

export const ImagePointer = styled.img`
  width: ${({ isMobile }) => (isMobile ? '100%' : '400px')};
`
