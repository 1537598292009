import React from 'react'
import { HelmetWrapper } from '../../components/helmet'
import { Loading } from '../../components/loading'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { useScreenMeasure } from '../register/hooks'
import { Title, Text, Wrapper, BodyContainer } from './style'
import { NextButton } from '../../components/next-button'
import { UserCreditRequest } from './hooks'

export const Restart = props => {
  const [isMobile] = useScreenMeasure()
  const [schoolSelect, visuals, slugPartner, restartCreditRequest, isLoading] = UserCreditRequest(props)

  return (
    <ScreenImageBackground visuals={visuals} background={schoolSelect.background}>
      <HelmetWrapper partner={slugPartner} />
      {isLoading && <Loading visuals={visuals} />}
      <BodyContainer>
        <Wrapper>
          <Title isMobile={isMobile}>Você pode iniciar um novo pedido de crédito</Title>
          <Text isMobile={isMobile}>
            Você pode atualizar suas informações nas próximas telas e fazer uma simulação de crédito com a Provi.
          </Text>
          {visuals && (
            <NextButton
              isMobile={isMobile}
              enabled={true}
              visuals={visuals}
              title="Fazer uma nova solicitão"
              onClick={() => restartCreditRequest()}
            />
          )}
        </Wrapper>
      </BodyContainer>
    </ScreenImageBackground>
  )
}
