import { useState, useEffect } from 'react'

export const useCurseSelect = (question, onAnswered, setNextEnabled) => {
  const options = question.installments.map(item => ({
    price: item.VlrParcela,
    installments: item.numberOfInstallments,
    id: item.id,
    monthlyInterest: item.monthlyInterest
  }))
  const initialState = options.filter(item => question.value === item.id)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onSelectOption(initialState[0])
      onAnswered && onAnswered(initialState[0].id)
    }
  }, [])

  const [selectedOption, setSelectedOption] = useState()

  const onSelectOption = option => {
    const selected = question.installments.find(installment => installment.numberOfInstallments === option.installments)
    setSelectedOption(selected)
    onAnswered(selected)
    setNextEnabled(true)
  }

  return [selectedOption, onSelectOption, options]
}
