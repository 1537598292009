import styled from 'styled-components'

export const ProviButton = styled.button`
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  text-decoration: none;
  letter-spacing: 2px;
  color: white;
  font-size: 12px;
  border: unset;
  text-transform: uppercase;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.9)};
  }
  &:focus {
    outline: none;
  }
`
