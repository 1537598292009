import styled from 'styled-components'
import { ProviButton } from '../../components/provi-button'

export const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background: ${({ visuals }) => visuals && visuals.background};
  background-size: cover;
  background-position: ${({ isMobile }) => (isMobile ? 'top left' : 'center')};
`

export const Container = styled.div`
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
  position: relative;
  height: 100vh;
  color: ${({ visuals }) => visuals.loginTextColor};
`

export const ImageLogo = styled.img`
  margin-top: 20px;
  margin: 10px;
  width: 260px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonGoogle = styled.button`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  color: white;
  height: 45px;
  background-color: ${({ visuals }) => visuals.loginButton};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 30px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 20px 10px 40px 0px;
  font-family: Roboto, sans-serif;
  &:hover {
    opacity: 0.8;
  }
  cursor: pointer;
  span {
    margin-top: 5px;
  }
`

// export const ButtonGitHub = styled(GitHubLogin)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 10px;
//   height: 45px;
//   width: 100%;
//   background-color: black;
//   color: white;
//   box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
//   border-radius: 30px;
//   border: 1px solid transparent;
//   font-size: 14px;
//   font-weight: 500;
//   padding: 10px 10px 10px 0px;
//   font-family: Roboto, sans-serif;
// `

export const LinkLogin = styled.div`
  padding: 20px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  color: ${({ visuals }) => visuals.primaryColor};
`

export const ImageLogoGoogle = styled.img`
  width: 25px;
  margin-right: 10px;
`

export const ImageLogoGitHub = styled.img`
  width: 18px;
  margin-right: 10px;
`

export const ContainerAuthSocial = styled.div`
  background-color: ${({ visuals }) => visuals.loginWrapperColor};
`

export const WrapperHome = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Wrapper = styled.div`
  padding: 12px 22px;
  height: 100%;
  max-height: 550px;
  width: 100%;
  max-width: 340px;
  border-radius: 5px;
  background-color: ${({ visuals }) => visuals.loginWrapperColor};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({ visuals }) => visuals.justifyContent};
  align-items: center;
  box-sizing: border-box;
  ${({ isMobile }) => isMobile && 'overflow: auto;'};
`

export const WrapperForm = styled.form`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: calc(100% - 170px);
`

export const WrapperButton = styled.div`
  margin-top: 20px;
`

export const Button = styled(ProviButton)`
  float: none;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ visuals }) => visuals.loginButton};
`

export const LinkHelp = styled.a`
  margin-top: 20px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`

export const Header = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
`

export const WrapperIcon = styled.div`
  cursor: pointer;
`

export const WrapperHelp = styled.div``

export const TextError = styled.span`
  font-size: 10px;
  color: red;
  text-align: left;
`

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const Text = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 17px;
  line-height: 30px;
  ${({ bold }) => bold && 'font-weight: bold;'};
  color: black;
`

export const TextTerms = styled.p`
  font-size: 12px;
  line-height: 2;
  text-align: left;
  color: black;
`

export const TextLink = styled.a`
  font-size: 12px;
  line-height: 2;
  text-align: left;
  color: black;
  text-decoration: underline;
  cursor: pointer;
`
