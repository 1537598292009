import { useState } from 'react'

export const useProfessionValidator = (onAnswered, setNextEnabled, question) => {
  const [value, setValue] = useState(question.value)

  const onValueChange = profession => {
    setValue(profession)
    onAnswered(profession)

    if (!profession || profession === '') {
      setNextEnabled(false)

      return
    }
    setNextEnabled(true)
  }

  return [onValueChange, value]
}
