import { useState, useEffect } from 'react'
import firebase from '../../services/firebase'
import { showToast } from '../toast'
import { slackHook } from '../../services/slack'

export const useFileUpload = (onAnswered, setNextEnabled, question) => {
  const [status, setStatus] = useState('pending')
  const [progress, setProgress] = useState(0)
  const [numberOfUploads, setNumberOfUploads] = useState(0)
  const [urlsState, setUrlsState] = useState([])

  useEffect(() => {
    if (question.value.length > 0) {
      setNextEnabled(true)
      setNumberOfUploads(question.value.length)
      onAnswered && onAnswered(question.value)
      return
    }
  }, [])

  const onDrop = async files => {
    const reader = new FileReader()

    reader.onerror = error => {
      showToast('Deu erro, tente novamente por favor!')
    }

    files.map(file => (file.uploadedToFirebase = false))
    let urls
    await Promise.all(
      files.map(async element => {
        setStatus('processing')

        const now = new Date().getTime()
        const storagePath = `/${firebase.auth().currentUser.uid}/${now}`

        let uploadRef = firebase.storage().ref(storagePath)
        let uploadTask = uploadRef.put(element)

        uploadTask.on(
          'state_changed',
          snapshot => {
            setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
          },
          error => {
            slackHook(error, 'proof enrollment')
            showToast('Deu erro, tente novamente por favor!')
            setStatus('pending')
          },
          async complete => {
            const url = await uploadRef.getDownloadURL()
            element['url'] = url
            urls = files.map(item => {
              return {
                downloadURL: item.url
              }
            })

            const newUrls = urls.concat(urlsState)
            setUrlsState(newUrls)
            setNumberOfUploads(urls.length + numberOfUploads)
            if (files.length === urls.length) {
              setNextEnabled(true)
              onAnswered(newUrls)
              setStatus('complete')
            }
          }
        )
      })
    )
  }

  return [onDrop, status, progress, numberOfUploads]
}
