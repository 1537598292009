import React from 'react'
import { View, Text, SendButton } from './style'
import { Tip } from '../tip'

export const RequestPermission = ({ setNextEnabled, onAnswered, question, visuals, isMobile }) => {
  // const [] = useComplyValidator(onAnswered, setNextEnabled, question)
  return (
    <View>
      <Text isMobile={isMobile} visuals={visuals}>
        Quero fazer um teste qualquer de texto que vai ser colocado aqui Quero fazer um teste qualquer de texto que vai ser
        colocado aqui Quero fazer um teste qualquer de texto que vai ser colocado aqui Quero fazer um teste qualquer de texto
        que vai ser colocado aqui Quero fazer um teste qualquer de texto que vai ser colocado aqui
      </Text>
      <Tip
        visuals={visuals}
        text="Algum texto de dica aqui Algum texto de dica aqui Algum texto de dica aqui Algum texto de dica aqui Algum texto de dica aqui Algum texto de dica aqui Algum texto de dica aqui"
      />
      <SendButton isMobile={isMobile} visuals={visuals}>
        {'Entendi'}
      </SendButton>
    </View>
  )
}
