import React, { useState, useEffect } from 'react'
import { currencyParser } from '../../utils'
import { CardBox, Text, Card, Column, ButtonBox, ProgressButton, StatusImg } from './style'

export const CreditRequestCard = ({ handleStatus, initiateCreditRequest, isMobile, item, visuals }) => {
  const [courseIndex, setCourseIndex] = useState()

  useEffect(() => {
    if (item.courses && item.courses.length > 1) setCourseIndex(true)
  }, [])

  return (
    <Card isMobile={isMobile} visuals={visuals}>
      <StatusImg src={handleStatus(item.status)} alt="status image" />
      <CardBox>
        {item.courses &&
          item.courses.map((courses, index) => {
            return (
              <Column key={index}>
                <Text visuals={visuals}>Curso {courseIndex ? index + 1 : null}: </Text>
                <Text bold visuals={visuals}>
                  {courses.name}
                </Text>
              </Column>
            )
          })}
        <Text visuals={visuals}>
          Valor Total: <b>{currencyParser(item.checkoutPrice, 'R$')}</b>
        </Text>
        {item.allowProgress.show && (
          <ButtonBox>
            <ProgressButton onClick={() => initiateCreditRequest(item.CreditRequestId, item.checkoutId)} visuals={visuals}>
              {item.allowProgress.text}{' '}
            </ProgressButton>
          </ButtonBox>
        )}
      </CardBox>
    </Card>
  )
}
