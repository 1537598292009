import styled, { css } from 'styled-components'

export const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const Text = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 22px;
  ${({ bold }) => bold && 'font-weight: bold'};
  color: ${({ visuals }) => visuals.questionColor};
  ${({ theme }) => css`
    ${theme.breakpoints.up('xs')} {
      line-height: 120%;
      padding: 5px 10px;
      font-size: 0.9em;
    }
  `}
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      line-height: 120%;
      padding: 5px 10px;
      font-size: 1.1em;
    }
  `}
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ visuals }) => `1.5px solid ${visuals.questionColor}`};
  background: transparent;
  margin: 12px 5px;
  border-radius: 5px;
  max-width: 340px;
  font-size: 1.1em;
  min-height: ${({ isMobile }) => (isMobile ? '195px' : '300px')};
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 80%;
      margin: 8px auto;
      max-width: 200px;
    }
    ${theme.breakpoints.down('sm')} {
      max-width: 320px;
    }
  `}
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 10px 0;
`
export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin: 35px 45px;
  width: 100%;
`
export const Flow = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  overflow: auto;
  align-items: center;
  justify-content: center;
`

export const StatusImg = styled.img`
  height: auto;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  ${({ isMobile }) => isMobile && 'margin: 12px 0;'}
  margin: 12px 0;
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `};
`
export const Title = styled.p`
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 14px;
  color: ${({ visuals }) => visuals.questionColor};
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin: 5px 5px;
      div {
        display: flex;
        align-items: center;
        line-height: 1.6rem;
        font-size: 1.4rem;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          font-weight: bold;
          font-size: 1.4rem;
          border-right: 2px solid #fff;
          padding: 10px 10px 10px 0;
          margin-right: 10px;
        }
      }
    }
    ${theme.breakpoints.down('xs')} {
      div {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  `};
`

export const ButtonBox = styled.div`
  text-align: center;
  margin: 15px 0;
`
export const ProgressButton = styled.button`
  background: transparent;
  font-size: 1.1em;
  color: ${({ visuals }) => visuals.questionColor};
  text-decoration: underline;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus,
  &:visited {
    color: #fff;
    text-decoration: none;
  }
`
export const CardBox = styled.div`
  justify-content: space-around;
  height: 100%;
`
export const Link = styled.a`
  color: ${({ visuals }) => visuals.questionColor};
  text-decoration: none;
  &:hover,
  &:focus,
  &:active,

`
