import axios from 'axios'
import { ROOT_URL } from '../constants'
import firebase from '../services/firebase'
import { FRONT_END_TOKEN, ERRORS, EMBEDDED_BASE_URL } from '../constants'
import logger from '../utils/logger'
import { isNull } from 'lodash'

// API GLOBAL
export const api = axios.create({
  baseURL: ROOT_URL
})

export const getFirebaseUser = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUserIdGTM(user.uid)
        localStorage.setItem('userId', user.uid)
        resolve(user)
      } else {
        reject()
      }
    })
  })
}

const getToken = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) return
      user
        .getIdToken()
        .then(newToken => {
          resolve(newToken)
        })
        .catch(() => {
          logout()
        })
    })
  })
}

api.interceptors.request.use(
  async function(config) {
    const token = await getToken()
    if (token) {
      config.headers.Authorization = token
    } else {
      logout()
    }
    return config
  },
  function(error) {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location = '/login'
    } else {
      return Promise.reject(error)
    }
  }
)

export const logout = (partner, history) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      if (history) {
        history.push(`/intro/${partner}`)
      }
    })
}

export const checkIfEmailIsAvailable = async email => {
  return new Promise((resolve, _reject) => {
    const options = {
      method: 'POST',
      headers: { Authorization: FRONT_END_TOKEN },
      data: { data: email },
      url: `${ROOT_URL}/api/check-email`
    }
    axios(options)
      .then(({ data }) => {
        const { provider, available, changePassword } = data
        resolve({ available, provider, changePassword })
      })
      .catch(error => {
        logger({ error })
        resolve({ available: false })
      })
  })
}

export const createProviUser = data => {
  return new Promise((resolve, reject) => {
    api
      .post('api/auth/sign_up', { data })
      .then(res => resolve(res.data))
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data, info: { data } })
          reject(error)
          return
        }
        logger({ error, info: { data } })
      })
  })
}

export const createGoogleUser = data => {
  return new Promise((resolve, reject) => {
    api
      .post('/api/auth/google/create', { data })
      .then(res => {
        resolve(res.data)
      })
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data, info: { data } })
          reject(error)
          return
        }
        logger({ error, info: { data } })
      })
  })
}

export const sendForm = ({ endpoint, data }) => {
  return new Promise((resolve, reject) => {
    api
      .post(endpoint, data)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data, info: { endpoint, data } })
          reject(error.response.data)
          return
        }
        logger({ error, info: { endpoint, data } })
      })
  })
}

export const fetchByScreen = (screen, id) => {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search)
    const info = urlParams.get('info')
    const cachedInfo = localStorage.getItem('info')
    const slug = localStorage.getItem('slugPartner')
    const CreditRequestId = localStorage.getItem('crid')

    const id_ = localStorage.getItem('PartnerId')
    let endpoint = `/v1/partner/credit-analysis/${id ? id : id_}/screen`

    if (info || cachedInfo) endpoint = `${endpoint}?info=${info || cachedInfo}`

    const screenData = { screen }

    if (CreditRequestId) screenData.CreditRequestId = CreditRequestId

    api
      .post(endpoint, screenData)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(async error => {
        try {
          const { uid } = await getFirebaseUser()
          if (error.response) {
            logger({ error: error.response.data })
            reject(error.response.data)
            return
          }
          logger({ error, info: { idPartner: id, screen, uid: uid } })
        } catch (e) {
          logger({ error })
        }
      })
  })
}

export const submitGuarantor = cpf => {
  console.log('submitGuarantor: ', cpf)

  return new Promise((resolve, reject) => {
    const endpoint = '/v1/guarantor'

    api
      .post(endpoint, { data: { data: cpf } })
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data })
          reject(error.response.data)
          return
        }

        logger({ error, info: { cpfGuarantor: cpf } })
      })
  })
}

export const sendConfirmationCode = phone => {
  return new Promise((resolve, reject) => {
    const endpoint = '/v1/user/phone/confirm'
    api
      .post(endpoint, { data: phone })
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data })
          reject(error.response.data)
          return
        }
        logger({ error, info: { phone } })
      })
  })
}

export const getPartnerId = param => {
  return new Promise((resolve, reject) => {
    const endpoint = `/v1/partner/slug/${param}`
    api
      .get(endpoint)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data })
          reject(error.response.data)
          return
        }
        logger({ error, info: param })
      })
  })
}

export const goToNext = (nextScreen = null) => {
  return new Promise((resolve, reject) => {
    const slug = localStorage.getItem('slugPartner')
    const endpoint = `/v1/partner/next-screen/${slug}`

    const screenData = { data: nextScreen }

    const CreditRequestId = localStorage.getItem('crid')
    if (CreditRequestId) screenData.CreditRequestId = CreditRequestId

    api
      .post(endpoint, screenData)
      .then(({ data }) => resolve(data))
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data })
          reject(error.response.data)
          return
        }
        logger({ error, info: { nextScreen: nextScreen || 'Não tem screen' } })
        resolve({ error: true })
      })
  })
}

export const goToPrevious = (currentScreen = null) => {
  return new Promise((resolve, reject) => {
    const slug = localStorage.getItem('slugPartner')

    const endpoint = `/v1/partner/previous-screen/${slug}`

    const screenData = { data: currentScreen }

    const creditRequestId = localStorage.getItem('crid')
    if (creditRequestId) screenData.CreditRequestId = creditRequestId

    api
      .post(endpoint, screenData)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data })
          reject(error.response.data)
          return
        }
        logger({ error, info: { currentScreen } })
      })
  })
}

export const getGuarantors = () => {
  const slug = localStorage.getItem('slugPartner')
  const CreditRequestId = localStorage.getItem('crid')

  let endpoint = '/v1/guarantor'

  if (CreditRequestId) endpoint = `/v1/guarantor/${slug}/${CreditRequestId}`

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(endpoint)
      resolve(data)
    } catch (error) {
      if (error.response) {
        logger({ error: error.response.data })
        reject({ error: true, msg: error.response.data.msg })
        return
      }
      logger({ error })
    }
  })
}

export const removeGuarantors = id => {
  const endpoint = `/v1/guarantor/${id}`

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.delete(endpoint)
      resolve(data)
    } catch (error) {
      if (error.response) {
        logger({ error: error.response.data })
        reject({ error: true, msg: error.response.data.msg })
        return
      }
      logger({ error, info: { idDeleteGuarantor: id } })
    }
  })
}

export const copiedGuarantors = id => {
  const endpoint = `/v1/guarantor/${id}/copied`

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.put(endpoint, {})
      resolve(data)
    } catch (error) {
      if (error.response) {
        logger({ error: error.response.data })
        reject({ error: true, msg: error.response.data.msg })
        return
      }
      logger({ error })
    }
  })
}

export const saveNewGuarantor = cpf => {
  const endpoint = `/v1/guarantor`

  const slug = localStorage.getItem('slugPartner')
  const CreditRequestId = localStorage.getItem('crid')

  const submitGuarantorData = { data: cpf }

  if (CreditRequestId) submitGuarantorData.CreditRequestId = CreditRequestId

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.post(endpoint, submitGuarantorData)
      resolve(data)
    } catch (error) {
      if (error.response) {
        logger({ error: error.response.data })
        reject({ error: true, msg: error.response.data.msg })
        return
      }
      logger({ error })
    }
  })
}

export const saveTerms = async partner => {
  const endpoint = `/v1/user/partner-terms-and-conditions/${partner}`
  return new Promise(async (resolve, reject) => {
    try {
      await api.post(endpoint, { data: true })
      resolve(true)
    } catch (error) {
      if (error.response) {
        logger({ error: error.response.data })
        reject({ error: true, msg: error.response.data.msg })
        return
      }
      logger({ error, info: { partner } })
      reject(error)
    }
  })
}

export const getStatus = async () => {
  const PartnerId = localStorage.getItem('PartnerId')
  const slug = localStorage.getItem('slugPartner')
  const CreditRequestId = localStorage.getItem('crid')

  let endpoint = `/v1/user/credit-request/status`

  if (PartnerId) endpoint = `${endpoint}/${PartnerId}`

  if (!isNull(CreditRequestId)) endpoint = `/v1/user/credit-request/status/${PartnerId}/${CreditRequestId}`

  try {
    const { data } = await api.get(endpoint)
    return data
  } catch (error) {
    if (error.response) {
      logger({ error: error.response.data })
      return { error: true, msg: error.response.data.msg }
    }
    logger({ error })
    return { error: true, msg: ERRORS.default }
  }
}

export const getStatusInstallment = async () => {
  const PartnerId = localStorage.getItem('PartnerId')

  let endpoint = `/v1/user/loan-option/status`
  if (PartnerId) endpoint = `${endpoint}/${PartnerId}`

  try {
    const { data } = await api.get(endpoint)
    return data
  } catch (error) {
    if (error.response) {
      logger({ error: error.response.data })
      return { error: true, msg: error.response.data.msg }
    }
    let info = {}
    firebase.auth().onAuthStateChanged(user => {
      info.user = user
      info.partnerId = PartnerId
      logger({ error, info })
    })
    return { error: true, msg: ERRORS.default }
  }
}

export const getStatusContract = async () => {
  const PartnerId = localStorage.getItem('PartnerId')

  let endpoint = `v1/admin/docusign/contract-status`
  if (PartnerId) endpoint = `${endpoint}/${PartnerId}`
  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.get(endpoint)
      resolve(data)
    } catch (error) {
      if (error.response) {
        logger({ error: error.response.data })
        reject(error.response.data.msg)
        return
      }
      logger({ error })
      reject(ERRORS.default)
    }
  })
}

export const getContract = async crid => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await axios.get(`${EMBEDDED_BASE_URL}/v1/admin/get-contract/${crid}`, {
        headers: { Authorization: FRONT_END_TOKEN }
      })
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}

export const setUserIdGTM = userId => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'Login',
    userId: userId
  })
}

export const restartCredit = (checkoutId = null) => {
  const PartnerId = localStorage.getItem('PartnerId')
  let endpoint = `/v1/user/credit-request/restart`

  if (PartnerId) endpoint = `${endpoint}/${PartnerId}`

  if (checkoutId && PartnerId) endpoint = `/v1/user/credit-request/restart/${PartnerId}/?checkoutId=${checkoutId}`

  return new Promise(async (resolve, reject) => {
    try {
      const data = await api.post(endpoint)

      const { CreditRequestId } = data.data
      if (CreditRequestId) localStorage.setItem('crid', CreditRequestId)
      resolve(data)
    } catch (error) {
      if (error.response) {
        logger({ error: error.response.data })
        reject(error.response.data.msg)
        return
      }
      reject(ERRORS.default)
    }
  })
}

export const checkDecryptInfo = async info => {
  return new Promise((resolve, _reject) => {
    const options = {
      method: 'POST',
      headers: { Authorization: FRONT_END_TOKEN },
      data: { info },
      url: `${ROOT_URL}/v1/partner/decrypt-info`
    }
    axios(options)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(error => {
        if (error.response) {
          logger({ error: error.response.data })
          _reject(error.response.data.msg)
          return
        }
        logger({ error })
        _reject(error)
      })
  })
}

export const checkForFirebaseUser = async email => {
  return new Promise((resolve, _reject) => {
    const options = {
      method: 'POST',
      headers: { Authorization: FRONT_END_TOKEN },
      data: { data: email },
      url: `${ROOT_URL}/api/check-firebase-user`
    }
    axios(options)
      .then(({ data }) => {
        const { hasFirebaseUser } = data
        resolve({ hasFirebaseUser })
      })
      .catch(error => {
        logger({ error })
        resolve({ hasFirebaseUser: false })
      })
  })
}
