import styled from 'styled-components'

export const View = styled.div`
  padding-top: 14px;
`

export const Link = styled.div`
  margin-top: 15px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`
