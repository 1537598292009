import styled from 'styled-components'

export const ProgressBarContainer = styled.div`
  height: 6px;
  border-color: #09b5dd;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
`
