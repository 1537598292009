import React from 'react'
import { OptionsList, Option, OptionText, OptionDay } from './style'
import { useDaySelect } from './hooks'

export const IntendedPaymentDay = ({ question, onAnswered, setNextEnabled, visuals }) => {
  const [selectedOption, onSelectOption, dayOptions] = useDaySelect(onAnswered, setNextEnabled, question)

  return (
    <OptionsList>
      {dayOptions &&
        dayOptions.map(option => (
          <Option
            key={option.id}
            visuals={visuals}
            isSelected={selectedOption !== undefined && option.id === selectedOption.id}
            onClick={() => onSelectOption(option)}
          >
            <OptionText isSelected={selectedOption !== undefined && option.id === selectedOption.id} visuals={visuals}>
              Todo dia
            </OptionText>
            <OptionDay isSelected={selectedOption !== undefined && option.id === selectedOption.id} visuals={visuals}>
              {option.value}
            </OptionDay>
          </Option>
        ))}
    </OptionsList>
  )
}
