import React from 'react'
import { View, Link } from './style'
import { TextField } from '../text-field'
import { NextButton } from '../next-button'
import { useLastCompany } from './hooks'

export const LastCompany = props => {
  const { setNextEnabled, onAnswered, question, visuals, goToNextScreen } = props
  const [onValueChange, value, enabledButton] = useLastCompany(onAnswered, setNextEnabled, question)

  const redirectTo = param => {
    onAnswered(param)
    goToNextScreen()
  }

  return (
    <View>
      <TextField visuals={visuals} placeholder={'Ex: Provi'} onValueChange={onValueChange} value={value} />
      <NextButton title="OK" enabled={enabledButton} isMobile visuals={visuals} onClick={() => redirectTo(value)} />
      <Link onClick={() => redirectTo('Nunca trabalhei')}>Nunca trabalhei</Link>
    </View>
  )
}
