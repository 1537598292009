import React, { useState } from 'react'
import firebase from '../../../services/firebase'
import google_logo from '../../../assets/images/google-logo.png'
import { createGoogleUser, setUserIdGTM, saveTerms } from '../../../services/api'
import { showToast } from '../../../components/toast'
import { LoadingLogin } from '../../../components/loading'
import { termsAndConditionsLink, privacyPolicyLink } from '../../../constants'
import { Buttons, LinkLogin, ButtonGoogle, ImageLogoGoogle, ContainerAuthSocial, Text, TextTerms, TextLink } from '../style'

export default ({ redirectTo, history, match, visuals }) => {
  const GOOGLE_PROVIDER = new firebase.auth.GoogleAuthProvider()
  const [loading, setLoading] = useState(false)

  const submit = async () => {
    const partner = match.params.school
    setLoading(true)
    try {
      const res = await firebase.auth().signInWithPopup(GOOGLE_PROVIDER)
      const data = {
        additionalUserInfo: res.additionalUserInfo,
        user: {
          displayName: res.user.displayName,
          email: res.user.email,
          emailVerified: res.user.emailVerified,
          photoURL: res.user.photoURL,
          uid: res.user.uid
        }
      }
      setUserIdGTM(data.user.uid)
      await createGoogleUser(data)
      await saveTerms(partner)
      history.push(`/cadastro/${partner}`)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      showToast(e.msg || 'Houve um erro tente novamente mais tarde')
    }
  }

  return (
    <ContainerAuthSocial visuals={visuals}>
      {loading && <LoadingLogin color={visuals.loginButton} />}
      <Buttons>
        <ButtonGoogle visuals={visuals} onClick={() => submit(redirectTo)}>
          <ImageLogoGoogle src={google_logo} />
          <span>Entrar com o Google</span>
        </ButtonGoogle>
        {/* <ButtonGitHub
          client
          Id="ac56fad434a3a3c1561e"
          onSuccess={response => console.log(response)}
          onFailure={response => console.log(response)}
        >
          <ImageLogoGitHub src={github_logo} />
          <p>Entrar com GitHub</p>
        </ButtonGitHub> */}
        <LinkLogin visuals={visuals} onClick={() => redirectTo('signup')}>
          Entrar com e-mail e senha
        </LinkLogin>
      </Buttons>
      <Text bold visuals={visuals}>
        Ao prosseguir, você concorda com:
      </Text>
      <TextTerms visuals={visuals}>
        1. Os{' '}
        <TextLink visuals={visuals} target="_blank" href={termsAndConditionsLink}>
          Termos de Uso
        </TextLink>{' '}
        e a{' '}
        <TextLink visuals={visuals} href={privacyPolicyLink} target="_blank">
          Política de Privacidade
        </TextLink>{' '}
        da Provi.
      </TextTerms>
      <TextTerms visuals={visuals}>
        2. A consulta / compartilhamento com a Provi de suas informações do SCR do Banco Central.
      </TextTerms>
    </ContainerAuthSocial>
  )
}
