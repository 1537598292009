import React from 'react'
import { View } from './style'
import { Selector } from '../selector'
import { useNumberDependents } from './hooks'

export const NumberDependents = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, numberDependents, setNumberDependent, numberDependent] = useNumberDependents(
    onAnswered,
    setNextEnabled,
    question
  )

  numberDependents[0].label = 'Não tenho'
  numberDependents[1].label = '1 dependente'
  numberDependents[2].label = '2 dependentes'
  numberDependents[3].label = '3 dependentes'
  numberDependents[4].label = '4 dependentes'
  numberDependents[5].label = '5 dependentes'
  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={numberDependents}
        value={numberDependent}
        onValueChange={onValueChange}
      />
    </View>
  )
}
