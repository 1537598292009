import React from 'react'

export const DetailsPlan = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#ff531d"
    strokeWidth="2"
    strokeLinecap="square"
    strokeLinejoin="arcs"
  >
    <path d="M13 17l5-5-5-5M6 17l5-5-5-5" />
  </svg>
)
