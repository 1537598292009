import React from 'react'
import { View, Column, Label } from './style'
import { TextField } from '../text-field'
import { Selector } from '../selector'
import { useReferrerPicker } from './hooks'

const styleLabel = {
  fontSize: '1.2rem',
  fontWeight: 'bold'
}

export const Referrer = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChangeReferrer, referrers, selectedReferrer, onValueChangeInput, value] = useReferrerPicker(
    onAnswered,
    setNextEnabled,
    question
  )
  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={referrers}
        value={selectedReferrer}
        onValueChange={onValueChangeReferrer}
      />

      {selectedReferrer && ['embaixadores', 'ex-alunos'].includes(selectedReferrer.value) && (
        <Column>
          <Label style={styleLabel}>Qual seu Cupom ?</Label>
          <TextField
            visuals={visuals}
            value={value}
            placeholder={'Digite seu cupom...'}
            onValueChange={onValueChangeInput}
            icon={question.value.coupon}
            disabled={question.value.coupon}
          />
        </Column>
      )}
    </View>
  )
}
