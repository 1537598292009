const breakpoints = {
  xs: 340,
  sm: 500,
  md: 800,
  lg: 1280,
  xl: 1920
}

export default {
  ...breakpoints,
  up: bp => `@media (min-width: ${breakpoints[bp]}px)`,
  down: bp => `@media (max-width: ${breakpoints[bp]}px)`
}
