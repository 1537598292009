import React from 'react'
import { OptionsList, Option, OptionText } from './style'
import { useCurseSelect } from './hooks'

const curses = [
  {
    id: 1,
    item: true,
    status: '',
    value: 'SOU RESIDENTE'
  },
  {
    id: 2,
    item: false,
    value: 'SOU ESTUDANTE'
  }
]

const SelectCurseView = ({ question, onAnswered, setNextEnabled, visuals }) => {
  const [selectedOption, onSelectOption] = useCurseSelect(question, onAnswered, setNextEnabled)
  return (
    <OptionsList>
      {curses.map(option => (
        <Option
          key={option.id}
          visuals={visuals}
          isSelected={selectedOption !== undefined && option.id === selectedOption.id}
          onClick={() => onSelectOption(option)}
        >
          <OptionText visuals={visuals}>{option.value}</OptionText>
        </Option>
      ))}
    </OptionsList>
  )
}
export default SelectCurseView
