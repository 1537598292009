import styled, { keyframes } from 'styled-components'
import { Button } from '../../globalStyles'

export const CloseModal = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
`
const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  display: flex;
  -webkit-animation-name: ${opacity}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${opacity};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

const scale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

export const Container = styled.div`
  position: absolute;
  max-width: 700px;
  height: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  padding: ${({ isMobile }) => (isMobile ? '25px' : '30px')};
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '80px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '250px')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-around' : 'space-between')};
  -webkit-animation-name: ${scale}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${scale};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

export const Header = styled.div`
  position: relative;
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 35px;
`
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Title = styled.p`
  color: ${({ visuals }) => visuals.bgColor};
  margin-top: 25px;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: center;
`

export const SubTitle = styled.p`
  color: ${({ visuals }) => visuals.bgColor};
  margin-top: 25px;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  text-align: center;
`

export const Description = styled.p`
  font-size: 1rem;
  line-height: 25px;
  text-align: justify;
`

export const ConfirmButton = styled(Button)`
  width: 200px;
  height: 58px;
  color: ${({ visuals }) => visuals.questionColor};
  background: #ff531b;
  border-radius: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 15px;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  cursor: pointer;

  display: flex;
  justify-content: space-around;
  align-items: center;
  &:hover {
    opacity: 0.6;
  }
`

export const ExclamationIcon = styled.span`
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  box-sizing: border-box;
`
