import { connect } from 'react-redux'
import { fetchScreen, answerQuestion, fetchPreviousScreen, loadingStart, loadingEnd, fetchModalError } from '../../actions'
import RegisterView from './view'

const mapStateToProps = state => ({
  currentScreen: state.get('app').get('currentScreen'),
  nextScreenUniqueName: state.get('app').get('nextScreenUniqueName'),
  isLoading: state.get('app').get('isLoading'),
  loadingAnswer: state.get('app').get('loadingAnswer'),
  errorMessage: state.get('app').get('errorMessage')
})

const mapDispatchToProps = dispatch => ({
  fetchScreen(uniqueName, id) {
    dispatch(fetchScreen(uniqueName, id))
  },
  fetchPreviousScreen(uniqueName, id) {
    dispatch(fetchPreviousScreen(uniqueName, id))
  },
  answerQuestion(question, id) {
    dispatch(answerQuestion(question, id))
  },
  loadingStart() {
    dispatch(loadingStart())
  },
  loadingEnd() {
    dispatch(loadingEnd())
  },
  fetchModalError() {
    dispatch(fetchModalError(false))
  }
})

export const Register = connect(mapStateToProps, mapDispatchToProps)(RegisterView)
