import { useState, useEffect } from 'react'

export const useNumberDependents = (onAnswered, setNextEnabled, question) => {
  const optionsNumberDependents = question.options.map(item => {
    return {
      label: item,
      id: item
    }
  })
  const initialState = optionsNumberDependents.find(item => question.value === item.id)
  const [numberDependent, setNumberDependent] = useState(initialState)

  const onValueChange = number => {
    setNextEnabled(true)
    setNumberDependent(number)
    onAnswered(number.id)
  }

  useEffect(() => {
    numberDependent && onValueChange(numberDependent)
  }, [])

  return [onValueChange, optionsNumberDependents, setNumberDependent, numberDependent]
}
