import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const OptionsList = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: row;
`
export const Option = styled(Button)`
  border: 1px solid ${({ visuals, isSelected }) => (isSelected ? 'transparent' : visuals.questionColor)};
  background: ${({ visuals, isSelected }) => (isSelected ? visuals.buttonBgColor : 'transparent')};
  margin: 2px;
  border-radius: 8px;
`
export const OptionText = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ visuals }) => visuals.questionColor};
`
