import React from 'react'
import { NextButton } from '../../components/next-button'
import { HelmetWrapper } from '../../components/helmet'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { View, TitleContainer, Title, Holder, ButtonBox, Link, ProgressButton, Flow } from './style'
import { UserCreditList } from './hooks'
import { useScreenMeasure } from '../register/hooks'
import { LoadingText } from '../../components/loading'
import { CreditRequestCard } from './CreditRequestCard'
import { capitalize } from 'lodash'

export const ListCreditRequest = props => {
  const [
    slugPartner,
    initiateCreditRequest,
    handleStatus,
    creditRequests,
    allowNewCR,
    redirectURL,
    visuals,
    isLoading,
    schoolSelect,
    killSession
  ] = UserCreditList(props)
  const [isMobile] = useScreenMeasure()

  return (
    <ScreenImageBackground visuals={visuals} background={schoolSelect.background} justifyContent="center">
      <HelmetWrapper partner={slugPartner} />
      <View isMobile={isMobile}>
        {isLoading ? (
          <LoadingText visuals={visuals} />
        ) : (
          <>
            <TitleContainer isMobile={isMobile}>
              <Title isMobile={isMobile} visuals={visuals}>
                {!allowNewCR && creditRequests
                  ? 'Selecione abaixo a solicitação de financiamento que você deseja dar continuidade'
                  : `Infelizmente, você ainda não possui nenhuma solicitação de crédito com a ${capitalize(
                      visuals.partnerName
                    )}.`}
              </Title>
            </TitleContainer>
            {!allowNewCR && creditRequests ? (
              <>
                {isMobile && allowNewCR && (
                  <ButtonBox>
                    <NextButton
                      isMobile={isMobile}
                      visuals={visuals}
                      title={'Começar nova socilitação'}
                      enabled={true}
                    ></NextButton>
                  </ButtonBox>
                )}
              </>
            ) : (
              <Link visuals={visuals} href={redirectURL} target="_blank">
                <NextButton
                  isMobile={true}
                  visuals={visuals}
                  onClick={() => null}
                  title={`Visite o site da ${visuals.partnerName}`}
                  enabled={true}
                />
              </Link>
            )}
            <Holder isMobile={isMobile}>
              <Flow isMobile={isMobile}>
                {creditRequests &&
                  creditRequests.map((item, idx) => {
                    return (
                      <CreditRequestCard
                        key={idx}
                        handleStatus={handleStatus}
                        initiateCreditRequest={initiateCreditRequest}
                        isMobile={isMobile}
                        visuals={visuals}
                        item={item}
                      />
                    )
                  })}
              </Flow>

              <ButtonBox>
                <ProgressButton onClick={() => killSession()} isMobile={true} visuals={visuals}>
                  Logout
                </ProgressButton>
              </ButtonBox>
            </Holder>
          </>
        )}
      </View>
    </ScreenImageBackground>
  )
}
