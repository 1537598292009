import React, { useEffect } from 'react'
import { OptionsList, Wrapper, Text, WrapperText } from './style'

export const ConfirmPaymentFlow = props => {
  const { visuals, setNextEnabled, question } = props

  useEffect(() => {
    setNextEnabled(true)
  }, [])

  return (
    <OptionsList>
      <Wrapper visuals={visuals}>
        {question &&
          question.screenTexts &&
          question.screenTexts.map((item, index) => (
            <WrapperText key={index}>
              <Text visuals={visuals}>{item.dateText}</Text>
              <Text visuals={visuals}>{item.installmentText}</Text>
            </WrapperText>
          ))}
      </Wrapper>
    </OptionsList>
  )
}
