import React from 'react'
import { View, ProgressContainer, PartnerLogo, SideContainer } from '../../footer-mobile/style'
import { ProgressBarContainer } from './style'

export const FooterMobile = props => {
  return (
    <View>
      <ProgressContainer>
        <ProgressBarContainer />
      </ProgressContainer>
      <SideContainer>{props.visuals && <PartnerLogo src={props.visuals.partnerLogoURL} />}</SideContainer>
    </View>
  )
}
