import React from 'react'
import Select from 'react-select'
import { View } from './style'

const customStyles = visuals => ({
  container: () => ({ position: 'relative', zIndex: 99, width: '100%' }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 20,
    backgroundColor: '#fff',
    color: '#666',
    '&:hover': {
      backgroundColor: state.isFocused ? '#f3f3f3' : '#fff'
    }
  }),
  control: provided => ({
    ...provided,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'transparent'
  }),
  singleValue: provided => ({
    ...provided,
    color: visuals.questionColor,
    fontSize: 20,
    lineHeight: 34
  }),
  input: provided => ({
    ...provided,
    color: visuals.questionColor,
    fontSize: 20
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: visuals.questionColor
  }),
  placeholder: provided => ({
    ...provided,
    color: visuals.questionColor,
    opacity: 0.5,
    fontSize: 25,
    lineHeight: 34
  }),
  indicatorSeparator: () => ({})
})

export const Selector = ({ visuals, options, value, onValueChange, ...props }) => (
  <View>
    <Select styles={customStyles(visuals)} options={options} value={value} onChange={onValueChange} {...props} />
  </View>
)
