import React from 'react'
import {
  Overlay,
  Container,
  Header,
  Body,
  Footer,
  Title,
  SubTitle,
  Description,
  CloseModal,
  ConfirmButton,
  ExclamationIcon
} from './style'
import { Close } from '../../assets/svg/close'
import { CheckIcon } from '../../assets/svg/checked_icon'

export const ModalAreYouSure = ({ visuals, isMobile, onDismiss, nextStep }) => {
  return (
    <Overlay>
      <Container isMobile={isMobile}>
        <Header>
          <CloseModal onClick={onDismiss}>
            <Close />
          </CloseModal>
          <Title visuals={visuals} isMobile={isMobile}>
            Você chegou ao fim da sua solicitação da Mesada UP!
          </Title>
          <SubTitle visuals={visuals} isMobile={isMobile}>
            Antes de enviar, confirme que:
          </SubTitle>
        </Header>
        <Body>
          <Description isMobile={isMobile}>
            <CheckIcon />
            As informações inseridas estão corretas
          </Description>
          <Description isMobile={isMobile}>
            <CheckIcon />
            Os documentos e fotos estão nítidos e legíveis
          </Description>
          <Description isMobile={isMobile}>
            <ExclamationIcon>!</ExclamationIcon> Após enviar, sua solicitação entrará em análise e vc não vai poder alterar seus
            dados
          </Description>
        </Body>
        <Footer>
          <ConfirmButton visuals={visuals} isMobile={isMobile} onClick={nextStep}>
            enviar solicitação
          </ConfirmButton>
        </Footer>
      </Container>
    </Overlay>
  )
}
