import React from 'react'
import { Route } from 'react-router-dom'
import { Login } from '../../screens/login'
import { Loading } from '../../components/loading'

export const ProtectedRoute = props => {
  const { component: Component, auth, isLoading, ...rest } = props
  if (isLoading) {
    return <Route {...rest} render={props => <Loading {...props} />} />
  }

  if (!auth) {
    return <Route {...rest} render={props => <Login {...props} />} />
  }

  return <Route {...rest} render={props => <Component {...props} auth={auth} />} />
}
