import React from 'react'
import {
  View,
  CourseValue,
  Simulate,
  OptionsList,
  Option,
  OptionText,
  OptionDetails,
  Detail,
  DetailLabel,
  DetailValue
} from './style'
import { useOptionsPicker } from './hooks'

export const Installments = props => {
  const { setNextEnabled, onAnswered, question, visuals } = props
  const course = JSON.parse(localStorage.getItem('course'))
  const [options, selectedOption, onSelectOption] = useOptionsPicker(onAnswered, setNextEnabled, question)
  return (
    <View>
      <CourseValue visuals={visuals}>{`todo mês vc recebe: ${
        course.value === '7' ? question.Course._priceAnista : question.Course._priceResidente
      }`}</CourseValue>
      <Simulate visuals={visuals}>Depois pague de volta em:</Simulate>
      <OptionsList>
        {options.map(option => (
          <Option
            key={option.id}
            visuals={visuals}
            isSelected={selectedOption !== undefined && option.id === selectedOption.id}
            onClick={() => onSelectOption(option)}
          >
            <OptionText visuals={visuals}>{option.installmentsToApply}x</OptionText>
          </Option>
        ))}
      </OptionsList>
      {selectedOption && (
        <OptionDetails>
          <Detail>
            <DetailLabel visuals={visuals}>{selectedOption.installmentsToApply} parcelas de:</DetailLabel>
            <DetailValue visuals={visuals}>{selectedOption.installmentValue}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel visuals={visuals}>Último pagamento:</DetailLabel>
            <DetailValue visuals={visuals}>{selectedOption.lastPayment}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel visuals={visuals}>Taxa de juros:</DetailLabel>
            <DetailValue visuals={visuals}>{selectedOption.monthlyInterest} (ao mês)</DetailValue>
          </Detail>
        </OptionDetails>
      )}
    </View>
  )
}
