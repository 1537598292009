import { useState, useEffect } from 'react'
import firebase from '../../services/firebase'
import { showToast } from '../toast'
import { slackHook } from '../../services/slack'

export const useFileUpload = (onAnswered, setNextEnabled, question) => {
  const [status, setStatus] = useState('pending')
  const [file, setFile] = useState(undefined)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (question && question.value) {
      setNextEnabled(true)
      onAnswered && onAnswered(question.value)

      return
    }

    setNextEnabled(false)
  }, [])

  const onDrop = file => {
    const reader = new FileReader()

    reader.onerror = error => {
      showToast('Deu erro, tente novamente por favor!')
    }

    reader.onload = () => {
      setFile(reader.result)
      setStatus('processing')

      const now = new Date().getTime()
      const storagePath = `/${firebase.auth().currentUser.uid}/${now}`

      let uploadRef = firebase.storage().ref(storagePath)
      let uploadTask = uploadRef.put(file[0])

      uploadTask.on(
        'state_changed',
        snapshot => {
          setProgress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        error => {
          slackHook(error, 'selfie')
          showToast('Deu erro, tente novamente por favor!')
          setStatus('pending')
        },
        async complete => {
          setNextEnabled(true)
          const url = await uploadRef.getDownloadURL()
          onAnswered(url)
          setStatus('complete')
        }
      )
    }

    try {
      reader.readAsDataURL(file[0])
    } catch (error) {
      showToast('Deu erro, tente novamente por favor!')
    }
  }

  return [file, onDrop, status, progress]
}
