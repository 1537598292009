import React from 'react'
import { OptionsList, OptionMonthlyInterest, Option, OptionText, ContainerRecommended } from './style'
import { usePaymentSelect } from './hooks'
import { Star } from '../../assets/svg/star2'

export const ChoosePaymentOption = ({ question, onAnswered, setNextEnabled, visuals, isMobile }) => {
  const [selectedOption, onSelectOption, cursesOptions] = usePaymentSelect(onAnswered, setNextEnabled, question)

  return (
    <OptionsList>
      {cursesOptions &&
        cursesOptions.map(option => (
          <Option
            key={option.id}
            visuals={visuals}
            isSelected={selectedOption !== undefined && option.id === selectedOption.id}
            onClick={() => onSelectOption(option)}
          >
            {option.isRecommendedPlan && (
              <ContainerRecommended isMobile={isMobile}>
                <Star />
                <p>Plano recomendado pra você</p>
              </ContainerRecommended>
            )}
            <OptionText isSelected={selectedOption !== undefined && option.id === selectedOption.id} visuals={visuals}>
              {option.text}
            </OptionText>
            <OptionMonthlyInterest
              isSelected={selectedOption !== undefined && option.id === selectedOption.id}
              visuals={visuals}
            >
              {option._monthlyInterest}
            </OptionMonthlyInterest>
          </Option>
        ))}
    </OptionsList>
  )
}
