import { useState } from 'react'
import { showToast } from '../../toast'
import { isValidCpf } from '../../../utils'
import { getGuarantors, removeGuarantors, saveNewGuarantor } from '../../../services/api'

export const useCpfValidator = (setGuarantors, guarantor, closeModal, isNewGuarantor, setIsLoading, setNextEnabled) => {
  const [showError, setShowError] = useState(false)
  const [value, setValue] = useState()
  const [cpfValid, setCpfValid] = useState(false)

  const onDismiss = () => {
    setShowError(false)
  }

  const saveGuarantor = async () => {
    if (!value) {
      setShowError(true)
      return
    }

    if (!isValidCpf(value.replace(/[^\d]+/g, ''))) {
      setShowError(false)
      showToast('CPF inválido')
      return
    }

    setIsLoading(true)

    if (isNewGuarantor) {
      try {
        await removeGuarantors(guarantor.id)
      } catch (error) {
        showToast(error.msg)
        setIsLoading(false)
        return
      }
    }

    try {
      const response = await saveNewGuarantor(value)
      if (response.error) {
        showToast(response.msg)
        setIsLoading(false)
        return
      }
    } catch (error) {
      showToast(error.msg)
      setIsLoading(false)
      return
    }

    const { data } = await getGuarantors()
    const filterWasClicked = data.filter(item => item.wasClicked)
    setNextEnabled(data.length === filterWasClicked.length)
    setGuarantors(data)
    setIsLoading(false)
    closeModal()
    showToast('Avalista adicionado 😃')
  }

  const onValueChange = cpf => {
    setValue(cpf)
    setCpfValid(false)

    if (cpf.includes('_')) {
      setCpfValid(false)
      return
    }
    if (!isValidCpf(cpf.replace(/[^\d]+/g, ''))) {
      setShowError(true)
      setCpfValid(false)
      return
    }

    setCpfValid(true)
    setShowError(false)
  }

  return [showError, onDismiss, onValueChange, value, saveGuarantor, cpfValid]
}
