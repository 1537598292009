import { useState } from 'react'

export const useCurseSelect = (question, onAnswered, setNextEnabled) => {
  const [selectedOption, setSelectedOption] = useState()

  const onSelectOption = option => {
    setSelectedOption(option)
    onAnswered(option)
    setNextEnabled(true)
  }

  return [selectedOption, onSelectOption]
}
