import { captureException } from '@sentry/browser'
import { isString } from 'lodash'
const { NODE_ENV } = process.env

export default ({ error, info }) => {
  if (NODE_ENV === 'development') {
    console.log('error: ', JSON.stringify(error))
    console.log('info: ', JSON.stringify(info))
  }

  if (error && error.true && isString(error.msg)) {
    if (error && error.msg && error.msg.toLowerCase().includes('invalid')) {
      captureException({ error })
      return
    }
  }

  if (error.msg || error.statusInstallment) {
    return
  }

  error = {
    error: error,
    info: info
  }

  if (NODE_ENV === 'development') return
  captureException(error)
}
