import { useState, useEffect } from 'react'
import { getFirebaseUser } from '../../../services/api'
import { showToast } from '../../../components/toast'
import { api } from '../../../services/api'

export const useHooks = (history, match) => {
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getFirebaseUser().then(user => {
      setUser(user)
      setIsLoading(false)
    })
  }, [])

  const resendConfirmationEmail = () => {
    setIsLoading(true)
    user
      .sendEmailVerification()
      .then(() => {
        setIsLoading(false)
        showToast('Email reenviado!')
      })
      .catch(() => {
        setIsLoading(false)
        showToast('Aguarde um instante...')
      })
  }

  const checkEmailVerified = () => {
    setIsLoading(true)
    user
      .reload()
      .then(() => {
        setIsLoading(false)
        if (user.emailVerified) {
          const endpoint = '/v1/user/email/verify'

          api
            .put(endpoint, { data: user.email })
            .then(() => history.push(`/cadastro/${match.params.school}`))
            .catch(() => {
              setIsLoading(false)
              showToast('Ocorreu um erro, tente novamente 😔')
            })

          return false
        }
        showToast('Email não verificado 😔')
      })
      .catch(() => {
        setIsLoading(false)
        showToast('Ocorreu um erro, tente novamente 😔')
      })
  }

  return [isLoading, user, resendConfirmationEmail, checkEmailVerified]
}
