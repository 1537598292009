import styled from 'styled-components'

export const OptionsList = styled.div`
  padding: 0px;
  display: flex;
  height: auto;
  overflow: auto;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  border: ${({ isSelected }) => (isSelected ? '3px solid white' : '1px solid white')};
  background: ${({ isSelected }) => (isSelected ? 'rgba(250,250,250,0.3)' : 'none')};
  border-radius: 15px;
  ${({ isMobile }) => isMobile && 'margin-bottom: 10px;'};
  ${({ isMobile }) => !isMobile && 'margin-left: 10px; width: 400px'};
  height: auto;
  padding: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`

export const OptionTitle = styled.p`
  font-size: ${({ isMobile }) => (isMobile ? '1rem' : '1.4rem')};
  line-height: 22px;
`

export const OptionText = styled.p`
  text-align: left;
  margin: 5px 10px;
  font-size: ${({ isMobile }) => (isMobile ? '0.8rem' : '1rem')};
`

export const SpanBuild = styled.span`
  font-weight: bold;
`

export const Row = styled.div`
  ${({ end }) => end && 'justify-content: flex-end'};
  ${({ between }) => between && 'justify-content: space-between'};
  ${({ margin }) => margin && 'margin-top: 5px'};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff531d;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 15px;
`

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
`

export const ContainerRecommended = styled.div`
  text-align: center;
  padding: 5px 10px;
  font-size: 0.8rem;
  position: relative;
  top: ${({ isMobile }) => (isMobile ? '-16px' : '-30px')};
  color: #333;
  background: #ffbb00;
  font-weight: bold;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    margin-left: 5px;
  }
`

export const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 20px;
  height: 20px;
`
