import { useState } from 'react'
import { find } from 'lodash'

export const useCivilStatePicker = (onAnswered, setNextEnabled, question) => {
  const civilStates = question.options.map(item => {
    return {
      label: item.name,
      value: item.name,
      id: item.id
    }
  })

  const initialState = find(civilStates, { value: question.value })
  const [selectedCivilState, setSelectedCivilState] = useState(initialState)

  setNextEnabled(true)

  const onValueChange = civilState => {
    setSelectedCivilState(civilState)
    onAnswered(civilState.id)
  }

  return [onValueChange, civilStates, selectedCivilState]
}
