import styled, { css } from 'styled-components'

export const View = styled.div`
  margin-top: 15px;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  display: flex;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin-top: 0px;
    }
  `};
`

export const ContainerGuarantors = styled.div`
  display: flex;
  margin: 5px;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  flex-wrap: wrap;
`
export const ButtonAddGuarantor = styled.div`
  display: flex;
  width: 280px;
  height: 90px;
  border: 2px dashed #fff;
  font-weight: bold;
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextLink = styled.div`
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
