import React from 'react'

export const ClipboardGuarantor = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.143 5.53577H3.32145C1.48706 5.53577 0 7.02283 0 8.85722V27.6787C0 29.5131 1.48706 31.0002 3.32145 31.0002H22.143C23.9774 31.0002 25.4644 29.5131 25.4644 27.6787V8.85722C25.4644 7.02283 23.9774 5.53577 22.143 5.53577ZM23.2502 27.6788C23.2502 28.2903 22.7545 28.786 22.143 28.786H3.32145C2.70997 28.786 2.21428 28.2903 2.21428 27.6788V8.85722C2.21428 8.24573 2.70997 7.75005 3.32145 7.75005H22.143C22.7545 7.75005 23.2502 8.24573 23.2502 8.85722V27.6788Z"
      fill="rgba(0,0,0,0.2)"
    />
    <path
      d="M27.6779 0H7.74919C5.9148 0 4.42773 1.48706 4.42773 3.32145C4.42773 3.93293 4.92342 4.42862 5.53491 4.42862C6.14639 4.42862 6.64201 3.93293 6.64201 3.32145C6.64201 2.70997 7.1377 2.21428 7.74919 2.21428H27.6779C28.2894 2.21428 28.7851 2.70997 28.7851 3.32145V23.2502C28.7851 23.8616 28.2894 24.3573 27.6779 24.3573C27.0664 24.3573 26.5707 24.853 26.5707 25.4645C26.5707 26.076 27.0664 26.5717 27.6779 26.5717C29.5123 26.5717 30.9993 25.0846 30.9993 23.2502V3.32145C30.9993 1.48706 29.5123 0 27.6779 0Z"
      fill="rgba(0,0,0,0.2)"
    />
  </svg>
)
