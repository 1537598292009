import React from 'react'
import { View, FieldsContainer } from './style'
import { Loading } from '../loading'
import { TextField, FormField } from '../text-field'
import { ErrorMessage } from '../error-message'
import { useCepValidator } from './hooks'

export const Cep = ({ setNextEnabled, onAnswered, question, visuals, isMobile }) => {
  const [showError, onDismiss, onValueChange, onFormChange, value, isLoading, isShowAddress] = useCepValidator(
    onAnswered,
    setNextEnabled,
    question
  )

  return (
    <View>
      {isLoading && <Loading visuals={visuals} />}
      <TextField
        type="tel"
        visuals={visuals}
        placeholder={'Ex: 01406-000'}
        value={value.zipcode}
        onValueChange={onValueChange}
        mask="99999 - 999"
        alwaysShowMask={false}
        maskChar={null}
      />
      {isShowAddress && (
        <>
          <FieldsContainer isMobile={isMobile}>
            <FormField
              value={value['state']}
              visuals={visuals}
              spacing={40}
              size={40}
              placeholder="UF"
              maskChar={null}
              mask="aa"
              onValueChange={v => onFormChange(v, 'state')}
            />
            <FormField
              value={value['city']}
              visuals={visuals}
              spacing={12}
              size={120}
              placeholder="Cidade"
              onValueChange={v => onFormChange(v, 'city')}
            />
            <FormField
              value={value['street']}
              visuals={visuals}
              spacing={12}
              size={280}
              placeholder="Endereço"
              onValueChange={v => onFormChange(v, 'street')}
            />
            <FormField
              value={value['district']}
              visuals={visuals}
              spacing={20}
              size={160}
              placeholder="Bairro"
              onValueChange={v => onFormChange(v, 'district')}
            />
            <FormField
              value={value['number']}
              visuals={visuals}
              spacing={34}
              size={50}
              placeholder="Nº"
              onValueChange={v => onFormChange(v, 'number')}
            />
            <FormField
              value={value['complement']}
              visuals={visuals}
              spacing={0}
              size={100}
              placeholder="Compl."
              onValueChange={v => onFormChange(v, 'complement')}
              subscript="Opcional"
            />
          </FieldsContainer>
          {showError && <ErrorMessage errorMessage="Preencha todos os campos" onDismiss={onDismiss} />}
        </>
      )}
    </View>
  )
}
