import React from 'react'
import { View, Button, TextContainer, ButtonTitle, Hightlight } from './style'
import { Check } from '../../assets/svg/check'

export const NextButton = ({ isMobile, onClick, enabled, visuals, title }) => (
  <View>
    <Button
      disabled={!enabled}
      enabled={enabled}
      visuals={visuals}
      onClick={({ target }) => (enabled ? onClick(target.value) : undefined)}
    >
      <ButtonTitle color={visuals.buttonTextColor}>{title}</ButtonTitle>
      <Check fill={visuals.buttonTextColor} />
    </Button>
    {!isMobile && (
      <TextContainer>
        <p>
          {`Carrega em `}
          <Hightlight>ENTER</Hightlight>
        </p>
      </TextContainer>
    )}
  </View>
)
