import styled, { css } from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  position: fixed;
  height: 64px;
  width: 100%;
  padding: 5px;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 4px 4px 0 0;
`
export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3vw;
`

export const PartnerLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3vw;
  object-fit: contain;
`

export const PartnerLogo = styled.img`
  max-width: 33vw;
  width: 100%;
  object-fit: contain;
`
export const SideContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 33.3vw;
`
export const ArrowButton = styled(Button)``

export const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 55px;
  border-radius: 5px;
  background: ${({ visuals }) => visuals.primaryColor};
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      height: 50px;
      width: 50px;
    }
  `};
`
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 0.9rem;
  color: ${props => props.primaryColor};
`
