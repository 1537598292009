import React from 'react'
import styled from 'styled-components'
import { useHooks } from './hooks'

import { ProviButton } from '../../../components/provi-button'
import { LoadingLogin } from '../../../components/loading'

export const Email = styled.h2`
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
`

export const Text = styled.p`
  font-size: 13px;
  margin: 20px 0;
`

export const ButtonResend = styled.div`
  text-decoration: underline;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
`

export const Wrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`

export const Button = styled(ProviButton)`
  float: none;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ visuals }) => visuals.loginButton};
`

const ValidationEmail = ({ history, match, visuals }) => {
  const [isLoading, user, resendConfirmationEmail, checkEmailVerified] = useHooks(history, match)

  return (
    <Wrapper>
      {isLoading && <LoadingLogin color={visuals.loginButton} />}
      <Text>Enviamos um email de confirmação para:</Text>
      <Email>{user && user.email}</Email>
      <Text>
        Por favor, cheque seu email e clique no link de confirmação. Depois, volte aqui e clique no botão de prosseguir.
      </Text>
      <Button visuals={visuals} onClick={checkEmailVerified}>
        prosseguir
      </Button>
      <ButtonResend visuals={visuals} onClick={resendConfirmationEmail}>
        REENVIAR EMAIL
      </ButtonResend>
    </Wrapper>
  )
}

export default ValidationEmail
