import React from 'react'

const fillSvg = {
  fill: 'none'
}

export const Document = () => (
  <svg>
    <path className="st0" style={fillSvg} d="M40.6,38.1c0.2-0.2,0.3-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7V38.1z" />
    <path className="st0" style={fillSvg} d="M23.2,36.8c-0.2,0.2-0.2,0.4-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7V36.8z" />
    <path
      className="st0"
      style={fillSvg}
      d="M30.7,27.7l-0.1,0.1c-0.4,0.4-0.8,0.7-1.3,0.9c-0.9,0.4-2,0.4-2.9,0c-0.1,0-0.1,0-0.2-0.1
		c-0.5-0.2-1.1-0.3-1.6,0c-0.3,0.1-0.5,0.3-0.7,0.5c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0.4,0.1,0.9,0.2,1.2,0.5
		c1.2,0.8,2.5,1.2,3.9,1.2c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0c0.3,0,0.5-0.1,0.7-0.1c1.7-0.3,3.3-1.3,4.4-2.7l0-1.8l-2.3-0.8
		c-1.9-0.6-3.8-0.7-5.6-0.2c-1.3,0.4-2.2,0.9-2.8,1.6c0.7-0.1,1.5,0,2.2,0.3c0.8,0.4,1.8,0.2,2.3-0.5l0.1-0.1c0,0,0-0.1,0.1-0.1
		c0.3-0.3,0.8-0.3,1.2,0l0,0c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0,0.1,0,0.1C31,27.3,30.9,27.5,30.7,27.7z"
    />
    <path
      className="st0"
      style={fillSvg}
      d="M41.5,20.1H22.2c-1.1,0-2,0.9-2,2h0v27.1c1.2-2.1,3-3.9,5.2-5.1c-1-1.1-1.7-2.5-2-3.9
		c-1.3-0.3-2.2-1.4-2.2-2.8c0-1.2,0.8-2.3,2-2.7v-3.2c-0.3,0.1-0.7,0.1-1-0.1c-0.5-0.3-0.7-0.9-0.5-1.4l0.2-0.6
		c0.1-0.3,0.2-0.5,0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.2c0.3-2.4,2-4.1,4.8-4.9c2.2-0.6,4.5-0.5,6.6,0.2l2.6,0.9c0.9-0.3,1.9-0.1,2.7,0.4
		c0.9,0.6,1.4,1.6,1.4,2.7v7c1.2,0.4,2,1.4,2,2.7c0,1.3-0.9,2.4-2.2,2.8c-0.3,1.5-1,2.8-2,4c0.2,0.1,0.4,0.2,0.6,0.4
		c1.9,1.2,3.5,2.8,4.6,4.7V22.1C43.6,21,42.7,20.1,41.5,20.1z"
    />
    <path
      className="st0"
      style={fillSvg}
      d="M27.2,43.6c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.2,0.1,0.2,0.2c1.1,0.8,2.4,1.2,3.8,1.2c1.4,0,2.7-0.4,3.8-1.1
		c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.3,2-3.1,2-5v-3.7c-1.9-0.4-3.3-2.1-3.3-4v-0.2
		c-0.4,0.3-0.8,0.6-1.2,0.8c-0.9,0.5-1.9,0.9-2.9,1.1c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0c-1.6,0.2-3.2-0.1-4.6-0.8
		c-0.4-0.2-0.8-0.4-1.2-0.7c0,0,0,0-0.1,0v7.2c0,1.9,0.7,3.7,2,5C27,43.4,27.1,43.5,27.2,43.6z M34.9,36.4
		C34.9,36.3,34.9,36.3,34.9,36.4c0.4-0.4,1-0.6,1.5-0.6s1.1,0.2,1.5,0.6c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.4-0.3,0.6
		c-0.2,0.2-0.4,0.3-0.6,0.3s-0.4-0.1-0.6-0.3c-0.1-0.1-0.2-0.1-0.3-0.1s-0.2,0-0.3,0.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.1,0-0.2,0
		c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.6S34.7,36.5,34.9,36.4z M25.6,36.9c0-0.2,0.1-0.4,0.3-0.6c0.4-0.4,0.9-0.6,1.5-0.6
		s1.1,0.2,1.5,0.6c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.1-0.5-0.3l0,0
		c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.4-0.1-0.6-0.3C25.7,37.4,25.6,37.2,25.6,36.9z
		 M26.2,41.3c0-0.2,0.1-0.5,0.3-0.6c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0.2-0.1,0.3-0.2,0.5-0.2
		c0.2,0,0.3,0.1,0.5,0.2c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0.1c2.3,2.3,6.1,2.3,8.4,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0.1-0.1
		c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.4-0.3,0.6
		c-0.8,0.8-1.7,1.4-2.7,1.8c-0.9,0.3-1.8,0.5-2.7,0.5s-1.8-0.2-2.7-0.5c-1-0.4-2-1-2.7-1.8C26.3,41.7,26.2,41.5,26.2,41.3z"
    />
    <path
      className="st0"
      style={fillSvg}
      d="M40.2,47.8c-0.9-0.9-2-1.7-3.2-2.3c-1.5,1.1-3.2,1.6-5.1,1.6c-1.8,0-3.6-0.6-5.1-1.6c-0.4,0.2-0.7,0.4-1.1,0.6
		c-0.8,0.5-1.5,1-2.1,1.7c-0.8,0.7-1.4,1.6-1.9,2.4c-0.2,0.4-0.5,0.8-0.6,1.3c-0.1,0.2-0.2,0.4-0.2,0.6c0.4,0.4,0.9,0.5,1.4,0.5
		h19.3c0.5,0,1-0.2,1.4-0.5c-0.1-0.2-0.2-0.4-0.2-0.6C42.1,50.1,41.3,48.9,40.2,47.8z"
    />
    <path
      className="st0"
      style={fillSvg}
      d="M38.9,32.9v-5.1c0-0.5-0.3-1-0.7-1.3c-0.3-0.2-0.6-0.3-0.9-0.3l0.1,4.4C37.4,31.7,38,32.6,38.9,32.9z"
    />
    <path className="st0" style={fillSvg} d="M79.1,69.6H16.5l-2.9,12.2h72.8l0.5-2.2C83.4,77.1,80.6,73.6,79.1,69.6z" />
    <path
      className="st0"
      style={fillSvg}
      d="M94.2,79.9c0.5,0.1,1.1,0.3,1.7,0.4c1.1,0.2,2.1,0.2,3,0.2c10.2,0,18.5-8.3,18.5-18.5c0-10-7.8-18.1-17.8-18.5
		c-0.2,0-0.5,0-0.7,0c0,0,0,0,0,0c-0.3,0-0.7,0-1,0c-4.8,0.3-9.2,2.3-12.5,5.9c-0.6,0.6-1.1,1.2-1.4,1.7c-2.1,2.9-3.3,6.4-3.5,10
		c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.8c0.1,1.5,0.3,2.9,0.7,4.3c0.2,0.6,0.4,1.1,0.6,1.7c1.3,3.3,3.6,6.1,6.5,8.2l0.3,0.2
		c0.4,0.3,0.8,0.6,1.3,0.8C91.2,78.9,92.7,79.5,94.2,79.9z M85.6,63.4c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h11.9V48.8
		c0-0.8,0.6-1.4,1.4-1.4c0.5,0,0.9,0.2,1.1,0.6c0.2,0.2,0.2,0.5,0.2,0.8v11.9h11.9c0.7,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
		h-11.9v11.9c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4V63.4H85.6z"
    />
    <path
      className="st0"
      style={fillSvg}
      d="M8.2,106.3h74.7c2.8,0,5.2-2.1,6.3-5.5l4-18.4c-1.2-0.4-2.5-0.8-3.6-1.4l-0.6,2.4c-0.2,0.6-0.7,1.1-1.3,1.1
		H11.9c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.4-0.4-0.8-0.3-1.2l3.5-15c0.2-0.6,0.7-1.1,1.3-1.1h62.8c-0.2-1.1-0.4-2.3-0.5-3.4H11.9
		l-9.1,37.8C3,104,5.3,106.3,8.2,106.3z M18.9,92.9c0.3-0.8,1.1-1.1,1.8-0.9c0.7,0.3,1.1,1,0.9,1.8c-0.1,0.2,0.2,0.7,0.6,0.8
		c0.8,0.3,1.9,0,2.3-0.1c2.3-0.6,4.4-1.8,6.7-3c1.2-0.7,2.6-1.4,4-2.1c0.5-0.2,1.6-0.6,2.6-0.3c1.2,0.4,1.9,1.7,1.8,3.2
		c0,0.3,0,0.5-0.1,0.8c0,0.4-0.1,0.8,0,1c0.2,0.8,1.8,1,3.2,0.8c3.7-0.5,7.2-1.5,10.5-3c0.5-0.2,1.6-0.7,2.6,0
		c0.5,0.3,0.7,0.7,0.9,1l0.1,0.2c0.7,1.3,2.9,1.3,5.1,1.2c5.3-0.3,10.7,0,16.1,0.9c0.7,0.1,1.3,0.8,1.1,1.6
		c-0.1,0.7-0.7,1.2-1.4,1.2l-0.2,0C72.4,97,67.2,96.7,62.1,97c-1.8,0.1-5.9,0.4-7.6-2.6c0,0,0,0,0,0c-3.6,1.6-7.4,2.7-11.3,3.2
		c-2.6,0.3-5.5-0.2-6.3-2.8c-0.2-0.7-0.2-1.4-0.1-2l0-0.1c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4-0.1-0.5c-0.1,0-0.2,0.1-0.4,0.2
		c-1.2,0.5-2.4,1.2-3.5,1.8l-0.3,0.2c-2.4,1.3-4.7,2.6-7.3,3.2c-1.6,0.4-2.9,0.4-4,0l0,0C19.2,96.3,18.5,94.3,18.9,92.9z M8.8,99H84
		c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4H8.8c-0.7,0-1.4-0.6-1.4-1.4C7.4,99.6,8.1,99,8.8,99z"
    />
    <path
      className="st0"
      style={fillSvg}
      d="M91.7,11.9H17c-2.9,0-5.3,2.4-5.3,5.3l0.5,43.4h65.5c0.2-3.3,1.2-6.4,2.8-9.3H55.2c-0.8,0-1.4-0.6-1.4-1.4
		s0.6-1.4,1.4-1.4h27.2c3.7-4.5,9.1-7.3,14.8-7.8L97,17.2C97,14.3,94.6,11.9,91.7,11.9z M45.4,50.6c0,0.7-0.2,1.5-0.6,2.1
		c-0.3,0.4-0.5,0.7-0.9,1c-0.5,0.4-1,0.6-1.6,0.8c-0.3,0.1-0.5,0.1-0.8,0.1H22.2c-0.9,0-1.8-0.3-2.4-0.9c-0.3-0.3-0.6-0.6-0.9-1
		c-0.4-0.6-0.6-1.3-0.6-2.1V22.1c0-2.2,1.8-3.9,3.9-3.9h19.3c2.2,0,3.9,1.8,3.9,3.9V50.6z M87.8,42H55.2c-0.7,0-1.4-0.6-1.4-1.4
		c0-0.8,0.6-1.4,1.4-1.4h32.5c0.8,0,1.4,0.6,1.4,1.4C89.2,41.3,88.5,42,87.8,42z M87.8,32.5H55.2c-0.7,0-1.4-0.6-1.4-1.4
		c0-0.8,0.6-1.4,1.4-1.4h32.5c0.8,0,1.4,0.6,1.4,1.4C89.2,31.9,88.5,32.5,87.8,32.5z M87.8,23.1H55.2c-0.7,0-1.4-0.6-1.4-1.4
		c0-0.8,0.6-1.4,1.4-1.4h32.5c0.8,0,1.4,0.6,1.4,1.4C89.2,22.5,88.5,23.1,87.8,23.1z"
    />
    <path
      className="st1"
      d="M27.1,37.5c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0.1l0,0c0.2,0.2,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0
		c0.2,0,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6c-0.4-0.4-0.9-0.6-1.5-0.6s-1.1,0.2-1.5,0.6
		c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3C26.7,37.8,27,37.7,27.1,37.5z"
    />
    <path
      className="st1"
      d="M35.5,37.8c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1
		c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6c-0.4-0.4-0.9-0.6-1.5-0.6s-1.1,0.2-1.5,0.6
		c0,0,0,0,0,0c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6C35.1,37.7,35.3,37.8,35.5,37.8z"
    />
    <path
      className="st1"
      d="M41.5,18.2H22.2c-2.1,0-3.9,1.7-3.9,3.9v28.5c0,0.7,0.2,1.4,0.6,2.1c0.3,0.4,0.6,0.7,0.9,1
		c0.7,0.6,1.6,0.9,2.4,0.9h19.3c0.3,0,0.6,0,0.8-0.1c0.6-0.1,1.1-0.4,1.6-0.8c0.4-0.3,0.6-0.6,0.9-1c0.4-0.6,0.6-1.3,0.6-2.1V22.1
		C45.4,20,43.7,18.2,41.5,18.2z M41.5,52.6H22.2c-0.5,0-1-0.2-1.4-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.2-0.4,0.4-0.9,0.6-1.3
		c0.5-0.9,1.1-1.7,1.9-2.4c0.7-0.7,1.4-1.2,2.1-1.7c0.4-0.2,0.7-0.4,1.1-0.6c1.5,1.1,3.2,1.6,5.1,1.6c1.8,0,3.6-0.6,5.1-1.6
		c1.2,0.6,2.3,1.3,3.2,2.3c1.1,1.1,1.9,2.3,2.5,3.7c0.1,0.2,0.2,0.4,0.2,0.6C42.5,52.5,42.1,52.6,41.5,52.6z M30.7,26.6L30.7,26.6
		c-0.4-0.3-0.9-0.3-1.2,0c0,0-0.1,0.1-0.1,0.1l-0.1,0.1c-0.6,0.7-1.5,0.9-2.3,0.5c-0.7-0.3-1.4-0.4-2.2-0.3c0.6-0.7,1.5-1.3,2.8-1.6
		c1.8-0.5,3.8-0.4,5.6,0.2l2.3,0.8l0,1.8c-1.1,1.4-2.6,2.4-4.4,2.7c-0.2,0.1-0.5,0.1-0.7,0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.4,0
		c-1.4,0-2.7-0.4-3.9-1.2c-0.4-0.3-0.8-0.4-1.2-0.5c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0c0.2-0.2,0.4-0.4,0.7-0.5
		c0.5-0.2,1.1-0.2,1.6,0c0.1,0,0.1,0.1,0.2,0.1c1,0.4,2,0.4,2.9,0c0.5-0.2,0.9-0.5,1.3-0.9l0.1-0.1c0.2-0.2,0.2-0.4,0.2-0.6
		c0,0,0-0.1,0-0.1C30.9,26.8,30.8,26.7,30.7,26.6z M24.9,31.1C24.9,31.1,24.9,31.2,24.9,31.1c0.4,0.3,0.8,0.5,1.2,0.7
		c1.4,0.7,3,0.9,4.6,0.8c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.5-0.1c1-0.2,2-0.6,2.9-1.1c0.4-0.3,0.8-0.5,1.2-0.8v0.2
		c0,2,1.4,3.6,3.3,4v3.7c0,1.9-0.7,3.6-2,5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.4-0.6,0.5c-0.1,0.1-0.2,0.1-0.2,0.2
		c-1.1,0.8-2.4,1.1-3.8,1.1c-1.4,0-2.7-0.4-3.8-1.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.2-0.2-0.3-0.3
		c-1.3-1.3-2-3.1-2-5V31.1z M37.3,26.3c0.3,0,0.6,0.1,0.9,0.3c0.4,0.3,0.7,0.7,0.7,1.3v5.1c-0.9-0.4-1.5-1.2-1.5-2.2L37.3,26.3z
		 M43.6,49.3c-1.1-1.9-2.7-3.5-4.6-4.7c-0.2-0.1-0.4-0.2-0.6-0.4c1-1.1,1.7-2.5,2-4c1.3-0.3,2.2-1.4,2.2-2.8c0-1.3-0.8-2.3-2-2.7v-7
		c0-1.1-0.5-2.1-1.4-2.7c-0.8-0.5-1.8-0.7-2.7-0.4l-2.6-0.9c-2.2-0.7-4.5-0.8-6.6-0.2c-2.9,0.8-4.5,2.5-4.8,4.9
		c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.2-0.3,0.5-0.4,0.7l-0.2,0.6c-0.2,0.5,0,1.1,0.5,1.4c0.3,0.2,0.7,0.2,1,0.1v3.2
		c-1.2,0.4-2,1.5-2,2.7c0,1.3,0.9,2.5,2.2,2.8c0.3,1.5,1,2.8,2,3.9c-2.2,1.2-4,2.9-5.2,5.1V22.1h0c0-1.1,0.9-2,2-2h19.3
		c1.1,0,2,0.9,2,2V49.3z M40.6,38.1v-1.4c0.2,0.2,0.2,0.4,0.2,0.7C40.8,37.7,40.7,38,40.6,38.1z M23.2,36.8v1.4
		c-0.2-0.2-0.2-0.4-0.2-0.7C22.9,37.2,23,37,23.2,36.8z"
    />
    <path
      className="st1"
      d="M31.9,44.1c0.9,0,1.8-0.2,2.7-0.5c1-0.4,2-1,2.7-1.8c0.2-0.2,0.3-0.4,0.3-0.6s-0.1-0.5-0.3-0.6
		c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c-2.3,2.3-6.1,2.3-8.4,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2
		c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-0.2,0.2-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6c0.8,0.8,1.7,1.4,2.7,1.8
		C30.1,43.9,31,44.1,31.9,44.1z"
    />
    <path
      className="st1"
      d="M87.8,20.3H55.2c-0.7,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h32.5c0.8,0,1.4-0.6,1.4-1.4
		C89.2,21,88.5,20.3,87.8,20.3z"
    />
    <path
      className="st1"
      d="M87.8,29.8H55.2c-0.7,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h32.5c0.8,0,1.4-0.6,1.4-1.4
		C89.2,30.4,88.5,29.8,87.8,29.8z"
    />
    <path
      className="st1"
      d="M87.8,39.2H55.2c-0.7,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h32.5c0.8,0,1.4-0.6,1.4-1.4
		C89.2,39.8,88.5,39.2,87.8,39.2z"
    />
    <path
      className="st1"
      d="M8.8,101.8H84c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H8.8c-0.7,0-1.4,0.6-1.4,1.4
		C7.4,101.1,8.1,101.8,8.8,101.8z"
    />
    <path
      className="st1"
      d="M21.1,97.1L21.1,97.1c1.1,0.5,2.4,0.5,4,0c2.6-0.7,5-1.9,7.3-3.2l0.3-0.2c1.1-0.6,2.3-1.3,3.5-1.8
		c0.2-0.1,0.4-0.2,0.4-0.2c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4,0,0.5l0,0.1c-0.1,0.6-0.1,1.3,0.1,2c0.7,2.5,3.7,3.1,6.3,2.8
		c3.9-0.5,7.7-1.6,11.3-3.2c0,0,0,0,0,0c1.7,3,5.9,2.7,7.6,2.6c5.2-0.3,10.4,0,15.5,0.8l0.2,0c0.7,0,1.3-0.5,1.4-1.2
		c0.1-0.8-0.4-1.5-1.1-1.6c-5.3-0.9-10.7-1.2-16.1-0.9c-2.2,0.1-4.4,0.1-5.1-1.2l-0.1-0.2c-0.2-0.3-0.4-0.7-0.9-1
		c-1-0.7-2.2-0.2-2.6,0c-3.3,1.5-6.8,2.5-10.5,3c-1.4,0.2-3,0-3.2-0.8c-0.1-0.2,0-0.6,0-1c0-0.2,0.1-0.5,0.1-0.8
		c0.1-1.5-0.7-2.8-1.8-3.2c-1-0.4-2,0-2.6,0.3c-1.4,0.6-2.8,1.4-4,2.1c-2.2,1.2-4.3,2.4-6.7,3c-0.4,0.1-1.5,0.4-2.3,0.1
		c-0.4-0.2-0.7-0.6-0.6-0.8c0.2-0.7-0.1-1.5-0.9-1.8c-0.7-0.2-1.5,0.1-1.8,0.9C18.5,94.3,19.2,96.3,21.1,97.1z"
    />
    <path
      className="st1"
      d="M100,40.8l-0.3-23.6c0-4.5-3.6-8.1-8.1-8.1H17c-4.4,0-8.1,3.6-8.1,8.1l0.5,44.7l-9.3,38.8c0,0.1,0,0.3,0,0.4
		c0,4.5,3.6,8.1,8.1,8.1h74.7c4.1,0,7.5-2.9,8.9-7.5l4-18.5c1,0.2,2,0.2,3.1,0.2c11.7,0,21.2-9.5,21.2-21.3
		C120.1,50.8,111.2,41.4,100,40.8z M86.5,81.8H13.7l2.9-12.2h62.5c1.5,4,4.3,7.6,7.9,10L86.5,81.8z M82.5,48.6H55.2
		c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h25.3c-1.6,2.8-2.6,6-2.8,9.3H12.2l-0.5-43.4c0-2.9,2.4-5.3,5.3-5.3h74.7
		c2.9,0,5.3,2.4,5.3,5.3l0.3,23.6C91.5,41.2,86.1,44.1,82.5,48.6z M77.7,63.4c0.1,1.1,0.2,2.3,0.5,3.4H15.4c-0.6,0-1.2,0.4-1.3,1.1
		l-3.5,15c-0.1,0.4,0,0.8,0.3,1.2c0.3,0.3,0.7,0.5,1.1,0.5h75.6c0.6,0,1.2-0.4,1.3-1.1l0.6-2.4c1.2,0.6,2.4,1.1,3.6,1.4l-4,18.4
		c-1.1,3.5-3.4,5.5-6.3,5.5H8.2c-2.9,0-5.2-2.3-5.3-5.2l9.1-37.8H77.7z M88.2,77.1c-2.9-2.1-5.2-4.9-6.5-8.2
		c-0.2-0.6-0.4-1.2-0.6-1.7c-0.4-1.4-0.6-2.8-0.7-4.3c0-0.3,0-0.6,0-0.8c0-0.3,0-0.6,0-0.9c0.2-3.6,1.4-7.1,3.5-10
		c0.4-0.5,0.9-1.1,1.4-1.7c3.3-3.5,7.7-5.6,12.5-5.9c0.3,0,0.7,0,1,0c0,0,0,0,0,0c0.2,0,0.4,0,0.7,0c10,0.3,17.8,8.5,17.8,18.5
		c0,10.2-8.3,18.5-18.5,18.5c-0.9,0-1.9-0.1-3-0.2c-0.6-0.1-1.2-0.2-1.7-0.4c-1.5-0.4-3-1-4.4-1.8c-0.4-0.2-0.8-0.5-1.3-0.8
		L88.2,77.1z"
    />
    <path
      className="st1"
      d="M97.5,75.3c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4V63.4h11.9c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
		h-11.9V48.8c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.4-0.7-0.6-1.1-0.6c-0.8,0-1.4,0.6-1.4,1.4v11.9H85.6c-0.8,0-1.4,0.6-1.4,1.4
		s0.6,1.4,1.4,1.4h11.9V75.3z"
    />
  </svg>
)
