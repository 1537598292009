import { Element } from 'react-scroll'
import styled, { css } from 'styled-components'
import { Button } from '../../../globalStyles'

export const ElementView = styled(Element)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`
export const GoOutButton = styled(Button)`
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  opacity: 1;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 16px 10px;
  width: 60%;
`
export const ButtonTitle = styled.p`
  text-align: 'center';
  color: #fff;
  font-size: 16px;
  line-height: 22px;
`
export const HandleButtons = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 70%;
  flex-direction: column;
  ${({ theme }) => css`
    ${theme.breakpoints.up('xs')} {
      flex-direction: column;
      width: 80%;
    }
  `};
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      flex-direction: column;
      width: 70%;
    }
  `};
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      flex-direction: column;
      width: 50%;
      max-width: 60%;
      margin: 0;
    }
  `};
`
