import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'

let config = {}

export const setupFirebase = () => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    console.log('[production] -> firebase')
    config = {
      apiKey: 'AIzaSyCnK8KRBUBhD9E09r4XM3rbqMgggpE_gTg',
      authDomain: 'provi-hmg.firebaseapp.com',
      databaseURL: 'https://provi-hmg.firebaseio.com',
      projectId: 'provi-hmg',
      storageBucket: 'provi-hmg.appspot.com',
      messagingSenderId: '88789028347',
      appId: '1:88789028347:web:75911a963c8da157'
    }
  } else {
    console.log('[development] -> firebase')
    config = {
      apiKey: 'AIzaSyDdvafhdgP1hR62caAamhvw_7JEwoaBUH4',
      authDomain: 'provi-dev.firebaseapp.com',
      databaseURL: 'https://provi-dev.firebaseio.com',
      projectId: 'provi-dev',
      storageBucket: 'provi-dev.appspot.com',
      messagingSenderId: '1017827692469',
      appId: '1:1017827692469:web:4bf3e45dc5b4bc2b'
    }
  }

  firebase.initializeApp(config)

  return firebase
}

export default firebase
