import React from 'react'
import { useScreenMeasure } from '../../screens/register/hooks'
import { Title, Text, Link, Container } from './style'
import { useIntroduction } from './hooks'

export const ClosedSubscribes = props => {
  const [visuals] = useIntroduction(props)
  const [isMobile] = useScreenMeasure()

  return (
    <>
      {visuals && (
        <Container visuals={visuals} isMobile={isMobile}>
          <Title isMobile={isMobile}>As inscrições estão encerradas!</Title>
          <Text isMobile={isMobile}>
            <strong>Se você tem interesse em solicitar sua mesada UP,</strong> acesse o site{' '}
            <Link href="https://www.sanarup.com/">https://www.sanarup.com/</Link> e faça seu pré-cadastro.
          </Text>
          <Text isMobile={isMobile}>
            <strong>Se você já solicitou sua mesada,</strong> tudo que você precisa fazer é entrar no app da Provi e confirmar o
            recebimento a partir do último dia de cada mês.
          </Text>
        </Container>
      )}
    </>
  )
}
