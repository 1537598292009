import { useState, useEffect } from 'react'

export const usePlanSelect = (question, onAnswered, setNextEnabled, setIsShowModal) => {
  const [selectedOption, setSelectedOption] = useState()
  const [plans, setPlans] = useState(question.plans)
  const [plan, setPlan] = useState({})

  const initialState = plans.filter(item => question.value === item.id)

  useEffect(() => {
    setNextEnabled(false)
    if (question.value) {
      setNextEnabled(true)
      if (initialState.length) {
        onSelectOption(initialState[0].id)
        onAnswered && onAnswered(initialState[0].id)
      }
    }
  }, [])

  const onSelectOption = option => {
    setSelectedOption(option)
    setIsShowModal(false)
    onAnswered(option)
    setNextEnabled(true)
  }

  return [selectedOption, onSelectOption, plans, setPlan, plan]
}

export const useModal = (question, onAnswered, setNextEnabled) => {
  const [isShowModal, setIsShowModal] = useState(false)

  return [isShowModal, setIsShowModal]
}
