import { useState, useEffect } from 'react'

export const useCoursePicker = (onAnswered, setNextEnabled, question) => {
  const options = question.options.map(item => ({
    label: item.name,
    id: item.id
  }))

  const initialState = options && options.filter(item => question.value === item.id)

  const [selectedCourse, setSelectedCourse] = useState(initialState)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onValueChange(initialState)
      onAnswered && onAnswered(initialState[0].id)
    }
  }, [])

  const onValueChange = number => {
    setNextEnabled(true)
    setSelectedCourse(number)
    onAnswered(number.id)
  }

  return [onValueChange, options, selectedCourse]
}
