import React from 'react'

import { HelmetWrapper } from '../../components/helmet'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { Loading } from '../../components/loading'
import { NextButton } from '../../components/next-button'
import { useScreenMeasure } from '../register/hooks'
import { Title, Text, BodyContainer, Wrapper, LinkRedirect } from './style'
import { useBankSlip } from './hooks'

export const BankSlip = props => {
  const [isMobile] = useScreenMeasure()
  const [isLoading, schoolSelect, visuals, slugPartner, screen] = useBankSlip(props)
  return (
    <ScreenImageBackground visuals={visuals} background={schoolSelect.background}>
      <HelmetWrapper partner={slugPartner} />
      {isLoading && <Loading visuals={visuals} />}
      {!isLoading && (
        <BodyContainer>
          <Wrapper>
            {screen && screen.title && <Title isMobile={isMobile}>{screen.title}</Title>}

            {screen &&
              screen.screenTexts &&
              screen.screenTexts.map(text => (
                <Text key={text} isMobile={isMobile}>
                  {text}
                </Text>
              ))}

            {screen && screen.redirectURL && visuals && (
              <LinkRedirect href={screen.redirectURL} target="_blank">
                {slugPartner && slugPartner !== 'sanar' && (
                  <NextButton isMobile enabled={true} visuals={visuals} onClick={() => true} title="GARANTIR MINHA VAGA" />
                )}
              </LinkRedirect>
            )}
          </Wrapper>
        </BodyContainer>
      )}
    </ScreenImageBackground>
  )
}
