import React from 'react'
import { View, TextLink, ContainerGuarantors, ButtonAddGuarantor } from './style'
import { Loading } from '../loading'
import { useGuarantorsList } from './hooks'
import { CapsuleGuarantor } from '../capsule-guarantor'
import { ModalGuarantor } from '../modal-guarantor'
import { ModalNewGuarantor } from './modalNewGuarantor'

const ListGuarantorsView = ({ question, onAnswered, setNextEnabled, visuals, isMobile }) => {
  const [
    guarantor,
    guarantors,
    selectGuarantor,
    copyMessage,
    isShowModal,
    setIsShowModal,
    isShowmodalNewGuarantor,
    setIsShowmodalNewGuarantor,
    setGuarantors,
    setIsNewGuarantor,
    isNewGuarantor,
    canAddOtherGuarantor,
    isLoading,
    setIsLoading
  ] = useGuarantorsList(question, onAnswered, setNextEnabled)

  return (
    <>
      {isLoading && <Loading visuals={visuals} />}
      <View isMobile={isMobile}>
        <ContainerGuarantors isMobile={isMobile}>
          {guarantors &&
            guarantors.length > 0 &&
            guarantors.map((item, index) => (
              <CapsuleGuarantor
                key={index}
                margin={index !== 0}
                index={index}
                item={item}
                isEditable={true}
                openModal={() => {
                  selectGuarantor(item)
                  setIsShowModal(true)
                }}
                isNewGuarantor={isNewGuarantor}
                setIsNewGuarantor={setIsNewGuarantor}
                setGuarantors={setGuarantors}
                selectGuarantor={selectGuarantor}
                setIsShowmodalNewGuarantor={setIsShowmodalNewGuarantor}
                isMobile={isMobile}
                visuals={visuals}
                guarantors={guarantors}
                setIsLoading={setIsLoading}
              />
            ))}
        </ContainerGuarantors>

        {canAddOtherGuarantor && (
          <TextLink
            onClick={() => {
              setIsNewGuarantor(false)
              setIsShowmodalNewGuarantor(true)
            }}
            isMobile={isMobile}
          >
            <ButtonAddGuarantor>adicionar outro</ButtonAddGuarantor>
          </TextLink>
        )}
      </View>
      {isShowModal && (
        <ModalGuarantor
          visuals={visuals}
          guarantor={guarantor}
          isMobile={isMobile}
          setNextEnabled={setNextEnabled}
          copyMessage={copyMessage}
          onDismiss={() => setIsShowModal(false)}
        />
      )}
      {isShowmodalNewGuarantor && (
        <ModalNewGuarantor
          setNextEnabled={setNextEnabled}
          isNewGuarantor={isNewGuarantor}
          setGuarantors={setGuarantors}
          visuals={visuals}
          guarantor={guarantor}
          isMobile={isMobile}
          copyText={copyMessage}
          setIsLoading={setIsLoading}
          closeModal={() => setIsShowmodalNewGuarantor(false)}
        />
      )}
    </>
  )
}

export default ListGuarantorsView
