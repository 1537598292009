import { Map } from 'immutable'
import * as T from './types'
import { showToast } from '../components/toast'
import { sendQuestionAnswer } from '../services/answer-service'
import { fetchByScreen, goToPrevious } from '../services/api'

export const loadingStart = () => ({
  type: T.LOADING_START,
  payload: null
})
export const loadingEnd = () => ({
  type: T.LOADING_END,
  payload: null
})

const fetchScreenStart = () => ({
  type: T.FETCH_SCREEN_START
})
const fetchScreenError = errorScreen => ({
  type: T.FETCH_SCREEN_ERROR,
  payload: { errorScreen }
})
export const fetchModalError = errorMessage => ({
  type: T.FETCH_MODAL_ERROR,
  payload: { errorMessage }
})
const fetchScreenEnd = currentScreen => ({
  type: T.FETCH_SCREEN_END,
  payload: { currentScreen }
})

const fetchPreviousScreenStart = () => ({
  type: T.FETCH_SCREEN_START
})
const fetchPreviousScreenError = errorScreen => ({
  type: T.FETCH_SCREEN_ERROR,
  payload: { errorScreen }
})
const fetchPreviousScreenEnd = currentScreen => ({
  type: T.FETCH_SCREEN_END,
  payload: { currentScreen }
})

const answerQuestionStart = () => ({
  type: T.ANSWER_QUESTION_START
})
const answerQuestionError = errorAnswer => ({
  type: T.ANSWER_QUESTION_ERROR,
  payload: { errorAnswer }
})
const answerQuestionEnd = nextScreenUniqueName => ({
  type: T.ANSWER_QUESTION_END,
  payload: { nextScreenUniqueName }
})

export const fetchScreen = (uniqueName, id) => async dispatch => {
  dispatch(fetchScreenStart())
  fetchByScreen(uniqueName, id)
    .then(res => {
      dispatch(fetchScreenEnd(res))
    })
    .catch(res => {
      if (res.error) {
        res.notifyUser ? dispatch(fetchModalError(res.msg)) : showToast('Erro, tente novamente mais tarde!')
        dispatch(fetchScreenError(res.error))
        return
      }

      dispatch(fetchScreenEnd(res))
    })
}

export const fetchPreviousScreen = (uniqueName, id) => async dispatch => {
  dispatch(fetchPreviousScreenStart())

  goToPrevious(uniqueName)
    .then(previousScreen => {
      dispatch(fetchPreviousScreenEnd(previousScreen))
      dispatch(fetchScreen(previousScreen, id))
    })
    .catch(res => {
      if (res.error) {
        showToast('Erro, tente novamente mais tarde!')
        dispatch(fetchPreviousScreenError(res.error))
        return
      }
      dispatch(fetchScreenEnd(res))
    })
}

export const answerQuestion = (question, id) => async (dispatch, getState) => {
  dispatch(answerQuestionStart())

  const response = await sendQuestionAnswer(question)
  if (response.err) {
    dispatch(loadingEnd())
    dispatch(answerQuestionError(response.err))

    return
  }
  dispatch(answerQuestionEnd(response.data))
  dispatch(fetchScreen(response.data, id))
}

const initialState = Map({
  currentScreen: undefined,
  isLoading: true,
  loadingAnswer: false,
  errorScreen: undefined
})

export const appReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    // Screen
    case T.FETCH_SCREEN_START:
      return state.set('isLoading', true)
    case T.FETCH_SCREEN_ERROR:
      return state.set('isLoading', false).set('errorScreen', payload.errorScreen)
    case T.FETCH_MODAL_ERROR:
      return state.set('isLoading', false).set('errorMessage', payload.errorMessage)
    case T.FETCH_SCREEN_END:
      return state.set('isLoading', false).set('currentScreen', payload.currentScreen)

    // Answer
    case T.ANSWER_QUESTION_START:
      return state.set('loadingAnswer', true)
    case T.ANSWER_QUESTION_ERROR:
      return state.set('loadingAnswer', false).set('errorAnswer', payload.errorAnswer)
    case T.ANSWER_QUESTION_END:
      return state.set('loadingAnswer', false).set('nextScreenUniqueName', payload.nextScreenUniqueName)

    // Loading only
    case T.LOADING_START:
      return state.set('isLoading', true)
    case T.LOADING_END:
      return state.set('isLoading', false)

    default:
      return state
  }
}
