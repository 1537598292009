import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  height: 100%;
  position: fixed;
  margin-left: 77px;
`
export const ArrowsContainer = styled.div`
  height: calc(100% - 375px);
  margin-top: 146px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const ArrowButton = styled(Button)``
export const Arrow = styled.img`
  padding: 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14));
`
