import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Overlay,
  Container,
  Header,
  Body,
  Footer,
  Title,
  Description,
  CloseModal,
  TextLink,
  SubTitle,
  GotItButton,
  ContainerTextCopy
} from './style'
import { Close } from '../../assets/svg/close'
import { ClipboardGuarantor } from '../../assets/svg/clipboard-guarantor'

const textsModal = {
  title: 'Detalhes do seu avalista:',
  subTitle:
    'Acessando esse link com a senha abaixo, seu avalista preencherá um cadastro simples e será co-responsável pelo seu financiamento',
  textForCopy: {
    title: 'Oi, tudo bom?',
    text: 'Esse é o link de acesso para você ser meu avalista de um crédito na Provi.',
    subText: 'Obrigado por me ajudar!'
  }
}

const buildMessage = guarantor => {
  if (!guarantor) return null
  const { accessToken, shortLink } = guarantor
  return `Oi, tudo bom?\n\nEsse é o link de acesso para você ser meu avalista de um crédito na Provi. Obrigado por me ajudar! 😀\n\nLink: ${shortLink}\nSenha: ${accessToken}`
}

const buildLink = link => {
  if (!link) return ''

  if (link.includes('http://')) return link.replace('http://', '')
  if (link.includes('https://')) return link.replace('https://', '')

  return link
}

export const ModalGuarantor = ({ visuals, isMobile, onDismiss, guarantor, copyMessage }) => {
  const { accessToken, shortLink, id, wasClicked } = guarantor

  return (
    <Overlay>
      <Container isMobile={isMobile}>
        <CloseModal onClick={onDismiss}>
          <Close />
        </CloseModal>
        <Header>
          <Title visuals={visuals} isMobile={isMobile}>
            {textsModal.title}
          </Title>
        </Header>
        <Body>
          <SubTitle visuals={visuals} isMobile={isMobile}>
            Nome: {guarantor.guarantorName} <br />
            CPF: {guarantor.GuarantorCPF}
          </SubTitle>

          <SubTitle visuals={visuals} isMobile={isMobile}>
            {textsModal.subTitle}
          </SubTitle>

          <ContainerTextCopy>
            <Description isMobile={isMobile}>{textsModal.textForCopy.title}</Description>
            <Description isMobile={isMobile}>{textsModal.textForCopy.text}</Description>
            <Description isMobile={isMobile}>{textsModal.textForCopy.subText}</Description>
            <br />

            <TextLink isMobile={isMobile}>Link: {buildLink(shortLink)}</TextLink>
            <TextLink isMobile={isMobile}>Senha: {accessToken}</TextLink>
          </ContainerTextCopy>
        </Body>
        <Footer isMobile={isMobile}>
          <CopyToClipboard text={buildMessage(guarantor)} onCopy={() => copyMessage(id, wasClicked)}>
            <GotItButton visuals={visuals} isMobile={isMobile}>
              Copiar Texto
              <ClipboardGuarantor />
            </GotItButton>
          </CopyToClipboard>
        </Footer>
      </Container>
    </Overlay>
  )
}
