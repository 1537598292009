import React from 'react'
const styleIcon = {
  width: '118px',
  height: '125px',
  fill: '#ffffff'
}
export const Selfie = () => (
  <svg style={styleIcon}>
    <path
      className="st0"
      d="M9.6,11.7c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8l5.6,5.6c0.3,0.3,0.6,0.4,0.9,0.4
	c0.3,0,0.6-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.8L9.6,11.7z"
    />
    <path
      className="st0"
      d="M12.6,22.4h-8c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h8c0.7,0,1.3-0.6,1.3-1.3
	C13.9,22.9,13.3,22.4,12.6,22.4z"
    />
    <path
      className="st0"
      d="M19.8,16.5c0.7,0,1.3-0.6,1.3-1.3V7.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v7.9
	C18.5,15.9,19.1,16.5,19.8,16.5z"
    />
    <path
      className="st0"
      d="M91.3,41.9c-6,0-11.5,2.1-15.8,5.6v-23c0-2.8-2.3-5.1-5.1-5.1H22.5c-2.8,0-5.1,2.3-5.1,5.1v83.5
	c0,2.8,2.3,5.1,5.1,5.1h11.6h24.7h11.5c2.8,0,5.1-2.3,5.1-5.1V86.2c4.3,3.5,9.8,5.6,15.8,5.6c13.8,0,25.1-11.2,25.1-25
	C116.4,53.1,105.1,41.9,91.3,41.9z M72.9,95.7h-5V83c0-6-3.5-11.3-8.6-13.8c2.7-2.9,4.3-6.8,4.3-11.1c0-9.1-7.4-16.5-16.5-16.5
	s-16.5,7.4-16.5,16.5c0,4.1,1.5,7.8,4,10.7c-5.5,2.3-9.4,7.8-9.4,14.1v12.8H20V33.2h52.9v16.7c-4.1,4.4-6.7,10.4-6.7,17
	c0,6.5,2.6,12.5,6.7,17V95.7z M56.6,68.2c-0.8,0.8-1.8,1.5-2.8,2.1c-2,1.1-4.3,1.8-6.8,1.8c-2.5,0-4.9-0.7-6.9-1.8
	c-1.1-0.6-2-1.3-2.9-2.2c-2.6-2.5-4.1-6-4.1-9.9c0-7.7,6.2-13.9,14-13.9c7.7,0,14,6.2,14,13.9C61,62.1,59.3,65.7,56.6,68.2z
	 M36.5,70.9c2.8,2.4,6.5,3.7,10.5,3.7c3.8,0,7.4-1.3,10.2-3.5c4.7,1.9,8.1,6.5,8.1,11.9v12.8H27.7V83C27.7,77.3,31.4,72.5,36.5,70.9
	z M20,24.5c0-1.4,1.1-2.5,2.6-2.5h47.8c1.4,0,2.6,1.1,2.6,2.5v6.3H20V24.5z M72.9,107.9c0,1.4-1.1,2.5-2.6,2.5h-7.6h-4.5H34.7h-4.5
	h-7.7c-1.4,0-2.6-1.1-2.6-2.5v-9.6h5.2h2.6h37.6h2.6h5V107.9z M91.3,89.3c-6.2,0-11.8-2.5-15.8-6.5c-0.9-0.9-1.8-1.9-2.5-3
	c-2.6-3.7-4.1-8.1-4.1-13c0-4.8,1.5-9.3,4.1-13c0.8-1.1,1.6-2.1,2.5-3c4.1-4,9.7-6.5,15.8-6.5c12.4,0,22.5,10.1,22.5,22.5
	C113.8,79.3,103.7,89.3,91.3,89.3z"
    />
    <path
      className="st0"
      d="M105.5,65.6H92.6V52.7c0-0.7-0.6-1.3-1.3-1.3S90,52,90,52.7v12.9H77.1c-0.7,0-1.3,0.6-1.3,1.3
	c0,0.7,0.6,1.3,1.3,1.3H90V81c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V68.2h12.9c0.7,0,1.3-0.6,1.3-1.3
	C106.7,66.2,106.2,65.6,105.5,65.6z"
    />
    <path
      className="st0"
      d="M47,100c-2.5,0-4.5,2-4.5,4.4c0,2.4,2,4.4,4.5,4.4c2.5,0,4.5-2,4.5-4.4C51.4,102,49.4,100,47,100z M47,106.6
	c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C49.2,105.6,48.2,106.6,47,106.6z"
    />
  </svg>
)
