import { useState, useEffect } from 'react'
import { getVisuals } from '../../utils'

export const useIntroduction = () => {
  const [visuals, setVisuals] = useState(null)

  useEffect(() => {
    window.open('http://login.provi.com.br/sanar', '_self')
    setVisuals(getVisuals('sanar'))
  }, [])

  return [visuals]
}
