import { useState, useEffect } from 'react'
import smartlook from 'smartlook-client'

import { isValidCpf, removeSpecialCharacters } from '../../utils'
import { getStatus } from '../../services/api'

export const useCpfValidator = (onAnswered, setNextEnabled, question) => {
  const [showError, setShowError] = useState(false)
  const [value, setValue] = useState(question.value || '')

  useEffect(() => {
    async function identifyUser() {
      try {
        const { CreditRequestId, UserId } = await getStatus()

        const slug = localStorage.getItem('slugPartner')
        const currentCrid = localStorage.getItem('crid')

        if (CreditRequestId && !currentCrid) {
          localStorage.setItem('crid', CreditRequestId)
        }
        localStorage.setItem('crid', CreditRequestId)

        if (UserId && CreditRequestId) {
          smartlook &&
            smartlook.identify(UserId, {
              CRID: CreditRequestId || 'Não encontrado'
            })
        }
      } catch (error) {
        console.log(error)
      }
    }

    identifyUser()

    const storedCPF = localStorage.getItem('cpf')
    let value

    if (question.value) {
      value = removeSpecialCharacters(question.value)

      setNextEnabled(true)
      setValue(value)
      onAnswered && onAnswered(value)
    } else if (storedCPF && storedCPF !== null && storedCPF !== 'null') {
      value = removeSpecialCharacters(storedCPF)

      setNextEnabled(true)
      setValue(value)
      onAnswered && onAnswered(value)
    }
  }, [])

  const onDismiss = () => {
    setShowError(false)
  }

  const onValueChange = cpf => {
    setValue(cpf)
    onAnswered(cpf)
    setNextEnabled(false)

    if (cpf.includes('_')) {
      setNextEnabled(false)
      return
    }
    if (!isValidCpf(cpf.replace(/[^\d]+/g, ''))) {
      setShowError(true)
      setNextEnabled(false)
      return
    }

    setShowError(false)
    setNextEnabled(true)
  }

  return [showError, onDismiss, onValueChange, value]
}
