import * as axios from 'axios'
import moment from 'moment'
import { getStatus } from '../services/api'

const slackURL = 'https://hooks.slack.com/services/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'

const postOnSlack = (errorBlock, type) => {
  let data = {
    username: 'ErrorBot Uploads',
    icon_emoji: ':robot_face:',
    text: `Erro na hora fazer upload para o firebase da: ${type.toUpperCase()}`,
    attachments: {
      mrkdwn_in: ['text'],
      text: errorBlock
    }
  }

  axios({
    method: 'post',
    url: slackURL,
    data: JSON.stringify(data)
  }).catch(error => console.log('ERROR WEBHOOK SLACK', error))
}

export async function slackHook(error = 'o erro nao veio na chamada', type = 'Selfie') {
  const timeStamp = moment().format()

  const { CreditRequestId, UserId } = await getStatus()

  let errorBlock = {
    mrkdwn_in: ['text'],
    color: 'black',
    text:
      '*User Information*\n' +
      ' --------------------------- \n' +
      '\n`CRID`: ' +
      CreditRequestId +
      '\n`UserID`: ' +
      UserId +
      ' \n--------------------------- \n' +
      '*Error Network Information*\n' +
      ' --------------------------- \n' +
      '\n`At`: ' +
      timeStamp +
      '\n`Error`: ' +
      error,
    thumb_url: 'http://placekitten.com/g/200/200'
  }

  postOnSlack(errorBlock, type)
}
