import React, { useState, useEffect } from 'react'
import { ConnectedRouter } from 'connected-react-router/immutable'
import firebase from './services/firebase'
import { Route, Redirect, Switch } from 'react-router'
import { ToastComponent } from './components/toast'
import { Register } from './screens/register'
import { Login } from './screens/login'
import { IntroductionScreen } from './screens/introduction'
import { CreditApproved } from './screens/credit-approved'
import { CreditDenied } from './screens/credit-denied'
import { Contract } from './screens/contract'
import { BankSlip } from './screens/bank-slip'
import { BankSlipPaid } from './screens/bank-slip-paid'
import { NotFound } from './screens/not-found'
import { ProtectedRoute } from './components/protected-route'
import { Restart } from './screens/restart-credit-request'
import { ListCreditRequest } from './screens/list-credit-request'
import { ClosedSubscribes } from './components/closed-subscribes'
import './index.css'
import { ThemeProvider } from 'styled-components'
import theme from './theme'

export const useAuth = () => {
  const [isAuth, setIsAuth] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const info = urlParams.get('info')
    if (info) localStorage.setItem('info', info)

    firebase.auth().onAuthStateChanged(user => {
      setIsAuth(user)
      setIsLoading(false)
    })
  }, [])

  return [isAuth, isLoading]
}

const App = ({ history }) => {
  const [isAuth, isLoading] = useAuth()

  return (
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/(intro|cadastro|login|aprovado|negado|contrato|assinado|sucesso)/sanar" component={ClosedSubscribes} />
          <ProtectedRoute auth={isAuth} path="/cadastro/:school" component={Register} isLoading={isLoading} />
          <ProtectedRoute auth={isAuth} path="/creditos/:school" component={ListCreditRequest} />
          <Route path="/intro/:school" component={IntroductionScreen} />
          <Route path="/login/:school" component={Login} />
          <Route path="/aprovado/:school" component={CreditApproved} />
          <Route path="/negado/:school" component={CreditDenied} />
          <Route path="/contrato/:school" component={Contract} />
          <Route path="/assinado/:school" component={BankSlip} />
          <Route path="/sucesso/:school" component={BankSlipPaid} />
          <ProtectedRoute auth={isAuth} path="/reiniciar/:school" component={Restart} isLoading={isLoading} />

          <Route path="/404" component={NotFound} />
          <Redirect to="/404" exact />
        </Switch>
        <ToastComponent />
      </ConnectedRouter>
    </ThemeProvider>
  )
}

export default App
