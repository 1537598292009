import React from 'react'
import {
  View,
  Column,
  Description,
  DescriptionContainer,
  PreviewImage,
  ProgressBarContainer,
  ProgressBar,
  ProgressText
} from './style'

export const ProcessingUploadBox = ({ file, progress, text }) => (
  <View>
    <Column>
      <PreviewImage src={file} />
      <ProgressText>{(progress * 100).toFixed(1)}%</ProgressText>
    </Column>
    <DescriptionContainer>
      <Description>{text}</Description>
      <ProgressBarContainer>
        <ProgressBar progress={progress} />
      </ProgressBarContainer>
    </DescriptionContainer>
  </View>
)
