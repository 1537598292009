import { useState, useEffect } from 'react'

import { getVisuals, setLocalstorage } from '../../utils'
import { getRespectiveRedirectURL, getPartnerRedirectURL } from '../introduction/hooks'

export const useLoginForm = props => {
  const [page, setPage] = useState('social')
  const [tab, setTab] = useState(0)
  const [visuals, setVisuals] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [slugPartner, setSlugPartner] = useState('')

  useEffect(() => {
    setIsLoading(true)
    const partnerSlug = props.match.params.school
    const urlParams = new URLSearchParams(window.location.search)

    if (partnerSlug === 'sanarmed') {
      window.location.href = getRespectiveRedirectURL(urlParams.get('info'))
      return
    }
    if (partnerSlug === 'impacta') {
      window.location.href = getPartnerRedirectURL('impacta')
      return
    }
    if (partnerSlug === 'gama') {
      window.location.href = getPartnerRedirectURL('gama')
      return
    }

    localStorage.removeItem('crid')
    const slug = props.match.params.school

    const cpf = urlParams.get('cpf')

    setLocalstorage(cpf, 'cpf')

    setVisuals(getVisuals(slug))
    setSlugPartner(slug)

    if (props.location && props.location.pathname.includes('/cadastro')) {
      props.history.push(`/login/${slug}`)
    }

    // ANALYTICS
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: window.location.pathname,
      pageTitle: `Login - ${props.match.params.school}`
    })
    setIsLoading(false)
  }, [props.history, props.location, props.match.params.school])

  const changeTab = (event, newValue) => {
    setTab(newValue)
  }

  const redirectTo = page => {
    setPage(page)
  }

  const onBack = () => {
    if (page === 'signup') {
      setPage('social')
    }
    if (page === 'help') {
      setPage('signup')
    }
    if (page === 'recoverPassword') {
      setPage('signup')
    }
    if (page === 'validationEmail') {
      setPage('signup')
    }
  }
  return [changeTab, tab, redirectTo, page, onBack, setPage, visuals, isLoading, slugPartner]
}
