import styled from 'styled-components'

export const View = styled.div`
  position: fixed;
  height: 93px;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
`
export const StartSpace = styled.div`
  max-width: 215px;
  width: 18%;
`
export const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const PartsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 33px;
  margin-right: 33px;
`
export const OffererContainer = styled.div``
export const ProgressBarContainer = styled.div`
  width: 220px;
  height: 6px;
  border-color: #09b5dd;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ visuals }) => visuals.bgColor};
`
export const ProgressBar = styled.div`
  width: ${({ percentual }) => percentual * 100}%;
  height: 100%;
  background: ${({ visuals }) => visuals.bgColor};
  border-radius: 2px;
  transition: width 0.5s;
`
export const OfferedBy = styled.p`
  font-size: 15px;
  line-height: 20px;
  text-align: left;
`
export const ProgressText = styled.p`
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 6px;
`
export const PartnerLogo = styled.img`
  width: ${({ visuals }) => (visuals.logoSize ? visuals.logoSize : '75px')};
`
export const Line = styled.img`
  margin-left: 15px;
  margin-right: 20px;
`
export const OffererLogo = styled.img`
  width: 100px;
  margin-right: 10px;
`
