import React from 'react'
const styleIcon = {
  width: '100px',
  height: '110px',
  fill: '#ffffff'
}
export const SchoolRecords = () => (
  <svg style={styleIcon}>
    <path
      className="st0"
      d="M73.9,26V9.8c0-3.9-3.2-7.2-7.2-7.2h-56c-3.9,0-7.2,3.2-7.2,7.2v82.7c0,3.9,3.2,7.2,7.2,7.2H54
		c0.4,0,0.8-0.2,1.1-0.5l6.6-6.6l1.1-1.1l10.6-10.6c0.3-0.3,0.4-0.7,0.4-1.1v-4C87,75.1,97.5,64.2,97.5,50.9S87,26.6,73.9,26z
		 M62.9,87.1l-2.1,2.1l-1.3,1.3l-2.1,2.1l-1.9,1.9v-1.9v-2.1v-3.7v-2.1v-3.4h13.1L62.9,87.1z M70.8,78.2h-8.1H54
		c-0.5,0-1,0.3-1.3,0.7c-0.2,0.2-0.3,0.5-0.3,0.9v4.9v2.1v3.7v2.1v4H12.2c-3.1,0-5.6-2.5-5.6-5.6V11.3c0-3.1,2.5-5.6,5.6-5.6h53
		c3.1,0,5.6,2.5,5.6,5.6V26c-7.3,0.5-13.8,4.3-18,9.8h-0.3v-9.1h1.6c0.5,0,0.9-0.3,1-0.7c0.2-0.4,0-0.9-0.4-1.2L41.3,14.2
		c-0.4-0.3-1-0.3-1.3,0L26.4,24.9c-0.4,0.3-0.5,0.7-0.3,1.2c0.2,0.4,0.6,0.7,1,0.7h1.6v9.1h-1.2c-0.6,0-1.1,0.5-1.1,1.1v2h-0.7
		c-0.6,0-1.1,0.5-1.1,1.1v3.6c0,0.3,0.1,0.6,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3h22.9c-0.5,2-0.8,4-0.8,6.2c0,2.2,0.3,4.3,0.8,6.4
		c0.2,0.7,0.4,1.4,0.7,2.1c0.5,1.3,1.1,2.5,1.7,3.7c0.4,0.7,0.9,1.4,1.3,2.1c1.6,2.2,3.5,4.2,5.6,5.7c1.5,1.1,3.1,2,4.8,2.8
		c2.5,1.1,5.2,1.7,8,2L70.8,78.2L70.8,78.2z M50.8,39H28.5v-1h22.9C51.2,38.3,51,38.7,50.8,39z M49.3,42.5H26.8v-1.4h0.6
		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h22.1C49.6,41.6,49.4,42,49.3,42.5z M40.7,24.6c1.7,0,3-1.4,3-3c0-1.7-1.4-3-3.1-3
		s-3.1,1.4-3.1,3c0,1.7,1.4,3,3,3H30.2l10.4-8.2L51,24.6H40.7z M50.4,26.8v9.1H50v-9.1H50.4z M47.8,26.8v9.1h-1.5v-9.1H47.8z
		 M44.1,26.8v9.1h-0.4v-9.1H44.1z M41.6,26.8v9.1h-1.9v-9.1H41.6z M40.6,22.5c-0.5,0-1-0.4-1-0.9c0-0.5,0.4-0.9,1-0.9
		c0.5,0,1,0.4,1,0.9S41.1,22.5,40.6,22.5z M37.5,26.8v9.1h-0.4v-9.1H37.5z M34.9,26.8v9.1h-1.5v-9.1H34.9z M31.2,26.8v9.1h-0.4v-9.1
		H31.2z M73.9,73.2c-0.4,0-0.8,0-1.2,0c-0.6,0-1.2,0-1.8-0.1c-6.1-0.5-11.6-3.5-15.3-8c-0.6-0.7-1.1-1.4-1.6-2.1
		c-0.8-1.2-1.4-2.4-2-3.7c-0.3-0.7-0.5-1.4-0.7-2.1c-0.6-2-0.9-4.2-0.9-6.4c0-2.1,0.3-4.2,0.9-6.2c0.2-0.7,0.5-1.5,0.7-2.2
		c0.2-0.5,0.4-0.9,0.6-1.4c0.7-1.3,1.4-2.6,2.3-3.8c3.7-4.9,9.5-8.3,16-8.8c0.6-0.1,1.2-0.1,1.8-0.1c0.4,0,0.8,0,1.2,0
		c11.8,0.6,21.1,10.4,21.1,22.4C95.1,62.8,85.7,72.6,73.9,73.2z"
    />
    <path
      className="st0"
      d="M87.8,49.5H74V35.7c0-0.1,0-0.2,0-0.2c-0.1-0.6-0.7-1.1-1.3-1.1c-0.7,0-1.3,0.6-1.3,1.3v13.8h-0.4h-0.2H57.6
		c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h13.2h0.2h0.4V66c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.2-0.5,1.3-1.1c0-0.1,0-0.2,0-0.2
		V52.2h13.8c0.7,0,1.3-0.6,1.3-1.3C89.1,50.1,88.5,49.5,87.8,49.5z"
    />
    <path
      className="st0"
      d="M42.3,54H15.2c-0.6,0-1.1,0.5-1.1,1.1v4.3c0,0.6,0.5,1.1,1.1,1.1h27.1c0.6,0,1.1-0.5,1.1-1.1v-4.3
		C43.5,54.5,43,54,42.3,54z M41.2,58.3H16.4v-2h24.8V58.3z"
    />
    <path
      className="st0"
      d="M42.3,63.8H15.2c-0.6,0-1.1,0.5-1.1,1.1v4.3c0,0.6,0.5,1.1,1.1,1.1h27.1c0.6,0,1.1-0.5,1.1-1.1v-4.3
		C43.5,64.3,43,63.8,42.3,63.8z M41.2,68.1H16.4v-2h24.8V68.1z"
    />
    <path
      className="st0"
      d="M42.3,73.6H15.2c-0.6,0-1.1,0.5-1.1,1.1v4.3c0,0.6,0.5,1.1,1.1,1.1h27.1c0.6,0,1.1-0.5,1.1-1.1v-4.3
		C43.5,74.1,43,73.6,42.3,73.6z M41.2,77.9H16.4v-2h24.8V77.9z"
    />
    <path
      className="st0"
      d="M42.3,83.4H15.2c-0.6,0-1.1,0.5-1.1,1.1v4.3c0,0.6,0.5,1.1,1.1,1.1h27.1c0.6,0,1.1-0.5,1.1-1.1v-4.3
		C43.5,84,43,83.4,42.3,83.4z M41.2,87.7H16.4v-2h24.8V87.7z"
    />
  </svg>
)
