import React from 'react'
import { useDropzone } from 'react-dropzone'
import { View, Information, Column, DescriptionContainer, Description, WrapperCheck, NumberOfUploads } from './style'

import { Selfie } from '../../assets/svg/selfie'
import { ProofAddress } from '../../assets/svg/proof_address'
import { SchoolRecords } from '../../assets/svg/school_records'
import { ProofEnrollment } from '../../assets/svg/proof_enrollment'
import { Document } from '../../assets/svg/document'
import { Check } from '../../assets/svg/check'

const MAX_SIZE = 26214400

const renderIcon = type => {
  switch (type) {
    case 'selfie':
      return <Selfie />
    case 'address':
      return <ProofAddress />
    case 'document':
      return <Document />
    case 'proof_enrollment':
      return <ProofEnrollment />
    case 'school_records':
      return <SchoolRecords />
    default:
      break
  }
}

export const PendingUploadBox = ({ onDrop, isMobile, text, type, question, numberOfUploads, visuals }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: MAX_SIZE })
  return (
    <>
      <div style={{ position: 'relative', cursor: 'pointer' }} {...getRootProps()}>
        {type !== 'selfie' &&
          (question.value &&
            question.value.length > 0 && (
              <WrapperCheck>
                <Check size={24} fill={visuals.successColor} />
              </WrapperCheck>
            ))}

        {type === 'selfie' &&
          question.value && (
            <WrapperCheck>
              <Check size={24} fill={visuals.successColor} />
            </WrapperCheck>
          )}

        <input {...getInputProps()} />
        <View isMobile={isMobile} isDragActive={isDragActive}>
          <Column>
            {renderIcon(type)}
            <Information>
              Arraste e solte<br />
              ou faça upload
            </Information>
          </Column>
          <DescriptionContainer>
            <Description>{text}</Description>
          </DescriptionContainer>
        </View>
      </div>
      <NumberOfUploads>
        {numberOfUploads > 0 && `Você enviou ${numberOfUploads} ${numberOfUploads > 1 ? 'arquivos' : 'arquivo'}`}
      </NumberOfUploads>
    </>
  )
}
