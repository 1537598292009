import { useState, useEffect } from 'react'
import { find } from 'lodash'

const options = [
  {
    id: 1,
    item: true,
    value: 'SIM'
  },
  {
    id: 2,
    item: false,
    value: 'NÃO'
  }
]

export const useOptionsFunding = (question, onAnswered, setNextEnabled) => {
  const [value, setValue] = useState()
  const [selectedOption, setSelectedOption] = useState()

  const initialState = find(options, { item: question.value })

  useEffect(() => {
    if (question.explanation !== null && question.value !== null) {
      onAnswered && onAnswered(question.value)
      if (question.value) {
        onSelectOption(initialState)
        onValueChange(question.explanation)
        return
      } else {
        onSelectOption(initialState)
        return
      }
    }
    if (question.value === false) {
      onSelectOption(initialState)
    }
  }, [])

  const onSelectOption = option => {
    setSelectedOption(option)
    if (!option.item) {
      onAnswered({ externalLoan: false })
      setNextEnabled(true)
    } else {
      setNextEnabled(false)
    }
  }

  const onValueChange = which => {
    setValue(which)
    onAnswered(which)
    if (which.length > 0) {
      setNextEnabled(true)
      onAnswered({ externalLoan: true, externalLoanExplanation: which })
      return
    }
    setNextEnabled(false)
  }

  return [selectedOption, onSelectOption, onValueChange, value, options]
}
