import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const OptionsList = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: row;
`
export const Wrapper = styled(Button)`
  width: 100%;
  max-width: 470px;
  border: 2.5px solid white;
  margin: 4px 8px;
  padding: 25px 12px;
  border-radius: 3px;
  position: relative;
`

export const WrapperText = styled.div`
  text-align: left;
  padding: 15px;
`

export const Text = styled.p`
  color: ${({ visuals }) => visuals.questionColor};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
`
