import bgImpacta from '../assets/images/bg-impacta.png'
import gamaLogo from '../assets/images/gama-partner-logo.svg'
import digitalHouse from '../assets/images/digitalhouse-partner-logo.svg'
import bg_digitalHouse from '../assets/images/digitalhouse-bg.svg'
import sanarLogo from '../assets/images/sanar-logo.png'

import bg_approved_impacta from '../assets/images/bg-approved-impacta.png'
import bg_approved_sanarup from '../assets/images/bg-approved-sanarup.png'
import bg_approved_gama from '../assets/images/bg-approved-gama.png'

import { isString } from 'lodash'

export function currencyParser(value, prefix = '', isAbsolute) {
  let stringValue = new String(value) // eslint-disable-line
  let integers = []
  let decimals = ''

  const sign = /-.+/.test(stringValue) ? '-' : ''

  stringValue = stringValue
    .replace(/-/g, '')
    .replace(/,/g, '.')
    .replace(prefix, '')

  if (isAbsolute) {
    const absoluteValues = stringValue.replace(/[^\d]/g, '')
    decimals = absoluteValues.substr(-2, 2)

    integers = placeDots(absoluteValues.slice(0, -2))

    return `${`${prefix + sign + integers},${decimals}`}`
  }

  const splitedValues = stringValue.split('.')

  decimals = splitedValues.length > 1 ? splitedValues.pop() : '00'
  const integerValues = splitedValues.join('')

  integers = placeDots(integerValues)

  if (decimals.length < 2) {
    decimals = `${decimals}0`
  } else if (decimals.length > 2) {
    decimals = decimals.substring(0, 2)
  }

  return `${prefix + sign + integers},${decimals}`
}

export function placeDots(string) {
  let iteration = -2
  const arrayWithDots = []

  for (let i = string.length - 1; i > -1; i -= 1) {
    if (iteration % 3 === 0 && i !== 0) {
      arrayWithDots.push(`.${string[i]}`)
    } else {
      arrayWithDots.push(string[i])
    }

    iteration += 1
  }

  return arrayWithDots.reverse().join('')
}

export function getVisuals(partner) {
  switch (partner) {
    case 'impacta':
      return {
        partnerName: 'Impacta',
        successColor: '#008000',
        logoSize: '125px',
        loginTextColor: 'Black',
        loginButton: '#2A55EF',
        bgColor: '#7B7B7B',
        loginWrapperColor: '#FFFFFF',
        primaryColor: 'black',
        fontFamily: 'Roboto',
        answerColor: 'white',
        questionColor: 'white',
        background: 'black',
        backgroundImage: true,
        buttonTextColor: 'white',
        buttonBgColor: 'transparent',
        creditApprovedBgImg: `url(${bg_approved_impacta}) no-repeat center / cover`,
        PartnerId: 1,
        partnerLogoURL: `https://firebasestorage.googleapis.com/v0/b/provi-dev.appspot.com/o/logo_impacta.png?alt=media&token=546d6bc2-64d2-43d6-9e9a-fa938464aab7`
      }
    case 'sanar':
      return {
        partnerName: 'Sanar',
        successColor: '#008000',
        loginTextColor: 'black',
        logoSize: '145px',
        loginButton: 'rgb(244, 80, 30)',
        loginWrapperColor: '#FFFFFF',
        bgColor: '#ff442b',
        background: '#ff442b',
        primaryColor: '#ff442b',
        buttonTextColor: '#ffffff',
        buttonBgColor: 'transparent',
        backgroundImage: false,
        answerColor: '#fff',
        questionColor: '#fff',
        fontFamily: "'Roboto', sans-serif",
        creditApprovedBgImg: `url(${bg_approved_sanarup}) no-repeat center / cover`,
        partnerLogoURL: sanarLogo
      }
    case 'sanarmed':
      return {
        partnerName: 'Sanar Med',
        successColor: '#50f036',
        logoSize: '200px',
        loginTextColor: 'black',
        loginButton: '#019972',
        loginWrapperColor: '#FFFFFF',
        bgColor: '#019972',
        background: 'linear-gradient(90deg, #009971 0%, #045390 100%)',
        primaryColor: '#019972',
        buttonTextColor: '#ffffff',
        buttonBgColor: 'transparent',
        creditApprovedBgImg: 'linear-gradient(90deg, #009971 0%, #045390 100%)',
        backgroundImage: false,
        answerColor: '#fff',
        questionColor: '#fff',
        fontFamily: "'Roboto', sans-serif",
        partnerLogoURL: `https://firebasestorage.googleapis.com/v0/b/provi-public/o/RESMED_SANAR.png?alt=media&token=5c0962ce-6714-4e96-bb4f-ef55ed5e05d9`
      }
    case 'gama':
      return {
        partnerName: 'Gama Academy',
        successColor: '#008000',
        logoSize: '180px',
        loginTextColor: 'black',
        loginButton: '#63DC3E',
        bgColor: '#7B7B7B',
        loginWrapperColor: '#FFFFFF',
        background: '#731AB7',
        primaryColor: '#731AB7',
        buttonTextColor: '#ffffff',
        buttonBgColor: 'transparent',
        creditApprovedBgImg: `url(${bg_approved_gama}) no-repeat center / cover`,
        backgroundImage: false,
        answerColor: '#fff',
        questionColor: '#fff',
        fontFamily: "'Roboto', sans-serif",
        partnerLogoURL: gamaLogo
      }
    case 'digitalhouse':
      return {
        partnerName: 'Digital House',
        successColor: '#008000',
        logoSize: '180px',
        loginTextColor: 'black',
        loginButton: '#D31540',
        bgColor: '#7B7B7B',
        loginWrapperColor: '#FFFFFF',
        primaryColor: '#1F1939',
        buttonTextColor: '#ffffff',
        buttonBgColor: 'transparent',
        background: `url(${bg_digitalHouse}) no-repeat center / cover`,
        creditApprovedBgImg: `url(${bg_digitalHouse}) no-repeat center / cover`,
        backgroundImage: true,
        answerColor: '#fff',
        questionColor: '#fff',
        fontFamily: "'Roboto', sans-serif",
        partnerLogoURL: digitalHouse
      }
    default:
      break
  }
}

export const validateFullName = fullName => {
  let result = true

  const names = fullName.split(' ')
  const firstName = names[0]
  const lastName = names.filter((_name, index) => index > 0)

  if (!firstName) result = false
  if (lastName.length === 0) result = false

  lastName.forEach(name => (name.trim().length === 0 && name !== '' ? (result = false) : null))
  return result
}

export const isValidCpf = strCPF => {
  let sum = 0,
    remainder,
    i
  if (strCPF === '00000000000') return false

  for (i = 1; i <= 9; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  remainder = (sum * 10) % 11

  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(strCPF.substring(9, 10))) return false

  sum = 0
  for (i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  remainder = (sum * 10) % 11

  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(strCPF.substring(10, 11))) return false
  return true
}

export const removeSpecialCharacters = element => {
  if (!isString(element)) return element

  const newElement = element.replace(/[^\d]+/g, '')
  return newElement
}

export const setLocalstorage = (storage, name) => {
  if (storage && name) localStorage.setItem(name, removeSpecialCharacters(storage))
}
