import React from 'react'

import { HelmetWrapper } from '../../components/helmet'
import { Loading } from '../../components/loading'
import { ArrowLeft } from '../../assets/svg/back-arrow'
import { useScreenMeasure } from '../register/hooks'

import RecoverPassword from './recover-password'
import Help from './help'
import AuthSocial from './auth-social'
import Signup from './signup'
import ValidationEmail from './validation-email'
import { useLoginForm } from './hooks'
import { Background, Container, ImageLogo, Wrapper, Header, WrapperIcon } from './style'

import provi_logo from '../../assets/images/provi-logo-login.png'

export const LoginView = props => {
  const [isMobile] = useScreenMeasure()
  const [changeTab, tab, redirectTo, page, onBack, setPage, visuals, isLoading, slugPartner] = useLoginForm(props)

  return (
    <>
      <HelmetWrapper partner={slugPartner} />
      {isLoading ? (
        <Loading visuals={visuals} />
      ) : (
        <>
          <Background visuals={visuals} />
          <Container visuals={visuals}>
            <Wrapper
              isMobile={isMobile}
              visuals={{ ...visuals, justifyContent: page === 'social' ? 'space-between' : 'flex-start' }}
            >
              {(page === 'help' || page === 'recoverPassword' || page === 'signup' || page === 'validationEmail') && (
                <Header>
                  <WrapperIcon onClick={onBack}>
                    <ArrowLeft />
                  </WrapperIcon>
                </Header>
              )}
              <ImageLogo style={{ width: `${visuals.logoSize}` }} src={visuals.partnerLogoURL} />

              {page === 'help' && <Help />}
              {page === 'recoverPassword' && <RecoverPassword visuals={visuals} />}
              {page === 'social' && (
                <AuthSocial visuals={visuals} match={props.match} history={props.history} redirectTo={redirectTo} />
              )}
              {page === 'signup' && (
                <Signup
                  visuals={visuals}
                  match={props.match}
                  history={props.history}
                  setPage={setPage}
                  redirectTo={redirectTo}
                  changeTab={changeTab}
                  tab={tab}
                />
              )}
              {page === 'validationEmail' && <ValidationEmail visuals={visuals} match={props.match} history={props.history} />}
              {page === 'social' && (
                <>
                  <ImageLogo style={{ width: '168px' }} src={provi_logo} />
                </>
              )}
            </Wrapper>
          </Container>
        </>
      )}
    </>
  )
}
