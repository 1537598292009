import styled from 'styled-components'
import InputMask from 'react-input-mask'
import CurrencyInput from 'react-currency-input'
import TextareaAutosize from 'react-autosize-textarea'
import { Link } from 'react-router-dom'

export const RouterLink = styled(Link)`
  text-decoration: none;
`
export const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`
export const Input = styled(InputMask)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
`
export const MoneyInput = styled(CurrencyInput)`
  background: none !important;
  color: inherit !important;
  border: none !important;
  padding: 0 !important;
  font: inherit !important;
  outline: inherit !important;
`
export const TextArea = styled(TextareaAutosize)`
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`
