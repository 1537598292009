import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { BirthDate } from '../../../components/birth-date'
import { FullName } from '../../../components/full-name'
import { Course } from '../../../components/course'
import { Installments } from '../../../components/installments'
import { Phone } from '../../../components/phone'
import { CivilState } from '../../../components/civil-state'
import { Cep } from '../../../components/cep'
import { WayToPay } from '../../../components/way-to-pay'
import { MonthlyEarns } from '../../../components/monthly-earns'
import { Profession } from '../../../components/profession'
import { RequestPermission } from '../../../components/request-permission'
import { ConfirmationCode } from '../../../components/confirmation-code'
import { ProfessionalSituation } from '../../../components/professional-situation'
import { ProfessionalSalaryWork } from '../../../components/professional-salary'
import { UploadDocuments } from '../../../components/upload-documents'
import { UploadSelfie } from '../../../components/upload-selfie'
import { UploadReceipt } from '../../../components/upload-receipt'
import { UploadSchoolRecords } from '../../../components/upload-school-records'
import { UploadProofEnrollment } from '../../../components/upload-proof-enrollment'
import { DateGraduation } from '../../../components/date-graduation'
import { IntendedWayToExpend } from '../../../components/intended-way-to-spend'
import { IntendedWayToPay } from '../../../components/intended-way-to-pay'
import { HasFunding } from '../../../components/has-funding'
import { NumberDependents } from '../../../components/number-dependents'
import { BankData } from '../../../components/bank-data'
import { Cpf } from '../../../components/cpf'
import { SelectPlan } from '../../../components/select-plan'
import { SanarGraduations } from '../../../components/sanar-graduations'
import { SelectCurse } from '../../../components/select-curse'
import { ConfirmCourseSimulation } from '../../../components/confirm-course-simulation'
import { LastCompany } from '../../../components/last-company'
import { CurrentOrDesiredIndustry } from '../../../components/current-or-desired-industry'
import { CourseIntendedIndustry } from '../../../components/course-intended-industry'
import { CourseDate } from '../../../components/course-date'
import { DataConfirmation } from '../../../components/data-confirmation'
import { CourseSimulation } from '../../../components/course-simulation'
import { IntendedPaymentDay } from '../../../components/intended-payment-day-screen'
import { CollegeStage } from '../../../components/college-stage'
import { ChoosePaymentOption } from '../../../components/choose-payment-option'
import { ConfirmPaymentFlow } from '../../../components/confirm-payment-flow'
import { DocumentsPreApproved } from '../../../components/documents-pre-approved'
import { Referrer } from '../../../components/referrer'

// GUARANTOR
import { RequestGuarantor } from '../../../components/request-guarantor'
import { NegativeFeedbackGuarantor } from '../../../components/negative-feedback-guarantor'
import { ListGuarantors } from '../../../components/list-guarantors'

/* eslint-disable react-hooks/exhaustive-deps */

const screens = {
  BirthDateScreen: props => <BirthDate {...props} />,
  AddressScreen: props => <Cep {...props} />,
  MaritalStatusScreen: props => <CivilState {...props} />,
  PartnerExplanationScreen: props => <Installments {...props} />,
  CoursesScreen: props => <Course {...props} />,
  CPFScreen: props => <Cpf {...props} />,
  SelectCurse: props => <SelectCurse {...props} />,
  ChoosePlanScreen: props => <SelectPlan {...props} />,
  FullNameScreen: props => <FullName {...props} />,
  PhoneScreen: props => <Phone {...props} />,
  IntentedWayToPayScreen: props => <WayToPay {...props} />,
  MonthlyEarnsScreen: props => <MonthlyEarns {...props} />,
  ProfessionScreen: props => <Profession {...props} />,
  RequestPermissionScreen: props => <RequestPermission {...props} />,
  ProfessionalSituationScreen: props => <ProfessionalSituation {...props} />,
  ProfessionalSalaryWorkScreen: props => <ProfessionalSalaryWork {...props} />,
  PersonalDocumentUploadScreen: props => <UploadDocuments {...props} />,
  ProofOfEnrollmentScreen: props => <UploadProofEnrollment {...props} />,
  SelfieUploadScreen: props => <UploadSelfie {...props} />,
  SchoolRecordsScreen: props => <UploadSchoolRecords {...props} />,
  ProofOfAddressUploadScreen: props => <UploadReceipt {...props} />,
  GraduationDateScreen: props => <DateGraduation {...props} />,
  WaitingPhoneConfirmationScreen: props => <ConfirmationCode {...props} />,
  IntendedWayToExpendScreen: props => <IntendedWayToExpend {...props} />,
  IntendedWayToPayScreen: props => <IntendedWayToPay {...props} />,
  ExistingExternalLoanScreen: props => <HasFunding {...props} />,
  NumberOfDependentsScreen: props => <NumberDependents {...props} />,
  BankAccountScreen: props => <BankData {...props} />,
  SanarGraduationsScreen: props => <SanarGraduations {...props} />,
  CollegeStageScreen: props => <CollegeStage {...props} />,
  ConfirmCourseSimulationScreen: props => <ConfirmCourseSimulation {...props} />,
  UserLastCompanyScreen: props => <LastCompany {...props} />,
  CurrentOrDesiredIndustryScreen: props => <CurrentOrDesiredIndustry {...props} />,
  CourseIntendedIndustryScreen: props => <CourseIntendedIndustry {...props} />,
  CourseDateScreen: props => <CourseDate {...props} />,
  DataConfirmationScreen: props => <DataConfirmation {...props} />,
  IntendedPaymentDayScreen: props => <IntendedPaymentDay {...props} />,
  InstallmentSimulationScreen: props => <CourseSimulation {...props} />,
  ChoosePaymentOptionScreen: props => <ChoosePaymentOption {...props} />,
  ConfirmPaymentFlowScreen: props => <ConfirmPaymentFlow {...props} />,
  DocumentsPreApprovedScreen: props => <DocumentsPreApproved {...props} />,
  ReferrerScreen: props => <Referrer {...props} />,
  // GUARANTOR
  RequestGuarantorScreen: props => <RequestGuarantor {...props} />,
  NegativeFeedbackGuarantorScreen: props => <NegativeFeedbackGuarantor {...props} />,
  ListGuarantorsScreen: props => <ListGuarantors {...props} />
}

export const useProvider = props => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [extraScreens, setExtraScreens] = useState(undefined)

  useEffect(() => {
    setExtraScreens(
      screens[props.question.uniqueName]({
        ...props,
        setNextEnabled: state => {
          props.setNextEnabled && props.setNextEnabled(state)
        }
      })
    )
  }, [props.question.uniqueName])

  const isMinorOfAge = dateStr => {
    if (dateStr.split('/').length !== 3) {
      return false
    }
    const date = moment(dateStr, 'DD/MM/YYYY')
    const year = date.format('YYYY')

    const age = moment().diff(year, 'years')
    return age < 18
  }

  return [extraScreens, setIsShowModal, isShowModal, isMinorOfAge]
}
