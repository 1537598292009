import React from 'react'
import { useDropzone } from 'react-dropzone'
import {
  View,
  Column,
  ProgressBarContainer,
  Description,
  DescriptionContainer,
  ProgressBar,
  PreviewImage,
  WrapperCheck
} from './style'
import { Check } from '../../assets/svg/check'

const MAX_SIZE = 26214400

export const CompleteUploadBox = ({ onDrop, file, text, progress, visuals }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: MAX_SIZE })
  return (
    <div style={{ cursor: 'pointer' }} {...getRootProps()}>
      <input {...getInputProps()} />
      <View isDragActive={isDragActive}>
        <Column>
          <PreviewImage src={file} />
          <WrapperCheck>
            <Check size={35} fill={visuals.successColor} />
          </WrapperCheck>
        </Column>
        <DescriptionContainer>
          <Description>{text}</Description>
          <ProgressBarContainer>
            <ProgressBar progress={progress} />
          </ProgressBarContainer>
        </DescriptionContainer>
      </View>
    </div>
  )
}
