import React from 'react'
import { Subscript, InputWrapper, FormInputWrapper, TextInput, FormInput, TextImputMultiline } from './style'
import { LockIcon } from '../../assets/svg/lock'

export const TextField = ({ visuals, placeholder, onValueChange, icon, disabled, ...props }) => (
  <InputWrapper>
    <TextInput
      disabled={disabled}
      visuals={visuals}
      placeholder={placeholder}
      onChange={({ target }) => onValueChange(target.value)}
      {...props}
    />
    {icon && <LockIcon />}
  </InputWrapper>
)

export const TextArea = ({ visuals, placeholder, onValueChange, ...props }) => (
  <InputWrapper>
    <TextImputMultiline
      visuals={visuals}
      placeholder={placeholder}
      onChange={({ target }) => onValueChange(target.value)}
      {...props}
    />
  </InputWrapper>
)

export const FormField = ({ visuals, placeholder, onValueChange, subscript, spacing, size, ...props }) => (
  <FormInputWrapper spacing={spacing} size={size}>
    <FormInput visuals={visuals} placeholder={placeholder} onChange={({ target }) => onValueChange(target.value)} {...props} />
    {subscript && <Subscript visuals={visuals}>{subscript}</Subscript>}
  </FormInputWrapper>
)
