import React from 'react'
import { Loading } from '../../assets/svg/loading'
import { ContainerNoConnection, Text } from './style'

export const NoConnection = ({ isMobile }) => (
  <ContainerNoConnection isMobile={isMobile}>
    <Loading />
    <Text>Você está sem internet. {!isMobile && 'Verifique a sua conexão!'}</Text>
  </ContainerNoConnection>
)
