import React from 'react'
import {
  Overlay,
  Header,
  Body,
  Footer,
  Container,
  Title,
  Description,
  MoreHelpText,
  MoreHelpLink,
  GotItButton,
  CloseModal
} from './style'
import { Close } from '../../assets/svg/close'

export const HelpModal = ({ screen, visuals, isMobile, onDismiss }) => {
  return (
    <Overlay isMobile={isMobile}>
      <Container isMobile={isMobile}>
        <Header>
          <CloseModal isMobile={isMobile} onClick={onDismiss}>
            <Close />
          </CloseModal>
          <Title visuals={visuals} isMobile={isMobile}>
            {screen.helpTitle}
          </Title>
        </Header>
        <Body isMobile={isMobile}>
          <Description>{screen.helpBody}</Description>
          <MoreHelpText>
            <MoreHelpLink visuals={visuals} href={screen.helpLink} target="_blank">
              {screen.helpLinkText}
            </MoreHelpLink>
          </MoreHelpText>
        </Body>
        <Footer>
          <GotItButton visuals={visuals} isMobile={isMobile} onClick={onDismiss}>
            {screen.textModal || 'ENTENDI'}
          </GotItButton>
        </Footer>
      </Container>
    </Overlay>
  )
}
