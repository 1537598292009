import styled, { css } from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Text = styled.p`
  font-size: 2rem;
  line-height: 48px;
  margin-bottom: 14px;
  color: ${({ visuals }) => visuals.questionColor};
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      font-size: 1.8rem;
      line-height: 2rem;
      text-align: center;
      margin-bottom: 15px;
    }
  `};
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 1.6rem;
      line-height: 1.8rem;
      text-align: center;
      margin-bottom: 15px;
    }
  `};
`

export const SubText = styled.p`
  font-size: 1.5rem;
  line-height: 1.7rem;
  text-align: justify;
  max-width: 700px;
  margin: 20px 0;
  color: ${({ visuals }) => visuals.questionColor};
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  `};
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  `};
`

export const SendButton = styled(Button)`
  width: 220px;
  height: 35px;
  color: ${({ visuals }) => visuals.questionColor};
  background: #00bed3;
  border-radius: 20px;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
`
