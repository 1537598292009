import React from 'react'
import { TextField } from '../text-field'
import { ErrorMessage } from '../error-message'
import { useGuarantorValidate } from './hooks'

export const RequestGuarantor = ({ onAnswered, question, setNextEnabled, visuals, isMobile }) => {
  const [showError, onDismiss, onValueChange, value] = useGuarantorValidate(onAnswered, question, setNextEnabled)
  return (
    <>
      <TextField
        visuals={visuals}
        placeholder={'Digite o CPF do avalista aqui'}
        onValueChange={onValueChange}
        mask="999.999.999 - 99"
        value={value}
        type="tel"
      />
      {showError && <ErrorMessage errorMessage="CPF Inválido" onDismiss={onDismiss} />}
    </>
  )
}
