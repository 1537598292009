import React from 'react'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import firebase from '../../../services/firebase'
import { getStatus } from '../../../services/api'

import {
  getFirebaseUser,
  createProviUser,
  checkIfEmailIsAvailable,
  saveTerms,
  checkForFirebaseUser
} from '../../../services/api'
import { showToast } from '../../../components/toast'
import Login from './Login'
import SignupComponent from './SignupComponent'
import { CustemTabs, CustemTab, CustemPaper } from './style'
import { useLoginForm } from './hook'
import logger from '../../../utils/logger'

const Signup = props => {
  const {
    tab,
    changeTab,
    redirectTo,
    setPage,
    history,
    match,
    visuals,
    values,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    validateForm
  } = props

  useLoginForm({ tab, values, validateForm })

  return (
    <CustemPaper>
      <CustemTabs value={tab} onChange={changeTab} indicatorColor="primary" textColor="primary">
        <CustemTab label="Criar conta" style={{ fontFamily: "'Nunito', sans-serif" }} />
        <CustemTab label="Login" style={{ fontFamily: "'Nunito', sans-serif" }} />
      </CustemTabs>
      {tab === 0 && (
        <SignupComponent
          visuals={visuals}
          setPage={setPage}
          redirectTo={redirectTo}
          tab={tab}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isValid={isValid}
          isSubmitting={isSubmitting}
        />
      )}
      {tab === 1 && (
        <Login
          visuals={visuals}
          match={match}
          history={history}
          setPage={setPage}
          redirectTo={redirectTo}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isValid={isValid}
          isSubmitting={isSubmitting}
        />
      )}
    </CustemPaper>
  )
}

const submitLogin = async (email, password, setSubmitting, props) => {
  const partner = props.match.params.school
  try {
    localStorage.removeItem('crid')
    await firebase.auth().signInWithEmailAndPassword(email, password)
    const user = await getFirebaseUser()
    await saveTerms(partner)

    if (user.providerData[0].providerId === 'password' && !user.emailVerified) {
      props.setPage('validationEmail')
      return
    }

    const { PartnerId, cpf } = await getStatus()

    if (partner === 'impacta' && PartnerId && cpf) {
      props.history.push(`/creditos/${partner}`)
      setSubmitting(false)
      return
    }

    props.history.push(`/cadastro/${partner}`)

    setSubmitting(false)
  } catch (error) {
    setSubmitting(false)

    if (error.code === 'auth/user-not-found') {
      showToast('Email ou senha inválidos')
      return
    }
    if (error.code === 'auth/wrong-password') {
      const { hasFirebaseUser } = await checkForFirebaseUser(email)

      if (hasFirebaseUser) {
        // For user that already register in the new sdk but are trying to use the old one
        showToast('Por favor coloque seu email para criar uma senha nova')
        return props.redirectTo('recoverPassword')
      }

      showToast('Senha inválida')
      return
    }
    showToast('Houve um erro, tente novamente mais tarde!')
  }
}

const submitSignUp = async (email, password, setSubmitting, props) => {
  try {
    const emailStatus = await checkIfEmailIsAvailable(email)
    const { available, provider, changePassword } = emailStatus

    if (changePassword) {
      // For user that already register in the new sdk but are trying to use the old one
      showToast('Por favor coloque seu email para criar uma senha nova')
      return props.redirectTo('recoverPassword')
    }

    if (!available) {
      const emailErrorMsg =
        provider === `Google` ? `Já existe uma conta com esse email (usando ${provider})` : 'Já existe uma conta com esse email'

      showToast(emailErrorMsg)
      setSubmitting(false)
      return false
    }

    const firebaseResponse = await firebase.auth().createUserWithEmailAndPassword(email, password)
    await createProviUser(firebaseResponse)
    await saveTerms(props.match.params.school)

    firebaseResponse.user.sendEmailVerification()

    if (props.tab === 0) props.setPage('validationEmail')

    setSubmitting(false)
  } catch (error) {
    setSubmitting(false)

    if (error.code === 'auth/email-already-in-use') {
      return showToast('Email em uso')
    }

    showToast('Houve um erro, tente novamente!')
  }
}

export default withFormik({
  mapPropsToValues: () => ({ email: '', password: '', passwordConfirmation: '' }),
  validationSchema: props => {
    const schema = {
      email: Yup.string()
        .trim()
        .email('Digite um email válido')
        .required('Preencha o campo Email'),
      password: Yup.string()
        .min(6, 'A senha deve ter no mínimo 6 caracteres.')
        .required('Preencha o campo Senha')
    }
    const passwordConfirmation = Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas devem ser idênticas')
      .required('Preencha o campo de Confirmar Senha')

    return Yup.object().shape(props.tab ? schema : { ...schema, passwordConfirmation })
  },
  handleSubmit: async ({ email, password }, { setSubmitting, props }) => {
    if (props.tab) {
      submitLogin(email.trim(), password, setSubmitting, props)
    } else {
      submitSignUp(email.trim(), password, setSubmitting, props)
    }
  }
})(Signup)
