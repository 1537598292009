import styled from 'styled-components'
import { Button as DefaultButton } from '../../globalStyles'

export const Button = styled(DefaultButton)`
  width: 60px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`
export const View = styled.div`
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${({ visuals }) => visuals.questionColor};
`
