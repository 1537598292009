import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  padding-top: 14px;
`
export const Text = styled.p`
  font-size: ${({ isMobile }) => (isMobile ? '20px' : '30px')};
  line-height: ${({ isMobile }) => (isMobile ? '27px' : '41px')};
  margin-bottom: 30px;
  margin-right: 10px;
  color: ${({ visuals }) => visuals.questionColor};
  text-align: justify;
`
export const SendButton = styled(Button)`
  width: 220px;
  height: 35px;
  color: ${({ visuals }) => visuals.questionColor};
  margin-top: 30px;
  background: #00bed3;
  border-radius: 20px;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
`
