import styled, { css } from 'styled-components'
import { Button } from '../../globalStyles'

export const View = styled.div`
  height: 192px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 2px dashed white;
  opacity: ${({ isDragActive }) => (isDragActive ? '0.8' : '1.0')};
  border-radius: 12px;
  color: white;
  &:focus {
    outline: 0;
  }
`
export const Row = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  position: relative;
  &:focus {
    outline: 0;
  }
  svg {
    width: 120px;
    height: 122px;
    fill: #fff;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      svg {
        width: 120px;
        height: 122px;
      }
    }
  `};
`

export const CheckContainer = styled.div`
  position: absolute;
  margin-left: 36px;
  padding-top: 1px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 13px;
  background: #128b81;
`
export const Description = styled.p`
  font-size: 25px;
  line-height: 30px;
`
export const DescriptionContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`
export const Information = styled.p`
  font-size: 16px;
  line-height: 22px;
`
export const InformationContainer = styled.div`
  height: 80px;
  padding-left: 40px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
`
export const PreviewPlaceholder = styled.span`
  position: absolute;
  width: 136px;
  height: 136px;
  font-size: 20px;
  line-height: 136px;
  text-align: center;
`
export const PreviewImage = styled.img`
  width: 136px;
  height: 136px;
  background: #c4c4c4;
`
export const PreviewContainer = styled.div`
  padding-top: 18px;
  padding-right: 26px;
`
export const PreviewFooter = styled.div`
  display: flex;
  justify-content: space-around;
`
export const PreviewButton = styled(Button)`
  text-decoration: underline;
`
export const ProgressContainer = styled.div`
  width: calc(100% - 68px);
  height: 18px;
  display: flex;
  justify-content: center;
  margin-left: 34px;
  margin-bottom: 10px;
  border-color: #e6e6e6;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
`
export const ProgressBarContainer = styled.div`
  width: 80%;
  height: 15px;
  background-color: white;
  border-radius: 30px;
  margin-top: 10px;
`
export const ProgressBar = styled.div`
  width: ${({ progress }) => progress * 100}%;
  height: 100%;
  background: #50f036;
  border-radius: 30px;
`
export const ProgressText = styled.p`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  font-size: 25px;
  line-height: 18px;
  color: white;
  font-weight: 600;
  margin-bottom: 1px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.6);
`
export const UploadCompleteContainer = styled.div`
  position: absolute;
  height: 166px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 40px;
`

export const WrapperCheck = styled.div`
  right: 10px;
  bottom: 10px;
  color: green;
  position: absolute;
`

export const NumberOfUploads = styled.p`
  color: white;
  font-size: 18px;
  text-align: right;
  margin-top: 5px;
`
