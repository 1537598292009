import { useState, useEffect } from 'react'
import { find } from 'lodash'

const referrers = [
  {
    label: 'Google',
    value: 'google'
  },
  {
    label: 'Embaixadores',
    value: 'embaixadores'
  },
  {
    label: 'Ex-Alunos',
    value: 'ex-alunos'
  },
  {
    label: 'Instagram',
    value: 'instagram'
  },
  {
    label: 'Youtube',
    value: 'youtube'
  },
  {
    label: 'Outros',
    value: 'outros'
  }
]

export const useReferrerPicker = (onAnswered, setNextEnabled, question) => {
  const initialState = find(referrers, { value: question.value.referrer })
  const [selectedReferrer, setSelectedReferrer] = useState(initialState)
  const [value, setValue] = useState(question.value.coupon)

  useEffect(() => {
    if (question.value) {
      onAnswered &&
        onAnswered({
          referrer: question.value.referrer,
          coupon: question.value.coupon
        })
      setNextEnabled(true)
      return
    }

    setNextEnabled(false)
  }, [])

  const onValueChangeReferrer = item => {
    setSelectedReferrer(item)
    if (question.value.coupon) {
      onAnswered({ referrer: item.value, coupon: question.value.coupon })
      return
    }
    setNextEnabled(false)
    if (item.value !== 'embaixadores' || item.value !== 'ex-alunos') {
      setNextEnabled(true)
      onAnswered({
        referrer: item.value,
        coupon: null
      })
    }
  }

  const onValueChangeInput = which => {
    setValue(which)
    onAnswered(which)
    if (which.length > 1) {
      setNextEnabled(true)
      onAnswered({ referrer: selectedReferrer.value, coupon: which })
      return
    }
    setNextEnabled(false)
  }

  return [onValueChangeReferrer, referrers, selectedReferrer, onValueChangeInput, value]
}
