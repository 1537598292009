import React from 'react'
import Helmet from 'react-helmet'

import impacta from '../../assets/favicon/impacta/impacta.ico'
import sanar from '../../assets/favicon/sanar/favicon.ico'
import sanarmed from '../../assets/favicon/sanarmed/favicon.png'

const getTitle = partner => {
  let partnerData = {}
  switch (partner) {
    case 'impacta':
      partnerData.title = '[Provi x IMPACTA] Prepare-se para a fase mais importante de todo estudante'
      partnerData.icon = impacta
      return partnerData
    case 'sanar':
      partnerData.title = '[Provi x SANAR] Prepare-se para a fase mais importante de todo estudante de medicina'
      partnerData.icon = sanar
      return partnerData
    case 'gama':
      partnerData.title = '[Provi x GAMA] Prepare-se para a Gama Experience!'
      partnerData.icon =
        'https://firebasestorage.googleapis.com/v0/b/provi-public/o/email_assets%2Fgama%2Fgama-favicon.png?alt=media&token=be316dbe-0e70-45d9-bd22-21861e63c4e3'
      return partnerData
    case 'digitalhouse':
      partnerData.title = '[Provi x DIGITAL HOUSE] Prepare-se para a fase mais importante da sua vida!'
      return partnerData
    case 'sanarmed':
      partnerData.title = '[Provi x SANARMED] Sanar Residência Médica - Cursos Preparatórios para Residência Médica'
      partnerData.icon = sanarmed
      return partnerData
    default:
      return '[Provi]'
  }
}

export const HelmetWrapper = ({ partner }) => {
  const partnerData = getTitle(partner)
  return (
    <Helmet>
      <title>{partnerData.title}</title>
    </Helmet>
  )
}
