import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  text-align: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${({ background }) => background};
  background-size: cover;
  background-position: ${({ isMobile }) => (isMobile ? '65%' : 'center')};
`
export const Wrapper = styled.div`
  height: calc(100% - 64px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: calc(100% - 93px);
    }
  `};
`
