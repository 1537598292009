import { useState, useEffect } from 'react'
import { getPartnerId } from '../../services/api'

export const GetPartnerName = ({ school }) => {
  const [partnerName, setPartnerName] = useState('')

  useEffect(() => {
    async function getPartner() {
      const partner = await getPartnerId(school)
      if (partner.name) {
        setPartnerName(partner.name)
      }
    }

    getPartner()
  }, [])

  return [partnerName]
}
