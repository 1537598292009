import React, { useState } from 'react'
import { ContainerCapsule, Left, Right, TextRight, WrapperCapsule, LinkChangeGuarantor, DeleteGuarantor } from './style'
import { useCapsuleGuarantor } from './hooks'
import { ModalQuestionAlert } from '../modals'

const renderTextStatus = (status, wasclicked) => {
  if (wasclicked) {
    switch (status) {
      case 'pending':
        return 'Clique aqui para enviar'
      case 'approved':
        return 'Avalista aprovado'
      case 'analysis':
        return 'Em análise'
      case 'review' || 'incomplete':
        return 'Ainda não preencheu'

      default:
        return 'Ainda não preencheu'
    }
  }
  return 'Clique aqui para enviar'
}

const renderColorStatus = (status, wasclicked) => {
  if (wasclicked) {
    switch (status) {
      case 'pending':
        return '#00A6D2;'
      case 'approved':
        return '#00C808'
      case 'analysis':
        return '#FFB803'
      case 'review' || 'incomplete':
        return '#C80000'

      default:
        return '#C80000'
    }
  }
  return '#00A6D2;'
}

export const CapsuleGuarantor = ({
  openModal,
  margin,
  isMobile,
  visuals,
  item,
  index,
  guarantors,
  setIsShowmodalNewGuarantor,
  selectGuarantor,
  setGuarantors,
  setIsNewGuarantor,
  setIsLoading,
  isEditable
}) => {
  const [modalState, setModalState] = useState(false)
  const [deleteCpf] = useCapsuleGuarantor(setGuarantors, setIsLoading)
  return (
    <WrapperCapsule>
      {modalState && (
        <ModalQuestionAlert
          title="Você deseja excluir seu avalista?"
          visuals={visuals}
          isMobile={isMobile}
          deleteFunc={deleteCpf}
          item={item.id}
          setModalState={setModalState}
          questionButtons
        />
      )}
      <ContainerCapsule onClick={openModal} margin={margin} isMobile={isMobile}>
        <Left>
          <div>#{(item.guarantorName && item.guarantorName.replace(/ .*/, '')) || `Avalista ${index + 1}`}</div>
        </Left>
        <Right color={renderColorStatus(item.status, item.wasClicked)}>
          <TextRight>Status: {renderTextStatus(item.status, item.wasClicked)}</TextRight>
        </Right>
      </ContainerCapsule>
      {isEditable && guarantors.length > 1 && item.canEditOrDelete && !item.singleGuarantor && (
        <>
          <LinkChangeGuarantor
            onClick={() => {
              setIsShowmodalNewGuarantor(true)
              selectGuarantor(item)
              setIsNewGuarantor(true)
            }}
          >
            Troque o #{(item.guarantorName && item.guarantorName.replace(/ .*/, '')) || `Avalista ${index + 1}`}
          </LinkChangeGuarantor>
          <DeleteGuarantor onClick={() => setModalState(true)} />
        </>
      )}
    </WrapperCapsule>
  )
}
