import React from 'react'
import { UploadBox } from '../upload-box'
import { useFileUpload } from './hooks'
import { View } from './style'

const OPTIONS_DROPZONE = {
  accept: 'image/jpeg, image/png'
}

export const UploadSelfie = props => {
  const { isMobile, setNextEnabled, onAnswered, question, visuals } = props
  const [file, onDrop, status, progress] = useFileUpload(onAnswered, setNextEnabled, question)
  return (
    <View>
      <UploadBox
        question={question}
        type="selfie"
        text="Enviar um arquivo"
        file={file}
        progress={progress}
        status={status}
        isMobile={isMobile}
        onDrop={onDrop}
        options={OPTIONS_DROPZONE}
        visuals={visuals}
      />
    </View>
  )
}
