import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const ToastComponent = () => {
  return (
    <ToastContainer
      containerId={'TOAST'}
      position="bottom-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover={false}
    />
  )
}

export const showToast = text => {
  toast.dismiss()

  if (text) {
    return toast(text || 'Erro, por favor tente mais tarde', {
      position: 'bottom-center',
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    })
  }
}
