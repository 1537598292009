import React from 'react'
import { View } from './style'
import { Selector } from '../selector'
import { useCourseDatePicker } from './hooks'

export const CourseDate = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, courseDates, selectedCourseDate] = useCourseDatePicker(onAnswered, setNextEnabled, question)
  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={courseDates}
        value={selectedCourseDate}
        onValueChange={onValueChange}
      />
    </View>
  )
}
