import { useState, useEffect } from 'react'
import { getVisuals } from '../../utils'
import { SCREENS } from '../../constants'
import firebase from '../../services/firebase'
import logger from '../../utils/logger'
import { getPartnerId, fetchByScreen } from '../../services/api'
import bg_approved_impacta from '../../assets/images/bg-approved-impacta.png'
import bg_approved_gama from '../../assets/images/bg-approved-gama.png'
import bg_approved_sanarup from '../../assets/images/bg-approved-sanarup.png'
import bg_digitalHouse from '../../assets/images/digitalhouse-bg.svg'
import { showToast } from '../../components/toast'

const TEXTS = [
  'Mas lembre-se, para garantir sua vaga você ainda precisa',
  'Assinar eletronicamente o contrato que foi enviado no seu email.',
  'Após a assinatura, pague o primeiro boleto que será enviado no seu email.',
  'Depois é só baixar o app da Provi e acompanhar os seus boletos dentro da nossa plataforma. Simples assim 😀',
  'Precisa de ajuda com o seu contrato ou primeiro boleto? É só falar com a gente pelo email papo@provi.com.br ou pelo WhatsApp +55 (11) 95771-2414'
]

const TITLE = 'Seu crédito foi aprovado! 🙌🏻'

export const useCreditApproved = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [dataWasFetched, setDataWasFetched] = useState(false)
  const [schoolSelect, setSchoolSelect] = useState({})
  const [visuals, setVisuals] = useState(null)
  const [slugPartner, setSlugPartner] = useState()

  const sanar = {
    title: 'Sua Mesada UP foi aprovada! 🙌🏻',
    texts: [
      'Mas lembre-se, para garantir o recebimento da sua mesada você ainda precisa',
      'Assinar eletronicamente o contrato que foi enviado no seu email.',
      false,
      'Depois é só baixar o app da Provi e acompanhar o recebimento da sua mesada dentro da nossa plataforma. Simples assim 😀',
      'Precisa de ajuda com alguma coisa? É só falar com a gente pelo email papo@provi.com.br ou pelo WhatsApp +55 (11) 95771-2414'
    ],
    background: `url(${bg_approved_sanarup}) no-repeat center / cover`
  }

  const impacta = {
    title: TITLE,
    texts: TEXTS,
    background: `url(${bg_approved_impacta}) no-repeat center / cover`
  }

  const gama = {
    title: TITLE,
    texts: TEXTS,
    background: `url(${bg_approved_gama}) no-repeat center / cover`
  }

  const digitalhouse = {
    title: TITLE,
    texts: TEXTS,
    background: `url(${bg_digitalHouse}) no-repeat center / cover`
  }

  const sanarmed = {
    title: TITLE,
    texts: TEXTS,
    background: 'linear-gradient(90deg, #009971 0%, #045390 100%)',
    redirectURL: 'https://www.sanarmed.com/residenciamedica'
  }

  useEffect(() => {
    const slug = props.match.params.school

    async function fetchData() {
      setIsLoading(true)
      try {
        const { id } = await getPartnerId(slug)
        await fetchByScreen(SCREENS.CreditApprovedScreen, id)
      } catch (error) {
        showToast('Error, tente novamente!')
      }
      setIsLoading(false)
    }
    if (dataWasFetched === false) {
      setDataWasFetched(true)
      fetchData()
    }

    setSlugPartner(slug)
    const APPROVED = 'CreditApproved'
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(
      {
        event: 'Pageview',
        pagePath: `${window.location.pathname}/${APPROVED}`,
        pageTitle: `${APPROVED} - ${slug}`
      },
      {
        event: 'GAEvent',
        eventCategory: 'Screen View',
        eventAction: APPROVED,
        eventLabel: `${slug}`
      }
    )

    switch (slug) {
      case 'sanar':
        setSchoolSelect(sanar)
        break
      case 'gama':
        setSchoolSelect(gama)
        break
      case 'impacta':
        setSchoolSelect(impacta)
        break
      case 'digitalhouse':
        setSchoolSelect(digitalhouse)
        break
      case 'sanarmed':
        setSchoolSelect(sanarmed)
        break
      default:
        break
    }

    setVisuals(getVisuals(slug))

    setIsLoading(false)
  }, [])

  return [isLoading, schoolSelect, visuals, slugPartner]
}
