import React from 'react'

import { HelmetWrapper } from '../../components/helmet'
import { ScreenImageBackground } from '../../components/screen-image-background'
import { Loading } from '../../components/loading'
import { NextButton } from '../../components/next-button'
import { useScreenMeasure } from '../register/hooks'
import { Title, Text, BodyContainer, Wrapper, List, ListItem } from './style'
import { useCreditApproved } from './hooks'

export const CreditApproved = props => {
  const [isMobile] = useScreenMeasure()
  const [isLoading, schoolSelect, visuals, slugPartner] = useCreditApproved(props)

  return (
    <ScreenImageBackground visuals={visuals} background={schoolSelect.background} justifyContent="flex-start">
      <HelmetWrapper partner={slugPartner} />
      {isLoading ? (
        <Loading visuals={visuals} />
      ) : (
        <BodyContainer>
          <Wrapper>
            <Title isMobile={isMobile}>{schoolSelect.title}</Title>

            {schoolSelect.text && <Text isMobile={isMobile}>{schoolSelect.text}</Text>}
            {schoolSelect.texts &&
              schoolSelect.texts.length && (
                <>
                  <Text>{schoolSelect.texts[0]}</Text>
                  <List>
                    {schoolSelect.texts[1] && <ListItem>{schoolSelect.texts[1]}</ListItem>}
                    {schoolSelect.texts[2] && <ListItem>{schoolSelect.texts[2]}</ListItem>}
                  </List>
                  <Text>{schoolSelect.texts[4]}</Text>
                </>
              )}

            {visuals &&
              slugPartner !== 'sanar' && (
                <NextButton
                  isMobile={isMobile}
                  enabled={true}
                  visuals={visuals}
                  title="Assinar contrato"
                  onClick={() => props.history.push(`/contrato/${slugPartner}`)}
                />
              )}
          </Wrapper>
        </BodyContainer>
      )}
    </ScreenImageBackground>
  )
}
