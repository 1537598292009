import { useState, useEffect } from 'react'
import { getVisuals } from '../../utils'
import { getPartnerId, fetchByScreen, getStatusContract, getContract } from '../../services/api'
import bg_approved_impacta from '../../assets/images/bg-approved-impacta.png'
import bg_approved_gama from '../../assets/images/bg-approved-gama.png'
import bg_approved_sanarup from '../../assets/images/bg-approved-sanarup.png'
import bg_digitalHouse from '../../assets/images/digitalhouse-bg.svg'
import { showToast } from '../../components/toast'

let interval

export const useContract = props => {
  const [schoolSelect, setSchoolSelect] = useState({})
  const [visuals, setVisuals] = useState(null)
  const [slugPartner, setSlugPartner] = useState()
  const [screen, setScreen] = useState()

  const [counter, setCounter] = useState(60)
  const [redirectUrl, setRedirectUrl] = useState()

  const sanar = {
    background: `url(${bg_approved_sanarup}) no-repeat center / cover`
  }

  const impacta = {
    background: `url(${bg_approved_impacta}) no-repeat center / cover`
  }

  const gama = {
    background: `url(${bg_approved_gama}) no-repeat center / cover`
  }

  const digitalhouse = {
    background: `url(${bg_digitalHouse}) no-repeat center / cover`
  }

  const sanarmed = {
    background: 'linear-gradient(90deg, #009971 0%, #045390 100%)'
  }

  useEffect(() => {
    let intervalTimer = null
    if (counter > 0) {
      intervalTimer = setInterval(() => {
        setCounter(counter => counter - 1)
      }, 1000)
    } else if (counter === 0) {
      clearInterval(intervalTimer)
      if (!redirectUrl) {
        throw Error('Error contract docsign')
      }
    }

    return () => clearInterval(intervalTimer)
  }, [counter])

  useEffect(() => {
    const slug = props.match.params.school
    async function fetchData() {
      try {
        const { id } = await getPartnerId(slug)
        const data = await fetchByScreen('DocusignScreen', id)

        setScreen(data.nextStep)
      } catch (error) {
        showToast('Erro, tente novamente!')
      }
    }
    fetchData()

    setSlugPartner(slug)
    const DOCUSIGN = 'Docusign'
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(
      {
        event: 'Pageview',
        pagePath: `${window.location.pathname}/${DOCUSIGN}`,
        pageTitle: `${DOCUSIGN} - ${slug}`
      },
      {
        event: 'GAEvent',
        eventCategory: 'Screen View',
        eventAction: DOCUSIGN,
        eventLabel: `${slug}`
      }
    )

    switch (slug) {
      case 'sanar':
        setSchoolSelect(sanar)
        break
      case 'gama':
        setSchoolSelect(gama)
        break
      case 'impacta':
        setSchoolSelect(impacta)
        break
      case 'sanarmed':
        setSchoolSelect(sanarmed)
        break
      case 'digitalhouse':
        setSchoolSelect(digitalhouse)
        break
      default:
        break
    }

    setVisuals(getVisuals(slug))
  }, [])

  useEffect(() => {
    let intervalContract = setInterval(async () => {
      const crid = localStorage.getItem('crid')
      const { data } = await getContract(crid)
      if (data && data.data && data.data.redirectUrl && data.data.EnvelopeId) {
        setRedirectUrl(data.data.redirectUrl)
        clearInterval(intervalContract)
      }
    }, 5000)
  }, [])

  useEffect(() => {
    const slug = props.match.params.school
    if (redirectUrl) {
      let intervalStatusContract = setInterval(async () => {
        const { data } = await getStatusContract()
        if (data.signed) {
          props.history.push(`/assinado/${slug}`)
        }
      }, 2000)
      return () => clearInterval(intervalStatusContract)
    }
  }, [redirectUrl])

  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])

  return [schoolSelect, visuals, slugPartner, screen, counter, redirectUrl]
}
