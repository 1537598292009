import styled from 'styled-components'
import { MoneyInput } from '../../globalStyles'

export const View = styled.div`
  padding-top: 14px;
`
export const CurrencyInput = styled(MoneyInput)`
  color: ${({ visuals }) => visuals.questionColor} !important;
  font-size: 25px !important;
  line-height: 34px !important;
  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
  :-ms-input-placeholder {
    opacity: 0.5;
    color: ${({ visuals }) => visuals.questionColor};
  }
`
