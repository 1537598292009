import React from 'react'
import { View } from './style'
import { Selector } from '../selector'
import { useCourseIntendedIndustry } from './hooks'

export const CourseIntendedIndustry = ({ setNextEnabled, onAnswered, question, visuals }) => {
  const [onValueChange, options, currentCourseIntendedIndustry] = useCourseIntendedIndustry(
    onAnswered,
    setNextEnabled,
    question
  )

  return (
    <View>
      <Selector
        placeholder="Selecione aqui"
        visuals={visuals}
        options={options}
        value={currentCourseIntendedIndustry}
        onValueChange={onValueChange}
      />
    </View>
  )
}
