import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const OptionsList = styled.div`
  padding-top: 18px;
  display: flex;
  flex-direction: row;
`
export const Option = styled(Button)`
  border: ${({ visuals, isSelected }) => (isSelected ? '2.5px solid white' : `1px solid ${visuals.questionColor}`)};
  background: ${({ visuals, isSelected }) => (isSelected ? 'rgba(250,250,250,0.3)' : 'transparent')};
  margin: 4px 8px;
  padding: 12px;
  border-radius: 3px;
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.6')};
`
export const OptionText = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-style: normal;
  font-size: 18px;
  line-height: 20px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  color: ${({ visuals }) => visuals.questionColor};
`
export const OptionDay = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  font-style: normal;
  font-size: 30px;
  line-height: 30px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : '600')};
  color: ${({ visuals }) => visuals.questionColor};
`
