import styled, { keyframes } from 'styled-components'

export const CloseModal = styled.div`
  position: absolute;
  right: 0px;
  top: 36px;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
`

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  display: flex;
  -webkit-animation-name: ${opacity}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${opacity};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

const scale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

export const Container = styled.div`
  position: absolute;
  max-width: 700px;
  height: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  padding: ${({ isMobile }) => (isMobile ? '24px 12px' : '30px')};
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '80px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '250px')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-around' : 'space-between')};
  -webkit-animation-name: ${scale}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${scale};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

export const Header = styled.div`
  position: relative;
  min-width: 90%;
  margin-top: 12px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 35px 0;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.p`
  font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem')};
  line-height: ${({ isMobile }) => (isMobile ? '35px' : '48px')};
  font-weight: 600;
  padding: 40px 24px 0 0;
  color: #010000;
`

export const Text = styled.div`
  font-size: 20px;
  color: #010000;
  cursor: pointer;
  ${({ underline }) => underline && 'text-decoration: underline;'};
`

export const LinkText = styled.a`
  font-size: 20px;
  color: #010000;
  cursor: pointer;
  ${({ underline }) => underline && 'text-decoration: underline;'};
`
