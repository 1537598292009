import React from 'react'
import { View, Text, SendButton, SubText } from './style'

export const TextConfirmation = ({ textButton, onSendClick, isMobile, visuals, text, subText, bullets }) => {
  return (
    <View>
      <Text isMobile={isMobile} visuals={visuals}>
        {text}
      </Text>
      {subText && <SubText visuals={visuals}>{subText}</SubText>}
      {bullets && bullets.map(item => <SubText visuals={visuals}>{item}</SubText>)}
      {textButton && (
        <SendButton visuals={visuals} isMobile={isMobile} onClick={onSendClick}>
          {textButton}
        </SendButton>
      )}
    </View>
  )
}
