import { useState, useEffect } from 'react'
import { find } from 'lodash'

export const useProfessionalSituationPicker = (onAnswered, setNextEnabled, question) => {
  const profissionalSituations = question.options.map(item => {
    return {
      label: item.name,
      value: item.name,
      id: item.id
    }
  })

  const initialState = profissionalSituations.filter(item => question.value === item.id)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onValueChange(initialState)
      onAnswered && onAnswered(initialState[0].id)
    }
  }, [])

  const [selectedProfissionalSituation, setSelectedProfissionalSituation] = useState(initialState)

  const onValueChange = profissional => {
    setNextEnabled(true)
    setSelectedProfissionalSituation(profissional)
    onAnswered(profissional.id)
  }

  return [onValueChange, profissionalSituations, selectedProfissionalSituation]
}
