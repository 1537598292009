import styled, { css } from 'styled-components'

export const ContainerCapsule = styled.div`
  ${({ isMobile }) => isMobile && 'margin-top: 10px'};
  ${props => props.margin && !props.isMobile && 'margin-left: 10px;'};
  width: 280px;
  height: 90px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      height: 65px;
    }
  `};
`

export const Left = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60%;
  background-color: white;
  color: #666666;
  border-radius: 90px 0 0 90px;
`

export const Right = styled.div`
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40%;
  background-color: ${({ color }) => color};
  border-radius: 0 90px 90px 0;
`

export const TextRight = styled.p`
  font-size: 12px;
  padding: 10px;
`

export const WrapperCapsule = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5px;
`

export const LinkChangeGuarantor = styled.div`
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: white;
  margin-top: 5px;
  text-decoration: underline;
`
export const DeleteGuarantor = styled.div`
  cursor: pointer;
  background: white;
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  &:after {
    content: '';
    width: 2px;
    height: 15px;
    background-color: #ff531b;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:before {
    content: '';
    width: 2px;
    height: 15px;
    background-color: #ff531b;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
