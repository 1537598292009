import { useState, useEffect } from 'react'
import { getVisuals } from '../../utils'
import { getPartnerId, fetchByScreen, getStatusInstallment } from '../../services/api'
import bg_approved_impacta from '../../assets/images/bg-approved-impacta.png'
import bg_approved_gama from '../../assets/images/bg-approved-gama.png'
import bg_approved_sanarup from '../../assets/images/bg-approved-sanarup.png'
import bg_digitalHouse from '../../assets/images/digitalhouse-bg.svg'
import { showToast } from '../../components/toast'

let interval

export const useBankSlip = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [schoolSelect, setSchoolSelect] = useState({})
  const [visuals, setVisuals] = useState(null)
  const [slugPartner, setSlugPartner] = useState()
  const [screen, setScreen] = useState()

  const sanar = {
    background: `url(${bg_approved_sanarup}) no-repeat center / cover`
  }

  const impacta = {
    background: `url(${bg_approved_impacta}) no-repeat center / cover`
  }

  const gama = {
    background: `url(${bg_approved_gama}) no-repeat center / cover`
  }

  const digitalhouse = {
    background: `url(${bg_digitalHouse}) no-repeat center / cover`
  }

  const sanarmed = {
    background: 'linear-gradient(90deg, #009971 0%, #045390 100%)'
  }

  useEffect(() => {
    const slug = props.match.params.school
    async function fetchData() {
      try {
        const { id } = await getPartnerId(slug)
        const data = await fetchByScreen('BankslipScreen', id)
        setScreen(data.nextStep)
      } catch (error) {
        showToast('Erro, tente novamente!')
      }
      setIsLoading(false)
    }
    fetchData()

    interval = setInterval(async () => {
      const { statusInstallment } = await getStatusInstallment()
      if (statusInstallment === 'paid') {
        props.history.push(`/sucesso/${slug}`)
      }
    }, 2000)

    setSlugPartner(slug)
    const BANKSLIP = 'BankSlip'
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(
      {
        event: 'Pageview',
        pagePath: `${window.location.pathname}/${BANKSLIP}`,
        pageTitle: `${BANKSLIP} - ${slug}`
      },
      {
        event: 'GAEvent',
        eventCategory: 'Screen View',
        eventAction: BANKSLIP,
        eventLabel: `${slug}`
      }
    )

    switch (slug) {
      case 'sanar':
        setSchoolSelect(sanar)
        break
      case 'gama':
        setSchoolSelect(gama)
        break
      case 'impacta':
        setSchoolSelect(impacta)
        break
      case 'sanarmed':
        setSchoolSelect(sanarmed)
        break
      case 'digitalhouse':
        setSchoolSelect(digitalhouse)
        break
      default:
        break
    }

    setVisuals(getVisuals(slug))
  }, [])

  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [])

  return [isLoading, schoolSelect, visuals, slugPartner, screen]
}
