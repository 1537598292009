import { useState, useEffect } from 'react'

export const useCurseSelect = (onAnswered, setNextEnabled, question) => {
  const cursesOptions = question.options.map(item => {
    return {
      label: item.name,
      value: item.name,
      id: item.id
    }
  })

  const initialState = cursesOptions.filter(item => question.value === item.id)

  useEffect(() => {
    if (question.value) {
      setNextEnabled(true)
      onSelectOption(initialState[0])
      onAnswered && onAnswered(initialState[0].id)
    }
  }, [])

  const [selectedOption, setSelectedCurse] = useState(question.value || {})

  const onSelectOption = curse => {
    setNextEnabled(true)
    setSelectedCurse(curse)
    onAnswered(curse.id)
  }

  return [selectedOption, onSelectOption, cursesOptions]
}
