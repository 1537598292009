import React from 'react'

const styleIcon = {
  width: '100px',
  height: '110px',
  fill: '#ffffff'
}

export const ProofEnrollment = () => (
  <svg style={styleIcon}>
    <path
      className="st0"
      d="M16.3,25.1h45c0.7,0,1.3-0.6,1.3-1.3v-8c0-0.7-0.6-1.3-1.3-1.3h-45c-0.7,0-1.3,0.6-1.3,1.3v8
	C15,24.5,15.6,25.1,16.3,25.1z M17.6,17.1H60v5.3H17.6V17.1z"
    />
    <path
      className="st0"
      d="M46.8,39.8c0-0.7-0.6-1.3-1.3-1.3H16.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h29.1
	C46.2,41.1,46.8,40.5,46.8,39.8z"
    />
    <path
      className="st0"
      d="M40.1,49.1H16.3c-0.7,0-1.3,0.6-1.3,1.3v8c0,0.7,0.6,1.3,1.3,1.3h23.8c0.7,0,1.3-0.6,1.3-1.3v-8
	C41.5,49.7,40.9,49.1,40.1,49.1z M38.8,57.1H17.6v-5.3h21.2V57.1z"
    />
    <path
      className="st0"
      d="M30.9,62.4H16.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h14.6c0.7,0,1.3-0.6,1.3-1.3
	C32.2,63,31.6,62.4,30.9,62.4z"
    />
    <path
      className="st0"
      d="M22.9,67.8h-6.6c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h6.6c0.7,0,1.3-0.6,1.3-1.3S23.7,67.8,22.9,67.8z"
    />
    <path
      className="st0"
      d="M72.6,25.1V9.6c0-4.1-3.3-7.4-7.4-7.4H12.4C8.3,2.2,5,5.5,5,9.6v81.2c0,4.1,3.3,7.4,7.4,7.4h40.3
	c0.4,0,0.8-0.2,1.1-0.5l18.4-18.6c0.3-0.3,0.4-0.7,0.4-1.1v-2.8C85.7,74.4,96,63.5,96,50.2S85.7,25.9,72.6,25.1z M54.2,92.9V79.6
	h13.2L54.2,92.9z M69.6,76.5H52.7c-0.8,0-1.5,0.7-1.5,1.5v17H13.9c-3.2,0-5.8-2.6-5.8-5.8V11.1c0-3.2,2.6-5.8,5.8-5.8h49.8
	c3.2,0,5.8,2.6,5.8,5.8v14c-3.5,0.2-6.8,1.1-9.8,2.7H16.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h39.3c-1,0.8-2,1.7-2.9,2.7H16.3
	c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h34.3c-0.6,0.9-1.2,1.8-1.7,2.7c-0.3,0.5-0.6,1.1-0.8,1.7c-1.3,3.1-2.1,6.4-2.1,10
	c0,13.4,10.4,24.3,23.5,25.1L69.6,76.5L69.6,76.5z M72.6,72.8c-0.5,0-1.1,0.1-1.6,0.1c-0.5,0-1,0-1.5-0.1c-11.7-0.8-21-10.6-21-22.6
	c0-3.2,0.7-6.3,1.9-9.1c0.3-0.8,0.7-1.5,1.2-2.3c0.6-1.1,1.3-2.1,2.1-3c0.8-1,1.7-1.9,2.6-2.7c1.2-1,2.4-1.9,3.8-2.7
	c0.8-0.5,1.7-0.9,2.5-1.2c2.2-0.9,4.5-1.4,6.9-1.6c0.5,0,1-0.1,1.5-0.1c0.5,0,1.1,0,1.6,0.1c11.6,0.8,20.9,10.6,20.9,22.6
	C93.5,62.1,84.3,71.9,72.6,72.8z"
    />
    <path
      className="st0"
      d="M86.2,48.8H72.7h-0.2h-0.2V34.9c0-0.8-0.6-1.4-1.3-1.4c-0.7,0-1.3,0.6-1.3,1.4v13.9h0h-0.2H55.9
	c-0.7,0-1.3,0.6-1.3,1.4c0,0.8,0.6,1.4,1.3,1.4h13.6h0.2h0v13.9c0,0.8,0.6,1.4,1.3,1.4c0.7,0,1.3-0.6,1.3-1.4V51.5h0.2h0.2h13.5
	c0.7,0,1.3-0.6,1.3-1.4C87.6,49.4,87,48.8,86.2,48.8z"
    />"white" />
  </svg>
)
