import styled, { keyframes, css } from 'styled-components'
import { Button } from '../../globalStyles'

export const CloseModal = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  ${({ theme }) =>
    css`
      ${theme.breakpoints.down('md')} {
        right: 10px;
        top: 10px;
      }
    `};
`
const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  -webkit-animation-name: ${opacity}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${opacity};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

const scale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

export const Container = styled.div`
  position: absolute;
  max-width: 700px;
  height: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  padding: ${({ isMobile }) => (isMobile ? '24px 8px' : '30px')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-around' : 'space-between')};
  -webkit-animation-name: ${scale}; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-name: ${scale};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  ${({ theme }) =>
    css`
      ${theme.breakpoints.down('md')} {
      }
    `};
`

export const Header = styled.div`
  position: relative;
`
export const Body = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${({ theme }) =>
    css`
      ${theme.breakpoints.down('xs')} {
        padding-top: 10px;
      }
    `};
`
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

export const Title = styled.p`
  color: ${({ visuals }) => visuals.bgColor};
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: center;
  ${({ theme }) =>
    css`
      ${theme.breakpoints.down('xs')} {
        font-size: 1.4rem;
      }
    `};
`

export const SubTitle = styled.div`
  font-size: 1rem;
  width: ${({ isMobile }) => (isMobile ? '100%' : '480px')};
  text-align: ${({ isMobile }) => (isMobile ? 'justify' : 'left')};
  padding-bottom: 10px;
`

export const Description = styled.div`
  font-size: 1rem;
  line-height: 25px;
  text-align: justify;
`

export const TextLink = styled.div``

export const GotItButton = styled(Button)`
  width: 200px;
  height: 58px;
  color: ${({ visuals }) => visuals.questionColor};
  background: ${({ visuals }) => visuals.bgColor};
  border-radius: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 15px;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : 'initial')};
  cursor: pointer;

  display: flex;
  justify-content: space-around;
  align-items: center;
  &:hover {
    opacity: 0.6;
  }
`

export const ContainerTextCopy = styled.div`
  border: 1px solid rgba(167, 167, 167, 0.4);
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0;
  ${({ theme }) =>
    css`
      ${theme.breakpoints.down('xs')} {
        margin: 10px 0;
      }
    `};
`
