import styled, { css } from 'styled-components'

export const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ isMobile }) => (isMobile ? 'calc(100vh - 70px)' : 'calc(100vh - 100px)')};
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      justify-content: flex-start;
    }
  `};
`
export const TitleContainer = styled.div`
  display: flex;
  width: 60%;
  ${({ theme }) => css`
    ${theme.breakpoints.down('lg')} {
      width: 90%;
    }
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `};
`
export const Title = styled.p`
  font-size: 35px;
  line-height: 48px;
  margin-bottom: 14px;
  color: ${({ visuals }) => visuals.questionColor};
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin-bottom: 15px;
      div {
        display: flex;
        align-items: center;
        line-height: 1.6rem;
        font-size: 1.4rem;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          font-weight: bold;
          font-size: 1.4rem;
          border-right: 2px solid #fff;
          padding: 10px 10px 10px 0;
          margin-right: 10px;
        }
      }
    }
    ${theme.breakpoints.down('xs')} {
      div {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  `};
`
export const Hint = styled.p`
  font-size: 1rem;
  text-align: justify;
  line-height: 20px;
  width: 60%;
  color: ${({ visuals }) => visuals.questionColor};
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  `};
`
export const BodyContainer = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      height: auto;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
  `};
`

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: flex;
      justify-content: flex-end;
    }
  `};
`
