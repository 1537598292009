import React from 'react'
import arrowUp from '../../assets/images/arrow_up.png'
import arrowDown from '../../assets/images/arrow_down.png'
import { View, Arrow, ArrowButton, ArrowsContainer } from './style'

export const Slider = ({ nextEnabled, onSlideUp, onSlideDown }) => {
  return (
    <View>
      <ArrowsContainer>
        <ArrowButton onClick={onSlideUp}>
          <Arrow src={arrowUp} />
        </ArrowButton>
        <ArrowButton disabled={!nextEnabled} onClick={onSlideDown}>
          <Arrow src={arrowDown} />
        </ArrowButton>
      </ArrowsContainer>
    </View>
  )
}
