import { useEffect, useState } from 'react'
import moment from 'moment'
import { getPartnerId, goToNext, getStatus, goToPrevious, logout, getStatusInstallment } from '../../services/api'

import { disableBodyScroll } from 'body-scroll-lock'
import { showToast } from '../../components/toast'
import { BASIC_INFO_STATUS, GUARANTOR_STATUS, DOCUMENT_STATUS, STATUS, SCREENS, KEYS } from '../../constants'

import { getVisuals, setLocalstorage } from '../../utils'

/* eslint-disable react-hooks/exhaustive-deps */

export const useSliderControl = (props, setShowHelpModal) => {
  const [IsCreditApprovedScreen, setIsCreditApprovedScreen] = useState()
  const [netInfo, setNetInfo] = useState(false)
  const [schoolSelected, setSchoolSelected] = useState()
  const [stateSlugPartner, setStateSlugPartner] = useState()
  const [firstScreen, setFirstScreen] = useState()
  const [countGuarantor, setCountGuarantor] = useState(0)
  const [screens, setScreens] = useState({ current: undefined })
  const [nextEnabled, setNextEnabled] = useState(false)
  const [isCreditAnalysisScreen, setIsCreditAnalysisScreen] = useState(false)
  const [isListIncompleteGuarantorsScreen, setIsListIncompleteGuarantorsScreen] = useState(false)
  const [isTermsScreen, setIsTermsScreen] = useState(false)
  const [isShowModalAlert, setIsShowModalAlert] = useState(false)
  const [userCPF, setCPF] = useState()

  useEffect(() => {
    const slugPartner = props.match.params.school
    const urlParams = new URLSearchParams(window.location.search)
    const cpf = urlParams.get('cpf')

    window.addEventListener('offline', function(e) {
      setNetInfo(false)
    })

    window.addEventListener('online', function(e) {
      setNetInfo(true)
    })

    setLocalstorage(cpf, 'cpf')

    async function fetchData() {
      try {
        setNetInfo(navigator && navigator.onLine)
        setStateSlugPartner(slugPartner)
        localStorage.setItem('slugPartner', slugPartner)
        const { id } = await getPartnerId(slugPartner)

        localStorage.setItem('PartnerId', id)
        const { nextScreen } = await goToNext()

        const crid = localStorage.getItem('crid')

        setFirstScreen(nextScreen)
        setSchoolSelected(id || localStorage.getItem('id'))

        const { status, basicInfoStatus, guarantorStatus, documentStatus, CreditRequestId, cpf } = await getStatus()
        const { statusInstallment } = await getStatusInstallment()

        if (!crid && CreditRequestId) {
          localStorage.setItem('crid', CreditRequestId)
        }

        cpf ? setCPF(true) : setCPF(false)

        if (status === STATUS.analysis) {
          props.fetchScreen(SCREENS.CreditAnalysisScreen, id)
          return
        } else if (status === STATUS.approved || status === STATUS.waiting_contract) {
          props.history.push(`/aprovado/${slugPartner}`, id)
          return
        } else if (status === STATUS.waiting_signature) {
          props.history.push(`/contrato/${slugPartner}`, id)
          return
        } else if (status === STATUS.signed) {
          if (statusInstallment === 'paid') {
            props.history.push(`/sucesso/${slugPartner}`, id)
            return
          }
          props.history.push(`/assinado/${slugPartner}`, id)
          return
        } else if (status === STATUS.denied) {
          props.history.push(`/negado/${slugPartner}`)
          return
        } else if (status === STATUS.settled || status === STATUS.transferred) {
          if (slugPartner === 'sanar') {
            props.history.push(`/sucesso/${slugPartner}`, id)
          }
          return
        } else if (status === STATUS.canceled) {
          props.history.push(`/reiniciar/${slugPartner}`, id)
        } else if (status === STATUS.incomplete && basicInfoStatus === BASIC_INFO_STATUS.analysis) {
          if (guarantorStatus === GUARANTOR_STATUS.needed) {
            props.fetchScreen(SCREENS.RequestGuarantorScreen, id)
            return
          } else if (guarantorStatus === GUARANTOR_STATUS.review) {
            props.fetchScreen(SCREENS.ListGuarantorsScreen, id)
            return
          } else if (documentStatus === DOCUMENT_STATUS.approved) {
            props.fetchScreen(SCREENS.DocumentsPreApprovedScreen, id)
            return
          } else if (documentStatus === DOCUMENT_STATUS.incomplete || documentStatus === DOCUMENT_STATUS.review) {
            props.fetchScreen(SCREENS.SelfieUploadScreen, id)
            return
          } else if (documentStatus === DOCUMENT_STATUS.analysis && guarantorStatus === GUARANTOR_STATUS.analysis) {
            props.fetchScreen(SCREENS.CreditAnalysisScreen, id)
            return
          } else if (guarantorStatus === GUARANTOR_STATUS.incomplete || guarantorStatus === GUARANTOR_STATUS.review) {
            props.fetchScreen(SCREENS.ListGuarantorsScreen, id)
            return
          }
        } else if (status === STATUS.incomplete && basicInfoStatus === BASIC_INFO_STATUS.approved) {
          if (guarantorStatus === GUARANTOR_STATUS.review) {
            props.fetchScreen(SCREENS.ListGuarantorsScreen, id)
            return
          } else if (guarantorStatus === GUARANTOR_STATUS.needed) {
            props.fetchScreen(SCREENS.RequestGuarantorScreen, id)
          } else if (documentStatus === DOCUMENT_STATUS.approved) {
            if (slugPartner === 'sanar') {
              props.history.push(`/aprovado/${slugPartner}`, id)
              return
            }
            props.fetchScreen(SCREENS.DocumentsPreApprovedScreen, id)
          } else if (documentStatus === DOCUMENT_STATUS.incomplete || documentStatus === DOCUMENT_STATUS.review) {
            props.fetchScreen(SCREENS.SelfieUploadScreen, id)
          } else if (documentStatus === DOCUMENT_STATUS.analysis) {
            if (
              guarantorStatus === GUARANTOR_STATUS.analysis ||
              guarantorStatus === GUARANTOR_STATUS.approved ||
              guarantorStatus === GUARANTOR_STATUS.unneeded
            ) {
              props.fetchScreen(SCREENS.CreditAnalysisScreen, id)
            } else if (guarantorStatus === GUARANTOR_STATUS.review || guarantorStatus === GUARANTOR_STATUS.incomplete) {
              props.fetchScreen(SCREENS.ListGuarantorsScreen, id)
            }
          } else {
            props.fetchScreen(SCREENS.SelfieUploadScreen, id)
          }
        } else {
          props.fetchScreen(nextScreen, id)
        }
      } catch (e) {
        showToast(e.msg)
      }
    }

    fetchData()

    disableBodyScroll()
  }, [])

  useEffect(() => {
    // ANALYTICS
    window.dataLayer = window.dataLayer || []

    if (props.currentScreen === undefined) return

    if (
      props.currentScreen &&
      props.currentScreen.nextStep &&
      props.currentScreen.nextStep.uniqueName === 'RequestGuarantorScreen'
    ) {
      setCountGuarantor(countGuarantor + 1)
    }

    const uniqueName = props.currentScreen && props.currentScreen.nextStep && props.currentScreen.nextStep.uniqueName

    window.dataLayer.push(
      {
        event: 'Pageview',
        pagePath: `${window.location.pathname}/${uniqueName}`,
        pageTitle: `${uniqueName} - ${props.match.params.school}`
      },
      { userId: localStorage.getItem('userId') }
    )
    setScreens({ current: props.currentScreen })

    setIsCreditAnalysisScreen('CreditAnalysisScreen' === uniqueName)
    setIsTermsScreen('TermsAndConditions' === uniqueName)
    setIsListIncompleteGuarantorsScreen('ListIncompleteGuarantorsScreen' === uniqueName)
    setIsCreditApprovedScreen('CreditApprovedScreen' === uniqueName)
  }, [props.currentScreen])

  // Let it here for now
  // Security problem in iOS
  // https://sentry.io/organizations/provi/issues/1366177353/?project=1772731&query=history&statsPeriod=14d
  // window.history.pushState(null, null, window.location.href)
  window.onpopstate = function() {
    window.history.go(1)
    goToPreviousScreen()
  }

  const quitApplication = () => {
    logout(stateSlugPartner, props.history)
  }

  const goToPreviousScreen = async () => {
    if (props.currentScreen.nextStep.uniqueName === firstScreen) {
      if (stateSlugPartner === 'impacta' && userCPF) {
        props.history.push(`/creditos/${stateSlugPartner}`)
      }
      setIsShowModalAlert(true)
      return
    }
    props.loadingStart()
    goToPrevious(props.currentScreen.nextStep.uniqueName)
      .then(({ previousScreen }) => {
        props.fetchScreen(previousScreen, schoolSelected)
        setScreens({ current: props.currentScreen })
      })
      .catch(error => console.error(error))
  }

  const goToNextScreen = () => {
    if (props && props.currentScreen && props.currentScreen.nextStep) {
      const { uniqueName } = props.currentScreen.nextStep
      if (uniqueName === 'BirthDateScreen' && isMinorOfAge(props.currentScreen.answer)) {
        return setShowHelpModal(true)
      }
    }
    props.loadingStart()
    setScreens({ current: props.currentScreen })
    onNextClick()
  }

  const goToScreen = screen => {
    props.loadingStart()
    const { school } = props && props.match && props.match.params
    if (props.currentScreen.nextStep.goToNextScreen === 'CreditApprovedScreen') {
      props.history.push(`/aprovado/${school}`)
    }
    props.fetchScreen(screen, schoolSelected)

    setScreens({ current: props.currentScreen })
  }

  const onAnswered = answer => {
    const currentScreen = screens.current
    currentScreen.answer = answer
    setScreens({ current: currentScreen })
  }

  const onSendClick = () => {
    props.loadingStart()
    onNextClick()
  }

  const onNextClick = () => {
    setNextEnabled(false)
    props.answerQuestion(screens.current, schoolSelected || localStorage.getItem('id'))
  }

  const onKeyDown = key => {
    if (props && props.currentScreen && props.currentScreen.nextStep) {
      const { uniqueName } = props.currentScreen.nextStep
      if (uniqueName === 'BirthDateScreen' && isMinorOfAge(props.currentScreen.answer)) {
        return setShowHelpModal(true)
      }
    }

    const isTermsScreen =
      props.currentScreen && props.currentScreen.nextStep && props.currentScreen.nextStep.uniqueName === 'TermsAndConditions'

    const isListGuarantorsScreen =
      props.currentScreen && props.currentScreen.nextStep && props.currentScreen.nextStep.uniqueName === 'ListGuarantorsScreen'

    const endpoint = props.currentScreen && props.currentScreen.nextStep && props.currentScreen.nextStep.endpoint
    const isTextArea =
      (props.currentScreen &&
        props.currentScreen.nextStep &&
        props.currentScreen.nextStep.uniqueName === 'IntendedWayToPayScreen') ||
      (props.currentScreen &&
        props.currentScreen.nextStep &&
        props.currentScreen.nextStep.uniqueName &&
        props.currentScreen.nextStep.uniqueName === 'IntendedWayToExpendScreen')
    if (key !== 'enter' || isTextArea || !endpoint || isTermsScreen || isListGuarantorsScreen) {
      return
    }
    onNextClick()
  }

  const isMinorOfAge = dateStr => {
    if (dateStr.split('/').length !== 3) {
      return false
    }
    const date = moment(dateStr, 'DD/MM/YYYY')
    const year = date.format('YYYY')

    const age = moment().diff(year, 'years')
    return age < 18
  }

  return [
    screens.current,
    goToPreviousScreen,
    goToNextScreen,
    goToScreen,
    onAnswered,
    onSendClick,
    onNextClick,
    onKeyDown,
    nextEnabled,
    setNextEnabled,
    isCreditAnalysisScreen,
    isTermsScreen,
    isListIncompleteGuarantorsScreen,
    isShowModalAlert,
    setIsShowModalAlert,
    quitApplication,
    getVisuals(props.match.params.school),
    IsCreditApprovedScreen,
    netInfo,
    schoolSelected
  ]
}

export const useHelpModal = () => {
  return useState(false)
}

export const useScreenMeasure = () => {
  const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    window.addEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    return () => {
      window.removeEventListener('resize', () => setDimensions([window.innerWidth, window.innerHeight]))
    }
  }, [])

  return [dimensions[0] <= 800]
}
