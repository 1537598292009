import React from 'react'
import { View, GotItButton, ImagePointer } from './style'

import POINTER_GUARANTOR from '../../assets/images/pointer.png'
export const NegativeFeedbackGuarantor = ({ setNextEnabled, onAnswered, visuals, isMobile, goToScreen }) => {
  setNextEnabled(true)

  return (
    <View>
      <ImagePointer isMobile={isMobile} src={POINTER_GUARANTOR} />
      <GotItButton visuals={visuals} isMobile={isMobile} onClick={() => goToScreen('RequestGuarantorScreen')}>
        ADICIONAR OUTRO AVALISTA
      </GotItButton>
    </View>
  )
}
